import { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Context } from '../App';
import { TypeContext } from '../misc/Types';
import log from "../misc/Logger";

interface PropsDialogGetSingleLineOfText {
    open: boolean,
    initText?: string,
    onClose: (ok: boolean, value: string) => void;
    title: string,
    contentLine1?: string,
    // contentLine2: string,
    label: string,
    button: string,
}

export default function DialogGetSingleLineOfText(props: PropsDialogGetSingleLineOfText) {
    const [text, setText] = useState(props.initText || '')
    log.debug('DialogGetSingleLineOfText');
    const { state } = useContext(Context) as TypeContext;

    const handleOK = () => {
        props.onClose(true, text || '')
    };

    return (
        <Dialog
            onClose={() => props.onClose(false, text || '')}
            open={props.open}
            disableRestoreFocus={true}
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    width: '95%',
                    maxWidth: '400px',
                    position: state.nativeApp ? 'absolute' : 'static', // Aplica posición absoluta solo si el TextField está enfocado y es una app nativa
                    top: state.nativeApp ? '5%' : undefined, // Aplica el top solo si el TextField está enfocado y es una app nativa
                },
            }}>
            <DialogTitle fontWeight={'bold'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontFamily: 'PT Sans, sans-serif' }}>
                {props.title}
                <IconButton onClick={() => props.onClose(false, text || '')} edge="end" aria-label='close_dialoggetsinglelineoftext'>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            {/*Divider*/}
            <div style={{ height: '1px', backgroundColor: '#ccc', width: '100%', margin: '0 auto' }}></div>

            <DialogContent>
                <DialogContentText style={{ color: 'grey', fontSize: 15 }}>
                    {props.contentLine1}
                </DialogContentText>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                    <TextField
                        value={text}
                        color='primary'
                        margin="dense"
                        label={props.label}
                        size="small"
                        inputProps={{ maxLength: 40 }}
                        autoFocus
                        onChange={(e) => { setText(e.target.value) }}
                    />
                </div>
            </DialogContent>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    aria-label='ok_dialoggetsinglelineoftext'
                    onClick={handleOK}
                    variant='contained'
                    size="small"
                    sx={{ textTransform: 'none', fontWeight: 'bold' }}
                    style={{ marginBottom: 20 }}>
                    {props.button}
                </Button>
            </div>
        </Dialog>
    );
}