import { createTheme } from "@mui/material";
import { DrupalEntity, UISettings, TypeState, UserInfo, PracticeBetter, OperationMode, PreplannedPractice, TeamplanAgeGroup, TeamplanDuration, TeamplanSkillLevel } from "../misc/Types";
import { testEnvironment, } from "./Functions";
import getUserLocale from 'get-user-locale';
import { PlayDetails } from "../components/DialogPlayBuilder";

// run in native mode by adding ?s=MDlh.. as query string - for test purposes
export const NATIVEAPPID = 'MDlhYzcxYjEwN2M5Njg1NjlhOGU2ZjUzYTM3NjYzZWU5NmJjODIyMGI0YmQ0Y2Vl'

// Pages/routes with no DrawerLandscape and no ICBAppBar
export const NAKEDPAES = ['', '/', '/print', '/termsandconditions', '/installandroid']

export const USEATTENDANCELIST = true;

console.log('CONSTANTS: ', getUserLocale())

export const IMAGEMIMETYPES = ['image/apng', 'image/avif', 'image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp']
export const VIDEOMIMETYPES = ['video/mp4', 'video/webm', 'video/ts', 'video/mov', 'video/quicktime']

export const DRUPALENTITYINIT: DrupalEntity = {
  type: '',
  // id: '',
  attributes: {},
  relationships: {},
}

export const PRACTICEINIT: PracticeBetter = {
  date: new Date(new Date().setSeconds(0,0)), 
  note: '', 
  selectedExercises: [], 
  attendance: [], 
  dirty: false, 
  team: DRUPALENTITYINIT, 
  uid: '',
}

export const PREPLANNEDPRACTICEINIT: PreplannedPractice = {
  note: '',
  selectedExercises: [],
  teamplanAgeGroup: TeamplanAgeGroup.u8,
  teamplanDuration: TeamplanDuration.m60,
  teamplanSkillLevel: TeamplanSkillLevel.beginner,
}

export const USERINIT: UserInfo = {
  // Next lines. Set default to 'Anonymous' and not blank. Anonymous allow run test
  // It seems that if default value is Anonymous the a regular user can not log in!!!!
  login: { "current_user": { "uid": 0, "name": 'Anonymous' }, "csrf_token": '', "logout_token": '' },
  data: DRUPALENTITYINIT,
  locale: getUserLocale() === 'es-ES' ? 'esES' : 'enUS'
}

export const UISETTINGSINIT: UISettings = {
  // start out closed/false because then we open if user has that setting. 
  // If we start out open and user has setting closed then we shortly see values that later goes away and that is bad UI
  // when account is created values are set to true
  showAttendance: false,  
  showDialogTeamPlanStepperButton: false,
  showDialogPracticeProgramPrintDescription: false,
  showDialogPracticeProgramPrintNote: false,
  showDialogPracticeProgramPrintTeachingPoints: false,
}

export const STATEINIT: TypeState = {
  loggedIn: -1,
  user: USERINIT,
  selectedExercisePool: 'icb',
  allFiles: [],
  allUsers: [],
  allGroups: [],
  allExercises: [],
  allPractices: [],
  allPreplannedPractices: [],
  allPlayers: [],
  allTeams: [],
  allConcepts: [],
  allPlays: [],
  allPlaybooks: [],
  curPractice: PRACTICEINIT,
  curPlaybook: DRUPALENTITYINIT,
  curPlay: DRUPALENTITYINIT,
  curPreplannedPractice: PREPLANNEDPRACTICEINIT,
  configuration: [],
  backdrop: 0,                    // show spinner intially
  debug: false,
  logLevel: 'info',
  showPracticeProgram: false,
  portrait: false,
  showWelcomePresentation: false, // Start out false for cleaner UI. Ie. show and automatically not show!
  gotUsersGroupsExercises: 0,
  showIntro: true,
  confirm: { code: () => { } },      // Dumb confirmation structure that has no effect!
  selectedGroup: DRUPALENTITYINIT,
  nativeApp: false,
  fromApp: '',
  exercisesRetrivedInitially: true,          // TO DO consider delete this state var
  fetchController: new AbortController(),
  awaitPaymentProcessing: false,
  uiSettings: UISETTINGSINIT,
  practicesRetrieved: false,
  operationMode: OperationMode.exercise,
  // selectedPlays: [],
  appBarHeight: 0,
  appBarShowTabs: false,
}

export const SITE_NAME = "iCoachBasketball.com";
export const JSONAPIPATH = "hidden/493443093fc62c86c08039c627ee925d"
export var BACKEND = "https://data.icoachbasketball.com";
if (testEnvironment())
  BACKEND = "https://test.data.icoachbasketball.netmaster.dk";
export const CDN = `https://icbcdn.dk/icb/var/www/${BACKEND.split('//')[1]}/web`;

export const HEADERS_GET: RequestInit = {
  credentials: "include", // Always send user credentials (cookies, basic http auth, etc..), even for cross-origin calls.
}

export const LPTHEME = createTheme({
  palette: {
    primary: {
      main: '#15468f', // ICB Blue
    },
    secondary: {
      main: '#d47029', // ICB Orange
    },
  },
});

export const GREYBACKGROUND = 100

export const NEWS_EXERCISE_DAYS_LOOK_BACK = 0

export const DEFAULT_GROUP_EXERCISE = 'f6d36ff5-c838-45b7-b0c9-94e630ba9177'; // ball handling
export const DEFAULT_GROUP_PLAY = 'e0dd4313-846f-45f5-8340-a9e6216287a9'; // BALL SCREEN '72448ec5-1869-43ba-9ee6-2ced47aabcdf'; // OFFENSE ZONE
export const ICB_USER_ID = '00ba8c7f-d077-4725-9bc4-481d3c3e01d3'; // icb user
export const SHOW_ALL_GROUP = 'YjcyZTM1ZmE4ZmU5YzIwZjA2NzY2MDlj' // random id

/*
When the back end runs operation "create_checkout_session" then the backend creates a random
string and stores the random string on the users profile.
The random string is returned to the app after successfull Stripe payment using query string parameter 
ICB_STRIPE_QUERY_PARAMETER.
At app start the app looks for two query string parameters ICB_STRIPE_QUERY_PARAMETER 
and ICB_BODY_SET_SUBSCRIPTION_PARAMETER. If the two query string parameters are available
then the JSON structure in query string parameter ICB_BODY_SET_SUBSCRIPTION_PARAMETER is
expanded with a property ICB_STRIPE_QUERY_PARAMETER with a value of the random string.
And the JSON structure is send to the backend using the operation "set_subscription"
Finally the backend compares the random string that is
supplied by the app and the random string that is stored in the users profile. If values
match then the backend continues trying to set the users subscription.
All this is done to ensure that a successfull Stripe Payment by a user is tried to 
a subscription update of the same user.
*/

// export const ICB_STRIPE_QUERY_PARAMETER = 'N2IyNWZlY2UyZjRhZmZkZWFmMzViOWVh'
// export const ICB_BODY_SET_SUBSCRIPTION_PARAMETER = 'ZDk4NzAwZGQ4Y2FkMzE2Y2FmZDA1Yjg3'

// Use as associative array. See https://www.geeksforgeeks.org/how-to-use-associative-array-in-typescript/
export const ENTITY_TYPE_TO_STATE_PROPERTY:  { [key: string]: string } = {
  'node--player': 'allPlayers',
  'node--practice': 'allPractices',
  'node--preplanned_practice': 'allPreplannedPractices',
  'node--exercise': 'allExercises',
  'node--group': 'allGroups',
  'node--concept': 'allConcepts',
  'node--play': 'allPlays',
  'node--playbook': 'allPlaybooks',
  'node--team': 'allTeams',
  'user--user': 'allUsers',
  'file--file': 'allFiles',
}

export const FILTER_USER_7_GET_ONLY_USER_7 = `?filter[uid.meta.drupal_internal__target_id]=7`;

// export const PLAYBOOK = true

export const DRAGGINGSTATE = {
  isDragging: false,
  type: null,
  id: null,
}

export const FRAMEINIT =     {
  id: 1,
  players: [
    { id: 1, x: 272, y: 350, number: 1, hasBall: true },
    { id: 2, x: 130, y: 280, number: 2, hasBall: false },
    { id: 3, x: 420, y: 280, number: 3, hasBall: false },
    { id: 4, x: 360, y: 150, number: 4, hasBall: false },
    { id: 5, x: 180, y: 150, number: 5, hasBall: false },
  ],
  lines: [],
}

export const PLAYDETAILS: PlayDetails = {
  frames: [FRAMEINIT], 
  currentFrameIndex: 0, 
  dragging: DRAGGINGSTATE, 
  selectedPlayerId: 0, 
  courtType: 'halfcourt'
}

export const TEAMPLAN = false

export const ROUTESWITHCARDSANDFORM = ['/icbdrills', '/icbplays', '/icbpreplannedpractices']