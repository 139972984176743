import { Box, FormControl, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import log from "../misc/Logger";
import { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { CRUD, DrupalEntity, TypeContext, UserType } from "../misc/Types";
import AuthorImageAndName from "./AuthorImageAndName";
import DialogGetSingleLineOfText from "./DialogGetSingleLineOfText";
import { getActionSetConfirm, nodeCRUD } from "../misc/Functions";

interface PropsTeamDropdown {
    handleChange: (value: DrupalEntity) => any,
    // teams: string[],
    selectedTeam: DrupalEntity,
    firstElement?: string,  // optionally first element for team list
    filterOnMyTeams: boolean,
}

export default function TeamDropdown(props: PropsTeamDropdown) {
    log.debug('TeamDropdown');
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const theme = useTheme();

    const [openDialogGetSingleLineOfText, setOpenDialogGetSingleLineOfText] = useState(false)
    const [disabledNewTeam, setDisabledNewTeam] = useState(false)
    // protect against setting a value for the Select component that is not yet among the MenuItem values
    // we can not just use team name as value when selecting a team from the dropdown. We have to use uuid because
    // a clubadmin can have several teams with same name - one team is his own and the other teams belong to coaches
    // in the club
    const [menuItems, setMenuItems] = useState<Array<DrupalEntity>>([]);
    const [selectedValue, setSelectedValue] = useState('');
    useEffect(() => {
        setMenuItems(state.allTeams.filter(x => x.relationships.uid.data.id === state.user.data.id || !props.filterOnMyTeams));
    }, [state.allTeams, state.user.data]);
    useEffect(() => {
        if (props.selectedTeam && menuItems.find(x => x.id === props.selectedTeam.id)) // for 'teamplan' practice we have no selected team
            // set the value requested by the parent if it exists in the menuItems
            setSelectedValue(props.selectedTeam.id!);
        else
            setSelectedValue('')
    }, [menuItems, props.selectedTeam]);

    // Close dialog to create new team
    async function onCloseDialogGetSingleLineOfText(ok: boolean, newTeamName: string) {
        if (ok) {
            // User clicked OK
            newTeamName = newTeamName.trim();
            if (!newTeamName) {
                // not valid team name
                dispatch(getActionSetConfirm(t('DialogGetSingleLineOfText04')));
            } else if (state.allTeams.
                find(x => x.attributes.title === newTeamName
                    // only consider teams that belong to current user. Think clubadmin - he sees teams of his club members
                    && x.relationships.uid.data.id === state.user.data.id
                )) {
                // team already exists
                dispatch(getActionSetConfirm(`${t('DialogGetSingleLineOfText05')}: ${newTeamName}`));
            } else {
                if (disabledNewTeam) // avoid save new team name twice when fast double click on ok in new team name dialog
                    return
                setDisabledNewTeam(true)
                const resp = await nodeCRUD(state, dispatch, CRUD.Create, {
                    type: 'node--team',
                    attributes: {
                        title: newTeamName
                    }
                })
                if (resp.data) {
                    setOpenDialogGetSingleLineOfText(false)
                    props.handleChange(resp.data)
                    // change value in this component because the component is still show even though we give control back to parent
                    // setSelectedValue(resp.data.id);
                } else {
                    dispatch(getActionSetConfirm(resp));
                }
                setDisabledNewTeam(false)
            }
        } else {
            // User cancelled the dialog
            setOpenDialogGetSingleLineOfText(false)
            // setDisabled(false)
        }
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '0 auto' }}>
            <FormControl fullWidth>
                <InputLabel
                    id="team-select-label"
                >
                    {t('PracticeProgramForm22')}
                </InputLabel>
                <Select
                    labelId="team-select-label"
                    id="team-select"
                    value={selectedValue}
                    variant="outlined"
                    label={t('PracticeProgramForm22')}
                    onChange={(event) => {
                        if (event.target.value !== props.firstElement) {
                            // tell the parent that value has changed
                            props.handleChange(state.allTeams.find(x => x.id === event.target.value)!)
                            // // change value in this component because the component is still show even though we give control back to parent
                            // setSelectedValue(event.target.value);
                        }
                    }}
                    size="small"
                    sx={{ color: 'grey' }}
                >
                    {
                        // Allow for potential static first element in drop down list
                        props.firstElement &&
                        <MenuItem
                            value={props.firstElement}
                            sx={{
                                fontSize: 14,
                                color: theme.palette.text.primary,
                            }}
                            // We must add onClick event handler becuase if "+Add Team" is already selected and we select it again we have to react!
                            onClick={() => {
                                // tell the parent that value has changed
                                setOpenDialogGetSingleLineOfText(true)
                                // change value in this component
                                setSelectedValue(props.firstElement!);
                            }}
                        >
                            {props.firstElement}
                        </MenuItem>
                    }
                    {menuItems.map((item, index) => (
                        <MenuItem
                            key={index}
                            value={item.id}
                            sx={{
                                color: theme.palette.text.primary,
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <span style={{ fontSize: 15 }}>{item.attributes.title}</span>
                                {
                                    state.user.data.attributes.field_user_type === UserType.clubadmin && !props.filterOnMyTeams &&
                                    <AuthorImageAndName node={item} nameBeforeImage={true} />
                                }
                            </Box>
                        </MenuItem>

                    ))}
                </Select>
            </FormControl >

            {/* Dialog to create new team */}
            <DialogGetSingleLineOfText
                open={openDialogGetSingleLineOfText}
                onClose={(ok, text) => onCloseDialogGetSingleLineOfText(ok, text)}
                title={t('PracticeProgramForm28')}
                contentLine1={t('DialogGetSingleLineOfText00')}
                label={t('DialogGetSingleLineOfText02')}
                button={t('DialogGetSingleLineOfText03')}
            />

        </Box>
    )
}