import { useState, Fragment, useContext, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box, TextField, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem, Step, Stepper, useMediaQuery, RadioGroup, Radio, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TeamDropdown from './TeamDropdown';
import log from "../misc/Logger";
import { Context } from '../App';
import { PracticeBetter, PreplannedPractice, TeamplanAgeGroup, TeamplanDuration, TeamplanSkillLevel, TypeContext, UserType } from '../misc/Types';
import { useTranslation } from 'react-i18next';
import { formatLanguage, getActionSetConfirm, getAllContentEntities, getDD, menuItemsForType } from '../misc/Functions';
import { BACKEND, DRUPALENTITYINIT, FILTER_USER_7_GET_ONLY_USER_7, ICB_USER_ID } from '../misc/Constants';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import StepButton from '@mui/material/StepButton';

export interface PropsDialogTeamPlanStepper {
    open: boolean,
    onClose: () => any;
}

export default function DialogTeamPlanStepper(props: PropsDialogTeamPlanStepper) {
    log.debug('DialogTeamPlanStepper');
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    // Stepper
    const [activeStep, setActiveStep] = useState(0);

    const now = new Date()

    // Form states
    const [team, setTeam] = useState(DRUPALENTITYINIT);
    const [ageGroup, setAgeGroup] = useState(TeamplanAgeGroup.u8.toString());
    const [level, setLevel] = useState(TeamplanSkillLevel.beginner.toString());
    const [weeks, setWeeks] = useState(1);
    const [duration, setDuration] = useState(TeamplanDuration.m60.toString());
    const [startDate, setStartDate] = useState(`${now.getFullYear()}-${('0' + (now.getMonth() + 1)).slice(-2)}-${('0' + now.getDate()).slice(-2)}`);
    const [selectedDays, setSelectedDays] = useState<number[]>([]);
    const [teamplan, setTeamplan] = useState<Array<PracticeBetter>>([])
    const [source, setSource] = useState('icb')

    // usertype free and pro always selects preplanned practices by ICB
    const sourceSelectionDisabled = [UserType.free, UserType.pro].includes(state.user.data.attributes.field_user_type)

    const steps = [
        t('DialogTeamPlanStepper00'),
        t('DialogTeamPlanStepper01'),
        t('DialogTeamPlanStepper02'),
        t('DialogTeamPlanStepper03'),
        t('DialogTeamPlanStepper04'),
    ];

    const [dayTimes, setDayTimes] = useState<Record<string, string>>({
        0: '16:00',
        1: '16:00',
        2: '16:00',
        3: '16:00',
        4: '16:00',
        5: '16:00',
        6: '16:00',
    });
    // const [practices, setPractices] = useState<Array<PracticeBetter>>([])

    useEffect(() => {
        if (!team.id)
            // if no team then set to first team if available
            setTeam(state.allTeams.find(x => x.relationships.uid.data.id === state.user.data.id) || { ...DRUPALENTITYINIT, id: '' })
        else if (!state.allTeams.find(x => x.id === team.id))
            // if selected team no longer exists then init team
            setTeam(DRUPALENTITYINIT)
    }, [state.allTeams, state.user.data.id])

    // reset suggested practices every time step is changed so we recalculte everytime hit step five no matter how. Step or jump!
    useEffect(() => {
        setTeamplan([]);
    }, [activeStep])

    // useMediaQuery para detectar pantallas más pequeñas
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleCloseDialog = () => {
        props.onClose();
    };

    const handleNext = () => {
        // If we're at step 2 then check that at least one day has been selected
        if (activeStep === 2 && selectedDays.length == 0) {
            dispatch(getActionSetConfirm(t('DialogTeamPlanStepper21')));
            return
        }

        // If we're at step 3 and about to go to step 4, generate practices
        if (activeStep === 3 && teamplan.length === 0) {
            selectPractices();
        }

        if (activeStep < steps.length - 1) {
            updateActiveStep(activeStep + 1);
        } else {
            // Final step: gather data and "finish"
            handleFinishPlan();
        }
    };


    const handleBack = () => {
        updateActiveStep(activeStep - 1);
    };

    const handleToggleDay = (day: number) => {
        setSelectedDays((prevDays) =>
            prevDays.includes(day)
                ? prevDays.filter((d) => d !== day)
                : [...prevDays, day]
        );
    };

    const handleDayTimeChange = (day: number, time: string) => {
        setDayTimes((prev) => ({
            ...prev,
            [day]: time,
        }));
    };

    function getRandomInt(min: number, max: number) {
        const minCeiled = Math.ceil(min);
        const maxFloored = Math.floor(max);
        return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
    }

    function getPreplannedPracticeCandidates() {
        // get preplanned practices from clubadmin or from ICB
        let preplannedPracticeOwner = ICB_USER_ID // assume from ICB
        if (source === 'clubadmin') {
            if (state.user.data.attributes.field_user_type === UserType.clubadmin) {
                preplannedPracticeOwner = state.user.data.id! // I am a club admin - get from me!
            } else if (state.user.data.attributes.field_user_type === UserType.club) {
                preplannedPracticeOwner = state.user.data.relationships.field_my_club_admin.data.id // I am a club user - get from my clubadmin
            }
        }

        return state.allPreplannedPractices
            .filter(x => x.relationships.uid.data.id === preplannedPracticeOwner)
            .map(x => JSON.parse(x.attributes.body.value))
            .filter(x => x.teamplanAgeGroup === ageGroup
                && x.teamplanDuration === duration
                && x.teamplanSkillLevel === level
            )
            .map((x: PreplannedPractice) => {
                return {
                    ...x,
                    team: team,
                    selectedExercises: x.selectedExercises.map((x) => {
                        return {
                            ...x,
                            exercise: state.allExercises.find(y => y.attributes.drupal_internal__nid === x.drupal_internal__nid) || DRUPALENTITYINIT
                        }
                    })
                }
            })
    }

    function selectPractices() {
        if (teamplan.length > 0)
            return;

        // find list of relevant practices
        let preplannedPracticeCandidates = getPreplannedPracticeCandidates()

        if (preplannedPracticeCandidates.length === 0) {
            dispatch(getActionSetConfirm(t('DialogTeamPlanStepper05'), 'Okay'))
            updateActiveStep(activeStep - 1);
            return
        }

        const practicesLocal: PracticeBetter[] = []

        // move date one day forward until all practices dates have a practice
        let date = new Date(startDate)
        let day = new Date(new Date(startDate)).getDay()
        let week = 0
        while (true) {
            if (selectedDays.includes(date.getDay())) {
                const idx = getRandomInt(0, preplannedPracticeCandidates.length)
                practicesLocal.push({
                    date: new Date(new Date(date).setHours(parseInt(dayTimes[day].slice(0, 2)), parseInt(dayTimes[day].slice(-2)))),
                    // date: new Date(new Date(date).setHours(5)),
                    note: preplannedPracticeCandidates[idx].note,
                    selectedExercises: preplannedPracticeCandidates[idx].selectedExercises,
                    attendance: [], // note that we create a number of practices where attendance is blank even though there migth players on the team!
                    team: team,
                    uid: state.user.data.id || ''
                })
                // remove practiceCandicate so we don't reuse it before all candicates are used
                preplannedPracticeCandidates.splice(idx, 1)
                // if no candicates left the recalculate list of candicates
                if (preplannedPracticeCandidates.length === 0)
                    preplannedPracticeCandidates = getPreplannedPracticeCandidates()
            }
            // move one day forward
            date.setDate(date.getDate() + 1)

            // one day forward
            day = day + 1
            // end loop?
            if (day >= new Date(startDate).getDay() && week >= weeks)
                break
            // one week forward
            if (day > 6) {
                week = week + 1
                day = 0
            }
        }

        setTeamplan(practicesLocal) // set suggested practices
    }

    function getWeekday(day: number) {
        // Sunday - Saturday : 0 - 6
        return new Intl.DateTimeFormat(formatLanguage(state.user.locale), { weekday: 'long' }).format(new Date(now.setDate(now.getDate() - now.getDay() + day)))
    }

    const handleFinishPlan = () => {
        console.log('Collected data:', {
            team,
            ageGroup,
            level,
            weeks,
            duration,
            startDate,
            selectedDays,
            source,
            dayTimes,
        });

        // check team and days are selected
        if (team.id === '') {
            dispatch(getActionSetConfirm(t('DialogTeamPlanStepper22')));
            setActiveStep(0)
            return
        } else if (selectedDays.length === 0) {
            dispatch(getActionSetConfirm(t('DialogTeamPlanStepper21')));
            setActiveStep(2)
            return
        }

        // solo guardar drupal_internal__nid para ejercicios y no el nodo de ejercicio completo
        const practicesLocalOnlydrupal_internal__nid = teamplan.map(x => {
            return {
                ...x,
                selectedExercises: x.selectedExercises.map((item) => ({
                    drupal_internal__nid: item.drupal_internal__nid,
                    coachNote: item.coachNote,
                    durationMinutes: item.durationMinutes,
                })),
            }
        })

        // check free user creates no more that 3 practices
        if (state.user.data.attributes.field_user_type === UserType.free && (teamplan.length + state.allPractices.length > 3)) {
            // Anonymous user tries to create more that 3 practices, including already created practices
            dispatch(getActionSetConfirm(t('DialogTeamPlanStepper06'), t('DrawerLandscape12'), () => navigate('/setsubscription')));
        } else {
            // layout all practices in the calendar
            getDD(state, dispatch, `${BACKEND}/icb-node/create-teamplan/${team.attributes.drupal_internal__nid}`, 'POST', practicesLocalOnlydrupal_internal__nid)
                .then((resp) => {
                    if (resp.ok) {
                        // get practices that are now in calender and get team with reference to new practicies
                        getAllContentEntities(state, dispatch, 'node--practice', state.user.login.current_user.uid === 7 ? FILTER_USER_7_GET_ONLY_USER_7 : '', true)
                        getAllContentEntities(state, dispatch, 'node--team')
                        handleCloseDialog();
                        navigate('/home')
                        dispatch(getActionSetConfirm(t('DialogTeamPlanStepper18'), t('DialogTeamPlanStepper19')));

                        // reset state for next use of dialog
                        updateActiveStep(0);
                        setTeam(DRUPALENTITYINIT);
                        setAgeGroup(TeamplanAgeGroup.u8.toString());
                        setLevel(TeamplanSkillLevel.beginner.toString());
                        setWeeks(1);
                        setDuration(TeamplanDuration.m60.toString());
                        setStartDate(`${now.getFullYear()}-${('0' + (now.getMonth() + 1)).slice(-2)}-${('0' + now.getDate()).slice(-2)}`);
                        setSelectedDays([]);
                        setSource('icb')
                        setDayTimes({
                            0: '16:00',
                            1: '16:00',
                            2: '16:00',
                            3: '16:00',
                            4: '16:00',
                            5: '16:00',
                            6: '16:00',
                        });
                    } else {
                        dispatch(getActionSetConfirm(resp.error))
                    }
                })
        }
    };

    // Step content
    const renderStepContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 0:
                return (
                    <>
                        <FormControl fullWidth margin="normal">
                            <TeamDropdown
                                handleChange={(value) => {
                                    setTeam(value)
                                }}
                                selectedTeam={team}
                                firstElement={t('PracticeProgramForm27')}
                                filterOnMyTeams={true}
                            />
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <InputLabel>{t('DialogTeamPlanStepper07')}</InputLabel>
                            <Select
                                value={ageGroup}
                                onChange={(e) => setAgeGroup(e.target.value)}
                                label={t('DialogTeamPlanStepper07')}
                                size="small"
                                sx={{ color: 'grey' }}
                            >
                                {
                                    menuItemsForType(TeamplanAgeGroup)
                                }
                            </Select>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <InputLabel>{t('DialogTeamPlanStepper08')}</InputLabel>
                            <Select
                                value={level}
                                onChange={(e) => setLevel(e.target.value)}
                                label={t('DialogTeamPlanStepper08')}
                                size="small"
                                sx={{ color: 'grey' }}
                            >
                                {
                                    menuItemsForType(TeamplanSkillLevel)
                                }
                            </Select>
                        </FormControl>


                    </>
                );

            case 1:
                return (
                    <>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>{t('DialogTeamPlanStepper09')}</InputLabel>
                            <Select
                                value={weeks}
                                onChange={(e) => setWeeks(parseInt(e.target.value.toString()))}
                                label={t('DialogTeamPlanStepper09')}
                                size="small"
                                sx={{ color: 'grey' }}
                            >
                                <MenuItem value="">None</MenuItem>
                                {[...Array(12)].map((_, i) => (
                                    <MenuItem key={i} value={i + 1}>
                                        {i + 1} {t('DialogTeamPlanStepper20')}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>{t('DialogTeamPlanStepper10')}</InputLabel>
                            <Select
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                                label={t('DialogTeamPlanStepper10')}
                                size="small"
                                sx={{ color: 'grey' }}
                            >
                                {
                                    menuItemsForType(TeamplanDuration)
                                }

                            </Select>
                        </FormControl>

                        <TextField
                            label={t('DialogTeamPlanStepper11')}
                            type="date"
                            fullWidth
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            margin="normal"
                            size="small"
                            InputProps={{
                                style: { color: 'grey' }
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: 'grey' }
                            }}
                        />
                    </>
                );
            case 2:
                return (
                    <>
                        {[
                            getWeekday(1),
                            getWeekday(2),
                            getWeekday(3),
                            getWeekday(4),
                            getWeekday(5),
                            getWeekday(6),
                            getWeekday(0),
                            // t('WeekDay00'),
                            // t('WeekDay01'),
                            // t('WeekDay02'),
                            // t('WeekDay03'),
                            // t('WeekDay04'),
                            // t('WeekDay05'),
                            // t('WeekDay06'),
                        ].map((day, index) => (
                            <Fragment key={day}>
                                <Box display="flex" alignItems="center" justifyContent="space-between" >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedDays.includes((index + 1) % 7)}
                                                onChange={() => handleToggleDay((index + 1) % 7)}
                                            />
                                        }
                                        label={day}
                                        sx={{ '& .MuiFormControlLabel-label': { fontSize: { xs: '12px', sm: '14px' } } }}
                                    />
                                    {selectedDays.includes((index + 1) % 7) && (
                                        <TextField
                                            label={t('PracticeProgramForm18')}
                                            type="time"
                                            value={dayTimes[(index + 1) % 7] || ''}
                                            size="small"
                                            onChange={(e) => handleDayTimeChange((index + 1) % 7, e.target.value)}
                                            sx={{ width: 140 }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 minutes intervals
                                            }}
                                        />
                                    )}
                                </Box>
                                <Divider sx={{ my: 0.5 }} />
                            </Fragment>
                        ))}
                    </>
                );

            case 3: // Source
                return (
                    <>
                        {!sourceSelectionDisabled ? (
                            <>
                                {/* Explain to club users that they can select practice plans from their club admin or iCoachBasketball */}
                                {state.user.data.attributes.field_user_type === UserType.club || state.user.data.attributes.field_user_type === UserType.clubadmin &&
                                    <Typography sx={{ fontSize: { xs: 12, sm: 14 }, color: 'grey', textAlign: 'center', marginBottom: 1 }}>
                                        {t('DialogTeamPlanStepper12')}
                                    </Typography>
                                }

                                <FormControl>
                                    {/* <FormLabel id="source">{t('DialogTeamPlanStepper13')}</FormLabel> */}
                                    <RadioGroup
                                        aria-labelledby="source"
                                        value={source}
                                        name="radio-buttons-group"
                                        onChange={(e) => setSource(e.target.value)}
                                    >
                                        <FormControlLabel sx={{ fontSize: { xs: 12, sm: 12 } }} value="icb" control={<Radio disabled={sourceSelectionDisabled} />} label="iCoachBasketball" />
                                        <FormControlLabel sx={{ fontSize: { xs: 12, sm: 12 } }} value="clubadmin" control={<Radio disabled={sourceSelectionDisabled} />} label="Club Admin" />
                                    </RadioGroup>
                                </FormControl>
                            </>
                        ) : (
                            <Box sx={{ marginTop: 5 }}>
                                <Divider sx={{ my: 1 }} />
                                <Typography sx={{ fontSize: { xs: 12, sm: 14 }, color: 'grey', textAlign: 'center' }}>
                                    {t('DialogTeamPlanStepper14')}
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                            </Box>
                        )}
                    </>
                );

            case 4: // Create practices
                selectPractices()
                return (
                    <>
                        <Divider sx={{ my: 1 }} />
                        <Typography sx={{ fontSize: { xs: 12, sm: 14 }, color: 'grey', textAlign: 'center', marginBottom: 1 }}>
                            {t('DialogTeamPlanStepper15')}
                        </Typography>
                        {
                            teamplan.map((x: PracticeBetter, index) => {
                                return (
                                    <Typography key={index} sx={{ fontSize: { xs: 11, sm: 13 }, color: 'grey' }}>
                                        {x.date.toLocaleDateString(formatLanguage(state.user.locale), { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
                                    </Typography>
                                )
                            })
                        }
                        <Divider sx={{ my: 1 }} />
                    </>
                );
            default:
                return null;
        }
    };

    // do some checks before change of step
    function updateActiveStep(newStep: number) {
        if (newStep > 0 && !team.id) {
            dispatch(getActionSetConfirm(t('DialogTeamPlanStepper22')));
            setActiveStep(0)
        } else if (newStep > 2 && selectedDays.length === 0) {
            dispatch(getActionSetConfirm(t('DialogTeamPlanStepper21')));
            setActiveStep(2)
        } else
            setActiveStep(newStep);
    }

    return (
        <Fragment>
            <Dialog
                open={props.open}
                onClose={handleCloseDialog}
                fullWidth
                maxWidth="sm"
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: 4,
                        // padding: 2,
                    },
                }}
            >
                <DialogTitle sx={{ backgroundColor: '#DD6F20', color: 'primary.contrastText', textAlign: 'center' }}>
                    <Typography sx={{ fontSize: { xs: 14, sm: 17 }, fontWeight: 'bold', color: "white" }}>{t('DialogTeamPlanStepper16')}</Typography>
                </DialogTitle>
                <DialogContent>
                    {/* Stepper modified to be non-linear and clickable with check icon */}
                    <Stepper activeStep={activeStep} alternativeLabel nonLinear sx={{ marginTop: 2, marginBottom: 2 }}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={activeStep > index}
                                sx={{
                                    '& .MuiStepIcon-root': {
                                        color: 'lightgrey', // default color for the icons
                                        '&.Mui-active': {
                                            color: '#DD6F20', // active step color
                                        },
                                        '&.Mui-completed': {
                                            color: '#DD6F20', // completed step color
                                        },
                                    },
                                }}
                            >
                                <StepButton onClick={() => {
                                    updateActiveStep(index)
                                }}>
                                    {!isSmallScreen && (
                                        <Typography sx={{ fontWeight: activeStep === index ? 'bold' : 'normal', fontSize: { xs: 11, sm: 12 }, color: activeStep === index ? '#DD6F20' : 'grey' }}>
                                            {label}
                                        </Typography>
                                    )}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    {renderStepContent(activeStep)}
                </DialogContent>

                <DialogActions sx={{ justifyContent: 'space-between', paddingRight: 3, paddingLeft: 3, paddingBottom: 2 }}>
                    <Box>
                        {activeStep > 0 && (
                            <Button
                                onClick={handleBack}
                                variant="text"
                                color="secondary"
                                startIcon={<ArrowBackIcon />}
                                sx={{ textTransform: 'none' }}
                            >
                                {t('HowToCheckAttendance06')}
                            </Button>
                        )}
                    </Box>
                    <Box>
                        <Button
                            onClick={handleNext}
                            variant="contained"
                            color="secondary"
                            sx={{ textTransform: 'none', fontWeight: 'bold' }}
                            endIcon={activeStep === steps.length - 1 ? <CheckIcon /> : <ArrowForwardIcon />}
                        >
                            {activeStep === steps.length - 1 ? t('DialogTeamPlanStepper17') : t('HowToCheckAttendance07')}
                        </Button>
                    </Box>
                </DialogActions>

            </Dialog>
        </Fragment>
    );
}