import { Box, Card, Grid, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import { Fragment, useContext, useState, } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import { TypeContext, PropsExerciseCardSelected, ActionSetSelectedExercises, ActionSetPractice, OperationMode, ExerciseSelected, ActionSetPreplannedPractice } from '../misc/Types';
import { Context } from '../App';
import { exerciseImage, getActionSetConfirm } from '../misc/Functions';
import Grid2 from "@mui/material/Unstable_Grid2";
import log from "../misc/Logger";
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, MenuItem, Input, Select, } from '@mui/material';
import { DialogExerciseDetails } from './DialogExerciseDetails';

// handle a single exercise card selected for a practice or selected for a practice draft
export default function ExerciseCardSelected(props: PropsExerciseCardSelected) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const theme = useTheme();

    log.debug('ExerciseCardSelected()');
    log.trace('ExerciseCardSelected()', JSON.stringify(props.exerciseSelected.exercise));

    const [openDialogExerciseDetails, setOpenDialogExerciseDetails] = useState(false)

    const fileIDImage = props.exerciseSelected.exercise?.relationships?.field_exercise_board_image?.data?.id;

    // dispatch action to update current practice or current practice draft depending on operation mode
    function dispatchSetPractice(selectedExercises: ExerciseSelected[]) {
        if (state.operationMode === OperationMode.exercise) {
            const action: ActionSetPractice = {
                type: 'setPractice', practice: {
                    ...state.curPractice,
                    selectedExercises: selectedExercises,
                    dirty: true,
                }
            }
            dispatch(action)
        } else {
            const action: ActionSetPreplannedPractice = {
                type: 'setPreplannedPractice', preplannedPractice: {
                    ...state.curPreplannedPractice,
                    selectedExercises: selectedExercises,
                }
            }
            dispatch(action)
        }
    }

    // swap this selected exercise with selected exercise with index idx
    function swap(idx: number) {
        const selectedExercise = props.sourceList[props.index]
        let selectedExercises = [...props.sourceList]
        selectedExercises[props.index] = selectedExercises[idx]
        selectedExercises[idx] = selectedExercise
        dispatchSetPractice(selectedExercises)
    }

    // move this selected exercise one up
    function handleUpArrow() {
        swap(props.index - 1)
    }

    // move this selected exercise one down
    function handleDnArrow() {
        swap(props.index + 1)
    }

    return (
        <Fragment>

            {/* Only include DialogExerciseDetails if we have an exercise */}
            {
                props.exerciseSelected.exercise &&
                <DialogExerciseDetails
                    open={openDialogExerciseDetails}
                    onClose={() => setOpenDialogExerciseDetails(false)}
                    exercise={props.exerciseSelected.exercise}
                    addOption={false}
                />
            }

            <Card>
                <Grid2 container width={1}>

                    <Grid2 xs={12} sx={{ background: state.operationMode === OperationMode.preplannedPractice ? 'linear-gradient(to right, #e1f5fe, #a0d8ef)' : '#fff3e0', width: '100%', padding: '2px', }}> {/* Ajusta el color del fondo según necesites */}
                        <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>

                            <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: theme.palette.text.primary }}>
                                {`${props.index + 1}. ${props.exerciseSelected!.exercise?.attributes.title || t('Generel21')}`}
                            </Typography>

                            <Typography sx={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'PT sans, sans-serif', color: theme.palette.text.primary }} textAlign={'right'}>
                                {state.operationMode === OperationMode.preplannedPractice ? props.exerciseSelected.durationMinutes + ' min' : props.startAndStopTime}
                            </Typography>
                        </Grid>
                    </Grid2>

                    <Grid2 xs={4}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <IconButton
                                onClick={() => setOpenDialogExerciseDetails(true)}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'transparent', // This will remove the hover background color
                                    },
                                }}
                            >
                                <img
                                    src={exerciseImage(state, fileIDImage, props.exerciseSelected.exercise?.id)}
                                    height="100" // Ajusta esto según sea necesario
                                    style={{ display: 'flex', borderRadius: '4px', objectFit: 'contain', height: '100px' }}
                                    alt="Imagen del Ejercicio"
                                />
                            </IconButton>
                        </Box>
                    </Grid2>


                    <Grid2 xs={7}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }} margin={0}>

                            {/* Duration */}
                            <FormControl>
                                <InputLabel id="duration-label"></InputLabel>
                                <Select
                                    labelId="duration-label"
                                    id="duration"
                                    value={props.exerciseSelected.durationMinutes}
                                    onChange={(e) => {
                                        let selectedExercises = [...props.sourceList]
                                        selectedExercises[props.index].durationMinutes = Number(e.target.value)
                                        dispatchSetPractice(selectedExercises)
                                    }}
                                    input={<Input id="duration" />}
                                    style={{ fontFamily: 'PT sans, sans-serif' }}
                                    renderValue={(selected) => `${selected} ${selected === 1 ? t('ExerciseCardSelected01') : t('ExerciseCardSelected02')}`}
                                >
                                    {Array.from({ length: 31 }, (_, i) => (
                                        <MenuItem key={i} value={i}>
                                            {i}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* Focus */}
                            <TextField
                                variant='standard'
                                color='primary'
                                margin="dense"
                                id="focus"
                                label={t('Generel08')}
                                size="small"
                                multiline
                                sx={{ fontFamily: 'PT sans, sans-serif' }} // Use sx prop instead of style
                                onChange={(e) => {
                                    let selectedExercises = [...props.sourceList]
                                    selectedExercises[props.index].coachNote = e.target.value
                                    dispatchSetPractice(selectedExercises)
                                }}
                                value={props.exerciseSelected.coachNote}
                                InputProps={{
                                    inputProps: { maxLength: 300 },
                                    sx: { fontFamily: 'PT sans, sans-serif' } // Ensure fontFamily applies to the input element
                                }}
                            />
                        </Box>
                    </Grid2>

                    <Grid2 xs={1}>
                        <Box display="flex" justifyContent="flex-end">
                            <Box display={props.displayOnly ? 'none' : 'inline'}>
                                <Stack >
                                    <DeleteOutlineOutlinedIcon fontSize="small" color="action" style={{ color: 'grey', marginTop: '10px' }}
                                        onClick={() => {
                                            // Remove selected exercise
                                            dispatch(getActionSetConfirm(t('ExerciseCardSelected00'), 'ok', () => {
                                                const actionSetSelectedExercises: ActionSetSelectedExercises = {
                                                    type: 'setSelectedExercises',
                                                    // selectedExercises: props.sourceList.filter((x: ExerciseSelected) => x.uuidListElement !== props.exerciseSelected.uuidListElement)
                                                    selectedExercises: props.sourceList.filter((x: ExerciseSelected) => x !== props.exerciseSelected)
                                                };
                                                dispatch(actionSetSelectedExercises);
                                                dispatch({ type: 'setPracticeProgramDirty', dirty: true })
                                            }));
                                        }}
                                    />
                                    {
                                        props.upArrow &&
                                        <ArrowCircleUpRoundedIcon fontSize="small" color="action" style={{ color: 'grey', marginTop: 15 }} onClick={handleUpArrow} />
                                    }
                                    {
                                        props.dnArrow &&
                                        <ArrowCircleDownRoundedIcon fontSize="small" color="action" style={{ color: 'grey', marginTop: 15 }} onClick={handleDnArrow} />
                                    }
                                </Stack>
                            </Box>
                        </Box>
                    </Grid2>
                </Grid2 >
            </Card>
        </Fragment>
    );
}
