import { useContext, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { ActionSetContentEntity, ActionSetPreplannedPractice, ActionShowPracticeProgram, PreplannedPractice, TypeContext } from "../misc/Types";
import { DataGrid, GridActionsCellItem, GridActionsColDef, GridColDef, GridEventListener, GridRowParams } from "@mui/x-data-grid";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BACKEND } from "../misc/Constants";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { getActionSetConfirm, getDD } from "../misc/Functions";
import AddBUttonAboveLists from "./AddButtonAboveList";

export default function PreplannedPractices() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('TeamplanPractices');
    const navigate = useNavigate();
    const theme = useTheme();

    const [disabled, setDisabled] = useState(false)

    // Data columns
    const columns: (GridColDef | GridActionsColDef)[] = [
        { field: 'uuid', headerName: '', maxWidth: 10, sortable: false, },
        { field: 'note', headerName: t('PreplannedPractices05'), flex: 1, sortable: false },
        { field: 'teamplanAgeGroup', headerName: t('PreplannedPractices06'), flex: 1, sortable: false },
        { field: 'teamplanSkillLevel', headerName: t('PreplannedPractices07'), flex: 1, sortable: false },
        { field: 'teamplanDuration', headerName: t('PreplannedPractices08'), flex: 1, sortable: false },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    onClick={() => {
                        handleDelete(params.id.toString())
                    }}
                    label={t('PreplannedPractices04')}
                    showInMenu
                />,
            ]
        }
    ]

    // row clicked. Show DialogDownloadSendBoth so user can work with practice
    const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
        // use the object that we already parsed and put in the list so we don't parse again!
        const action: ActionSetPreplannedPractice = { type: 'setPreplannedPractice', preplannedPractice: { ...params.row } };
        dispatch(action);
        navigate('/icbpreplannedpractices')
    };

    // Delete preplanned practice
    function handleDelete(id: string) {
        dispatch(getActionSetConfirm(t('PreplannedPractices03'), '', () => {
            if (disabled)
                return
            setDisabled(true)
            getDD(state, dispatch, `${BACKEND}/icb-node/delete/${state.allPreplannedPractices.find(x => x.id === id)?.attributes.drupal_internal__nid}`)
                .then((resp) => {
                    if (resp.ok) {
                        // delete the preplanned practice from the global state
                        const action: ActionSetContentEntity = {
                            type: 'setContentEntity',
                            contentType: 'node--preplanned_practice',
                            data: state.allPreplannedPractices.filter(x => x.id !== id),
                            initialLoad: true,
                        }
                        dispatch(action)
                    } else {
                        dispatch(getActionSetConfirm(resp.error))
                    }
                })
                .finally(() => {
                    setDisabled(false)
                })
        }))
    }

    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                {t('PreplannedPractices00')}
            </Typography>
            <Divider sx={{ marginBottom: 3 }} />

            {/* Button to add preplanned practice */}
            <AddBUttonAboveLists
                buttonText={t('PreplannedPractices02')}
                addCode={() => {
                    const actionShowPracticeProgram: ActionShowPracticeProgram = { type: 'showPracticeProgram', show: true }
                    dispatch(actionShowPracticeProgram);
                    navigate('/icbpreplannedpractices')
                }}
                tooltip={t('PreplannedPractices01')}
            />

            <DataGrid
                sx={{ marginTop: 3 }}
                columns={columns}
                rows={
                    state.allPreplannedPractices
                        // only ICB and clubadmins can create preplanned practices. List only users own preplanned practices
                        .filter(x => x.relationships.uid.data.id === state.user.data.id)
                        .map(x => {
                            const preplannedPracticeParsed: PreplannedPractice = JSON.parse(x.attributes.body.value)
                            return {
                                note: preplannedPracticeParsed.note,
                                teamplanAgeGroup: t('EnumKey' + preplannedPracticeParsed.teamplanAgeGroup),
                                teamplanSkillLevel: t('EnumKey' + preplannedPracticeParsed.teamplanSkillLevel),
                                teamplanDuration: t('EnumKey' + preplannedPracticeParsed.teamplanDuration),
                                selectedExercises: preplannedPracticeParsed.selectedExercises,
                                uuid: x.id, // uuid for preplaned practice 
                            }
                        })
                }
                getRowId={(row) => row.uuid} // use uuid as row id and not id
                columnVisibilityModel={{ uuid: false }}
                disableColumnMenu
                onRowClick={handleRowClick}
                rowHeight={30}
            />
        </Box >
    )
}