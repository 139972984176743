import { useState, useEffect, useContext } from 'react';
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, } from '@mui/material';
import PlayAnimationHTMLDoc from './PlayAnimationHTMLDoc';
import { Context } from '../App';
import { TypeContext } from '../misc/Types';
import log from "../misc/Logger";
import { getConfigValue } from '../misc/Functions';

interface Frame {
  id: number;
  players: any[];
  lines: any[];
}

interface PropsDialogPlayAnimation {
  playNid: number,
  isOpen: boolean;
  onClose: () => void;
  frames: Frame[];
  courtType: 'halfcourt' | 'fullcourt';
}

export default function DialogPlayAnimation({ playNid, isOpen, onClose, frames, courtType }: PropsDialogPlayAnimation) {
  const { state } = useContext(Context) as TypeContext;
  log.debug('DialogPlayAnimation');

  const [modalContentUrl, setModalContentUrl] = useState('');
  const [downloadVideo, setDownloadVideo] = useState(false);

  useEffect(() => {
    if (isOpen) {

      // WE CALL THE HTML DOC FROM PLAYANIMATIONHTMLDOC.TSX, AND WE PASS THE FRAMES AND COURT TYPE
      const HTMLDoc = PlayAnimationHTMLDoc(frames, courtType, playNid, downloadVideo)
      const blob = new Blob([HTMLDoc], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      setModalContentUrl(url);
    } else {
      if (modalContentUrl) {
        URL.revokeObjectURL(modalContentUrl);
        setModalContentUrl('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, frames, downloadVideo]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="animation-modal-title"
      aria-describedby="animation-modal-description"
      PaperProps={{
        style: {
          borderRadius: 16,
          maxWidth: '600px',
          maxHeight: '650px',
          width: '99%',
          height: '99%',
          margin: 1
        },
      }}
    >

      <DialogTitle>
        {state.allPlays.find(x => x.attributes.drupal_internal__nid === playNid)?.attributes.title}
      </DialogTitle>

      <DialogContent sx={{ padding: '6px 12px' }}>
        <iframe
          src={modalContentUrl}
          style={{ width: '100%', height: '100%', border: 'none', padding: '0' }} // Asegurar que el iframe no tenga padding
          title="Animation"
        />
      </DialogContent>

      {/* Option to download video if that functionality has been enabled in configuration */}
      {
        getConfigValue(state, 'enableCreateAndDownloadVideoOfPlay')
        &&
        <FormControlLabel
          control={<Checkbox />}
          label="Download Video"
          onChange={() => setDownloadVideo(!downloadVideo)}
        />
      }

    </Dialog >
  );
};
