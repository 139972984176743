import { Button, Snackbar, Typography } from '@mui/material'
import { Fragment, useContext, useState } from 'react'
import { Context } from '../App';
import { TypeContext, UserType } from '../misc/Types';
import { useTranslation } from 'react-i18next';
import { ContentCopy } from '@mui/icons-material';
import { getActionSetConfirm, icbControllerGenerel02 } from '../misc/Functions';
import log from "../misc/Logger";

export default function CopyToClipboardButton() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false)

    return (
        <Fragment>
            {
                state.user.data.attributes.field_user_type === UserType.clubadmin &&
                <Fragment>
                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        sx={{ marginTop: 2, textTransform: 'none' }}
                        startIcon={<ContentCopy />}
                        onClick={async () => {
                            setOpen(true)
                            const resp = await icbControllerGenerel02(state, { "opr": "get_club_sha" })
                            if (!resp.ok) {
                                dispatch(getActionSetConfirm(resp.error))
                                return
                            }
                            const club_sha = resp.club_sha || 'default club code'
                            // en Safari móvil, por razones de seguridad, esta llamada debe realizarse dentro del contexto onClick
                            setTimeout(() => {
                                navigator.clipboard.writeText(club_sha)
                                    .then(() => { log.info(`copied to clipboard`) })
                                    .catch((error) => { log.error(`could not copy to clipboard, error: ${error}`) })
                            }, 0)
                        }}
                    >
                        <Typography
                            fontSize={12}

                        >
                            {t('CopyToClipboardButton01')}
                        </Typography>
                    </Button>
                    <Snackbar
                        open={open}
                        onClose={() => setOpen(false)}
                        autoHideDuration={2000}
                        message={t('CopyToClipboardButton00')}
                    />
                </Fragment>
            }
        </Fragment >
    )
}
