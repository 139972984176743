import {
    BACKEND, JSONAPIPATH, STATEINIT, DEFAULT_GROUP_EXERCISE, CDN, NAKEDPAES, NATIVEAPPID, DRUPALENTITYINIT, FILTER_USER_7_GET_ONLY_USER_7
} from "../misc/Constants";
import {
    CollectBeforeStateChange, DrupalEntity, TypeState, ActionSetState,
    CRUD, ActionSetConfirm, ActionGoHome, EnvironmentType, JSONAPIResponse, UserType, FileRelationship,
    DrupalEntityData,
    PracticeBetter,
    ActionSetPractice,
    JSONAPITypeId,
    PlaybookSharable,
    PreplannedPractice,
    ActionSetUISettings,
    UISettingKeys,
} from "./Types";
import log from "./Logger";
import { NavigateFunction } from "react-router-dom";
import i18next from 'i18next'
import getUserLocale from 'get-user-locale';
import { randomId } from "@mui/x-data-grid-generator";
import prefix from 'loglevel-plugin-prefix'; // logging
import lescape from 'escape-latex'
import { PlayDetails } from "../components/DialogPlayBuilder";
import { generateAnimationScript, generateSVGContent } from "../components/PlayAnimationHTMLDoc";
import { minify } from 'terser';
import { MenuItem } from "@mui/material";

// From four key locale, ie esES, to two key locate, ie es
export const formatLanguage = (key: string) => `${key.substring(0, 2)}`

// See https://blog.logrocket.com/the-async-cookie-store-api-a-new-treat-for-web-developers/
export const getCookie = (name: string) => {
    return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
}

export function lng() {
    // Default language from cookie
    let language = formatLanguage(getCookie('icoachbasketball'));
    if (language === '') {
        // Try base language on getUserLocale
        language = getUserLocale() === 'es-ES' ? 'es' : 'en'
    }
    return language;
}

// We must use texts from translation files
// Here we are not in a react compoent envronment so we have to import translation like below
i18next.init(
    {
        fallbackLng: "en",
        lng: lng(),
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: `${import.meta.env.BASE_URL}i18n/{{lng()}}.json`,
        },
    }
)

// Global scope variable
// var globalScopeCheckRestorePracticeProgram = false; // set back to false once restore works!

// Reload app to landing page or login dialog box. On iPhone we want to reload to login dialog box. Otherwise reload to landing page
// If user already logged in the login dialog box is skipped
export function reloadApp(nativeApp: boolean, pathname: string = '') {
    log.info(`reloadApp() ${pathname}`)
    if (nativeApp) {
        window.location.replace(`${window.location.origin}${pathname}?s=${NATIVEAPPID}`)
    } else {
        window.location.replace(`${window.location.origin}${pathname}`)
    }
}

// Error are errors! Not just a message to the user about wrong use of UI
// Errors are errors system guys must put their attention to!
export function logAndShowError(dispatch: React.Dispatch<any>, s: string) {
    log.error(s);
    // Make sure log has been sent to the server before alert user
    // Otherwise, we can not see the error on the server when the
    // user sees the alert
    dispatch(getActionSetConfirm(s));
}

export function testEnvironment() {
    let host = window.location.hostname.toLowerCase();
    return (host.includes('test') || host.includes('localhost'));
}

export function testEnvironmentIndication() {
    return testEnvironment() ? 'TEST' : ''
}

// return current users clubadmin if usertype is 'club'. Otherwise, return current users id
export function getClubAdmin(state: TypeState) {
    return state.user.data.attributes.field_user_type === UserType.club
        ? state.user.data.relationships.field_my_club_admin.data.id
        : state.user.data.id
}

// Return true if free user has reached max number of saved practices. Otherweise, return false
export const freeUserReachedMaxPractices = (state: TypeState) => state.user.data.attributes.field_user_type === 'free' &&
    state.allPractices.filter(x => x.relationships.uid.data.id === state.user.data.id).length >=
    state.configuration[0].attributes.field_max_practices_per_user;

// Fetch header for HTTP POST, PATCH and DELETE
export function getHeaders(
    // content_type: string,
    csrf_token: string,
    // content_disposition: string,
    method: string,
    body: any = {}
): RequestInit {
    log.debug(`getHeaders()`);
    let credentials: RequestCredentials = "include"
    let passwordInBody = body.pass;
    let headers = {
        method: method,
        credentials: credentials, // set and send cookies in cross-origin requests
        headers: {
            "Content-Type": "application/vnd.api+json",
            "Accept": "application/vnd.api+json",
            "X-CSRF-Token": csrf_token,
        },
        body: JSON.stringify(body),
    }
    // Don't log password
    if (passwordInBody) {
        let headersWithoutPwd = JSON.parse(JSON.stringify(headers))
        headersWithoutPwd.body = JSON.parse(headersWithoutPwd.body);
        headersWithoutPwd.body.pass = "*****";
        headersWithoutPwd.body = JSON.stringify(headersWithoutPwd.body);
        log.debug(`${JSON.stringify(headersWithoutPwd, null, 3)}`);
    } else {
        log.debug(JSON.stringify(headers, null, 3));
    }
    return headers;
}

// Get nodes of nodetype 'notetype' in the selected locale, 'state.user.locale'. Get nodes recursively/pagination
export async function fetchNodes(
    node: string,
    nodetype: string,
    filter: string,
    state: TypeState,
    dispatch: React.Dispatch<any>,
    collect: CollectBeforeStateChange = { nodes: [], included: [], },
    next?: string
) {
    // build url
    let url = next || `${BACKEND}/${formatLanguage(state.user.locale)}/${JSONAPIPATH}/${node}/${nodetype}${filter}`;
    const json: JSONAPIResponse = await getDD(state, dispatch, url)
    if (json.data) {
        // add collected data
        collect.nodes.push(...json.data);
        if (json.included) {
            collect.included.push(...json.included);
        }
        if (json.links.hasOwnProperty("next")) {
            // get more data
            return fetchNodes(node, nodetype, filter, state, dispatch, collect, json.links.next && json.links.next.href) // recursive call to handle pagination
        }
        // return collected data
        return (collect)
    } else {
        log.debug(`fetchNodes(): , ${json}`); // you come here if user logged out and fetch data was not completed
        return collect
    }
}

// Generel function to retrieve Drupal content types
export async function getAllContentEntities(state: TypeState, dispatch: React.Dispatch<any>, contentType: string, filter: string = '', initialLoad: boolean = true) {
    log.debug('getAllContentEntities 01: ', contentType)
    fetchNodes(contentType.split('--')[0], contentType.split('--')[1], filter, state, dispatch)
        .then((data) => {
            log.debug('getAllContentEntities 02: ', contentType, '. node: ', data.nodes.length, '. included users: ', data.included.filter(x => x.type === 'user--user').length, '. included files: ', data.included.filter(x => x.type === 'file--file').length,)
            // dispatch the nodes
            dispatch({ type: 'setContentEntity', contentType: contentType, data: data.nodes, initialLoad: initialLoad })
            // dispatch the included nodes, we only include files and users
            dispatch({ type: 'setContentEntity', contentType: 'file--file', data: data.included.filter(x => x.type === 'file--file'), initialLoad: false })
            dispatch({ type: 'setContentEntity', contentType: 'user--user', data: data.included.filter(x => x.type === 'user--user'), initialLoad: false })
            // Extra stuff depending on content type
            if (['node--group', 'node--exercise', 'user--user'].includes(contentType))
                dispatch({ type: 'setGotUsersGroupsExercises' })
            if (['node--practice'].includes(contentType))
                dispatch({ type: 'setPracticesRetrieved' }) // now, pracitce calender no longer has to say 'loading calender'
        })
}

// get list of users that are relevant
export function getUsers(state: TypeState, dispatch: React.Dispatch<any>) {
    // Get user info if not Anonymous
    if (state.user.login.current_user.uid !== 0) {
        // Get current user and all users that have current user as clubadmin
        // For OR filter please see https://www.drupal.org/forum/support/post-installation/2024-10-18/solved-jsonapi-filtering-and-clause
        const filter =
            `filter[my-group][group][conjunction]=OR
&filter[filter-1][condition][path]=uid
&filter[filter-1][condition][value]=${state.user.login.current_user.uid}
&filter[filter-1][condition][memberOf]=my-group
&filter[filter-2][condition][path]=field_my_club_admin.meta.drupal_internal__target_id
&filter[filter-2][condition][value]=${state.user.login.current_user.uid}
&filter[filter-2][condition][memberOf]=my-group`
        const include = `include=user_picture,field_my_club_admin,field_club_logo,field_club_documents,field_my_club_admin.field_club_logo,field_my_club_admin.field_club_documents`
        getAllContentEntities(state, dispatch, 'user--user', `?${filter}&${include}`)
    }
}

// Initial get data from backend
export function fetchData(state: TypeState, dispatch: React.Dispatch<any>) {
    log.debug(`{fetchData(), ${window.location.pathname}`);
    getAllContentEntities(state, dispatch, 'node--exercise', getFilterExercise(state, { type: 'node--group', id: DEFAULT_GROUP_EXERCISE }), false) // we also get exercises in App so this is NOT initial load
    const filterUser7GetOnlyUser7 = state.user.login.current_user.uid === 7 ? FILTER_USER_7_GET_ONLY_USER_7 : ''
    // A number of initial loads
    getAllContentEntities(state, dispatch, 'node--player', filterUser7GetOnlyUser7)
    getAllContentEntities(state, dispatch, 'node--practice', filterUser7GetOnlyUser7)
    getAllContentEntities(state, dispatch, 'node--group', filterUser7GetOnlyUser7)
    getAllContentEntities(state, dispatch, 'node--concept', '?include=field_concept_video')
    getAllContentEntities(state, dispatch, 'node--team', filterUser7GetOnlyUser7)
    getAllContentEntities(state, dispatch, 'node--play', filterUser7GetOnlyUser7)
    getAllContentEntities(state, dispatch, 'node--playbook', filterUser7GetOnlyUser7)
    getAllContentEntities(state, dispatch, 'node--preplanned_practice', filterUser7GetOnlyUser7)
    getUsers(state, dispatch)
    // Get ICB logo file for PDF
    const fileIDICBLogo = state.configuration[0].relationships.field_icb_logo_for_pdf.data.id
    if (fileIDICBLogo) {
        getAllContentEntities(state, dispatch, 'file--file', `?filter[id]=${fileIDICBLogo}`, false) // Not initial load
    }
}

// Handle no server response on fetch in getDD and nodeCRUD
function fetchFailed(state: TypeState, dispatch: React.Dispatch<any>, url: string, error: any) {
    // Make sure intro is not shown
    if (state.loggedIn === -1) {
        dispatch({ type: 'setState', state: { ...state, loggedIn: 0 } })
    }
    if (error.name === 'AbortError') {
        log.info(`${error.name} ${url}`);
    } else if (['Failed to fetch', 'Load failed'].includes(error.message) || error.message.includes('ERR_INTERNET_DISCONNECTED')) {
        dispatch(getActionSetConfirm('Please check your internet connection and try again.'));
    } else {
        const msg = `Error. Contact ICB. Error: ${error.message} Request: ${url}`
        dispatch(getActionSetConfirm(msg));
        log.error(msg);
    }
    return error.message || error
}

// Get Drupal Data - it this a better function to use than ????????????????
export async function getDD(state: TypeState, dispatch: React.Dispatch<any>, url: string, method: string = 'GET', body: any = undefined) {
    try {
        const requestInit: RequestInit = {
            credentials: "include", // Always send user credentials (cookies, basic http auth, etc..), even for cross-origin calls.
            signal: state.fetchControllerSignal,
            method: method,
            // headers: {
            //     "Cache-Control": "max-age=3600"
            // }
        }
        // Only POST requests can have a body
        if (method === 'POST')
            requestInit['body'] = JSON.stringify(body)
        // const response = await fetch(url, { ...HEADERS_GET, signal: state.fetchControllerSignal });
        const response = await fetch(url, requestInit);
        if (response.ok) {
            // get text or get json?
            const content_type = response.headers.get('Content-type') || ''
            if (['application/json', 'application/vnd.api+json'].includes(content_type)) {
                // icb_user returns 'application/json', JSONAPI returns 'application/vnd.api+json'
                const json = await response.json();
                return json;
            } else if (content_type === 'text/plain; charset=UTF-8') {
                const text: string = await response.text();
                return text;
            } else {
                const msg = `Unknown content-type: ${content_type}. Request: ${url}`
                log.error(msg)
                return (msg)
            }
        } else {
            // Make sure intro is not shown
            if (state.loggedIn === -1) {
                dispatch({ type: 'setState', state: { ...state, loggedIn: 0 } })
            }
            if (response.status === 503) {
                // test if we are in maintenance mode and get maintenance mode text
                const resp = await fetch(`${BACKEND}/user/login_status?_format=text`);
                const maintenanceMessage = await resp.text()
                console.log(maintenanceMessage)
                dispatch(getActionSetConfirm(maintenanceMessage));
            } else {
                dispatch(getActionSetConfirm(`Network response was not OK. Status: ${response.status}. Status Text: ${response.statusText}. Type: ${response.type}`));
            }
        }
    } catch (error: any) {
        return fetchFailed(state, dispatch, url, error)
        // // Make sure intro is not shown, show msg to user and log message
        // if (state.loggedIn === -1) {
        //     dispatch({ type: 'setState', state: { ...state, loggedIn: 0 } })
        // }
        // if (error.name === 'AbortError') {
        //     log.info(`${error.name} ${url}`);
        // } else if (['Failed to fetch', 'Load failed'].includes(error.message) || error.message.includes('ERR_INTERNET_DISCONNECTED')) {
        //     dispatch(getActionSetConfirm('Please check your internet connection and try again.'));
        //     // log.info(error.message, 'Network error: Internet is disconnected or unreachable.'); now reason to log this in central log as it would force mail to admin
        // } else {
        //     const msg = `Error. Contact ICB. Error: ${error.message} Request: ${url} Function: getDD()`
        //     dispatch(getActionSetConfirm(msg));
        //     log.error(msg);
        // }
        // return error.message || error
    }
}

export function zeroPad(num: number, size: number) {
    let sNum = num.toString();
    while (sNum.length < size) sNum = "0" + num;
    return sNum;
}

export function afterLogout(state: TypeState, dispatch: React.Dispatch<any>, navigate: NavigateFunction, route = '/') {
    let action: ActionSetState = {
        type: 'setState', state:
        {
            ...STATEINIT,
            // The values below should NOT fallback to default once a user logs out
            configuration: state.configuration,
            user: {
                ...STATEINIT.user,
                locale: state.user.locale,
            },
            portrait: state.portrait,
            nativeApp: state.nativeApp,
            showPracticeProgram: state.showPracticeProgram,
            loggedIn: 0,
            fetchController: new AbortController(),
            fetchControllerSignal: null,
        }
    }
    dispatch(action);

    localStorage.removeItem(`ìcb_${__APP_VERSION__}`);

    prefix.apply(log, { template: 'Anonymous (0)' })

    // on native app goto login else goto landing page
    if (state.nativeApp || getEnvironment() === EnvironmentType.dev || route !== '/') {
        navigate(route)
    } else {
        window.location.href = landingPage();
    }
}

export async function logoutNow(state: TypeState, dispatch: React.Dispatch<any>, navigate: NavigateFunction, route = '/') {
    log.info(`logoutNow()`)
    try {
        // state.fetchController.abort(USER_HAS_LOGGED_OUT)
        state.fetchController.abort()
        // we can not use the standard getDD becuase that takes fetchController into account and we want to logout no matter what! await getDD(state, dispatch, `${BACKEND}/user/logout`, HEADERS_GET, false);
        // fetch(`${BACKEND}/user/logout`, HEADERS_GET);
        await icbControllerGenerel02(state, { "opr": "logout" })
        // don't show error if logout error. We get logout error is users session
        // is destroyed by script icb_scripts/icb_set_users_to_free_if_subscription_expired.php
    } catch (error) {
        log.error('ICB logout', error)
    }
    afterLogout(state, dispatch, navigate, route)
}

// Logout
export async function logout(state: TypeState, dispatch: React.Dispatch<any>, navigate: NavigateFunction) {
    if (state.curPractice.selectedExercises.length > 0 && state.curPractice.dirty) {//selectedExercises.length > 0 && state.practiceProgramDirty) {
        dispatch(getActionSetConfirm(i18next.t("Generel18"), 'OK', () => logoutNow(state, dispatch, navigate)));
    } else {
        logoutNow(state, dispatch, navigate)
    }
}

/*
Handle translatable nodes with translatable related files. Ie. video exercise files!
Here we test if we are allowed to delete a translatable related file.
If we create a node with a translatable related file then the initial langcode is 'en'
If we work on a node language version !== 'en' then we are only allowed to delete
the translatable related file if the file was created after the node. Becuase that 
will indicate that the file was added to the node after the node language was 
created
*/
function deleteRelatedFile(node: DrupalEntity, file: DrupalEntity): boolean {
    console.log('node', node.attributes.created, node.attributes.langcode)
    console.log('file', file.attributes.created)
    return node.attributes.langcode === 'en' || file.attributes.created > node.attributes.created
}

// Save node/user with zero or more file relations updated and return updated node/user
/*
TO DO I am not happy with this function. Look at the way it is called in ExerciseCreate
and the follow up call to nodeCRUD. Could this function be replaced by a number of calls
to nodeCRUD? The reason for this function is also to delete files but perhaps Drupal deletes
files when Drupal see that files are not longer related to entities or users?
*/
export async function saveNodeWithFileRelations(
    state: TypeState
    , dispatch: React.Dispatch<any>
    , node: DrupalEntity                            // node to update or create
    , fileRelationships: Array<FileRelationship>    // list of file relationships
) {
    log.info(`START saveNodeWithFileRelations ${node.type}`);

    if (!node.id) {
        // Create node that has relationships to files
        const resp1 = await nodeCRUD(state, dispatch, CRUD.Create, node)
        if (!resp1.data)
            return resp1
        // If there are no associated files to handle then just exit
        if (!fileRelationships.find(x => x.file || x.remove))
            return resp1;
        // Keep on working with the node we just created
        node = resp1.data
    }

    let deletes: Array<DrupalEntity> = []

    for (const fileRelation of fileRelationships) {
        // IT MAKES NO SENSE TO ASK FOR REMOVAL AND PROVIDE NEW. IN THAT CASE YOU SHOULD JUST PROVIDE NEW
        if (fileRelation.remove && fileRelation.file)
            alert('INCONSISTENCY. YOUR ASK FOR REMOVAL AND PROVIDE NEW AT THE SAME TIME')

        // do we have a file relation already on the this field?
        const fileID = node.relationships && node.relationships[fileRelation.field] && node.relationships[fileRelation.field].data && node.relationships[fileRelation.field].data.id  // TO DO shorten this line!

        if (fileID && fileRelation.remove) {
            // remove old relation. 1. If allowed, remove old file. 2. Remove relation on node. 3. Update node
            if (deleteRelatedFile(node, state.allFiles.find(x => x.id === node.relationships[fileRelation.field].data.id)!))
                deletes.push(node.relationships[fileRelation.field].data)
            let relationships: { [key: string]: any } = {};
            relationships[fileRelation.field] = { data: null, type: 'file--file' }
            const resp2 = await nodeCRUD(state, dispatch, CRUD.Update, { type: node.type, id: node.id, relationships: relationships })
            if (!resp2.data)
                return resp2
        }

        if (fileID && fileRelation.file) {
            // replace old relation. 1. If allowed, remove old file
            if (deleteRelatedFile(node, state.allFiles.find(x => x.id === node.relationships[fileRelation.field].data.id)!))
                deletes.push(node.relationships[fileRelation.field].data)
        }

        if (fileRelation.file) {
            // add new relation. 1. Upload file and node field will be updated by backend
            const resp3 = await nodeCRUD(state, dispatch, CRUD.Update, { type: node.type, id: node.id }, fileRelation.file, fileRelation.field)
            if (!resp3.data)
                return resp3
        }
    }

    // Update node/user with 'other' data and get latest file relationships
    const resp4 = await nodeCRUD(state, dispatch, CRUD.Update, { type: node.type, id: node.id, attributes: node.attributes })
    if (!resp4.data)
        return resp4

    // Do deletes last so we don't risk updating the node/user and file relations have already been deleted.
    deletes.forEach(x => nodeCRUD(state, dispatch, CRUD.Delete, x))

    log.info(`STOP saveNodeWithFileRelations ${node.type}`);
    return resp4
}

// Save node and return updated node
export async function nodeCRUD(
    state: TypeState
    , dispatch: React.Dispatch<any>
    , crud: CRUD                // Create, Update or Delete
    , node: DrupalEntity        // node to update, delete or create
    , file?: File               // file upload to node relation 
    , field?: string            // node relation for uploaded file
) {
    // url to resource type
    // Only user icoachbasketball.com is allowed to use translations. Other uses use default language.
    const languagePath = state.user.login.current_user.uid === 7 ? `${formatLanguage(state.user.locale)}/` : ''
    let url = `${BACKEND}/${languagePath}${JSONAPIPATH}/${node.type.split('--')[0]}/${node.type.split('--')[1]}`;
    // url to specific resource element
    if (crud === CRUD.Delete || crud === CRUD.Update || file)
        url += `/${node.id}`
    const callID = randomId().substring(0, 4) // for better debugging
    // url to specific resource element file relationship
    if (field)
        url += `/${field}`
    try {
        let operation = "POST"
        if (crud === CRUD.Update)
            operation = 'PATCH'
        if (crud === CRUD.Delete)
            operation = 'DELETE'

        // assume not file upload
        let header = getHeaders(state.user.login.csrf_token, operation, { "data": node })
        if (file)
            header = {
                method: 'POST',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/octet-stream",
                    // "Content-Language":  state.user.login.current_user.uid === 7 ? `${formatLanguage(state.user.locale)}` : '',
                    "Accept": "application/vnd.api+json",
                    "X-CSRF-Token": state.user.login.csrf_token,
                    // Replace non-ISO-8859-1 characters with an underscore or similar
                    "Content-Disposition": `file; filename="${randomId()}-${file.name.replace(/[^A-Za-z0-9.\-_]/g, '_')}"`,
                },
                body: file,
            }

        log.debug(`call id: ${callID} ${JSON.stringify(header)}`);
        const resp = await fetch(url, header)
        log.debug(`call id: ${callID} ${resp.status} ${JSON.stringify(header)}`);
        if ([200, 201].includes(resp.status)) { // 200: retrive/update, 201: create
            const data = await resp.json()
            dispatch({ type: 'setContentEntity', contentType: file ? 'file--file' : node.type, data: [data.data], initialLoad: false })
            // return updated node
            return data
        } else if ([204].includes(resp.status)) { // No Content - successfull delete
            dispatch({ type: 'delContentEntity', contentType: file ? 'file--file' : node.type, id: node.id })
            return
        } else { // if ([403, 405, 422, 503].includes(resp.status)) { 
            // 403/Forbidden
            // 405/Method Not Allowed (ie. users updates exercise in language different from exercise creation language)
            // 422/Unprocessable Content/Entity is not valid: The content has either been modified by another user, or you have already submitted modifications. As a result, your changes cannot be saved
            // 503/Service Unavailable
            // AND ALL OTHER
            const data = await resp.json()
            const msg = `Please login again. If the problem persists then contact ICB. ${node.type}/${node.id}/${data.errors[0].status}/${data.errors[0].title}/${data.errors[0].detail}`
            return msg
        }
    } catch (error: any) {
        // We get "Failed to fetch" if there is no network. That error should not trigger an email to developers!
        /*
        20/12/24 I got "Load failed" and I can't see traces of the request on the server. Could it be that the browser failed to get any response from the requested URL
        Like no internet - in that case we should not log as an error and send mail but we should just ask user to check connection
        show msg - Please check your internet connection and try again.
        There are other places we do that - see getDD()
        */
        return fetchFailed(state, dispatch, url, error)
        // let msg
        // if (error instanceof TypeError && error.message === 'Failed to fetch') {
        //     msg = `${error.message} (${node.type}/${node.id})`
        // } else {
        //     msg = `Error. Contact ICB (${error} (${node.type}/${node.id})) Function: nodeCRUD() A: ${JSON.stringify(error)} B: ${typeof error} C: ${error.constructor.name}`
        // }
        // log.info(`call id: ${callID} ${msg}`);
        // return msg // dispatch(getActionSetConfirm(msg));
    }
}

// Call ICB module
export async function icbControllerGenerel02(state: TypeState, body: any, path = 'icb') {
    const url = `${BACKEND}/${path}`;
    // log.info(`${url} - icbControllerGenerel02() opr: ${JSON.stringify(body)}`);
    try {
        const resp = await fetch(url, getHeaders(state.user.login.csrf_token, 'POST', body))
        log.debug(`icbControllerGenerel02(), returned resp: ${JSON.stringify(resp)}`)
        if (!resp.ok) {
            // get text or get json?
            const content_type = resp.headers.get('Content-type')
            if (content_type === 'application/vnd.api+json') {
                const json = await resp.json();
                return json.error;
            } else if (content_type === 'text/plain; charset=UTF-8' || content_type === 'text/html; charset=UTF-8') {
                const text: string = await resp.text();
                return { ok: false, error: text }
            } else {
                const msg = `Unknown content-type: ${content_type}. Request: ${url}`
                log.error(msg)
                return { ok: false, error: msg }
            }
            // const json = await resp.json()
            // return json.error
        }
        const json = await resp.json()
        log.debug(`icbControllerGenerel02(), returned json: ${JSON.stringify(json)}`)
        return json
    } catch (error) {
        log.debug(`icbControllerGenerel02(), returned error: ${JSON.stringify(error)}`)
        // We get "Failed to fetch" if there is no network. That error should not trigger an email to developers!
        let msg
        if (error instanceof TypeError && error.message === 'Failed to fetch') {
            msg = `${error.message}`
        } else {
            msg = `Error. Contact ICB Error: ${error} Function: icbController02()`
        }
        log.info(`${msg}`);
        return { ok: false, error: msg }
    }
}

// Setup Stripe Subscription for the given user type/subscription type. 
export async function create_checkout_session(state: TypeState, dispatch: React.Dispatch<any>, body_set_subscription: any) {
    log.info(`create_checkout_session() from: "${state.user.data.attributes.field_user_type}" to "${body_set_subscription.field_user_type}"`);
    // Stripe gives "Non-ASCII characters in URLs must be percent-encoded in order for the URL to be valid." if we do not URL encode
    body_set_subscription.field_club_name = encodeURIComponent(body_set_subscription.field_club_name)
    let body = {};
    // Pay and set subscription info in Drupal once backend receives Stripe message invoice.paid
    body = {
        "opr": "create_checkout_session",
        "locale": formatLanguage(state.user.locale) === 'ca' ? 'es' : formatLanguage(state.user.locale),
        "base_path": import.meta.env.BASE_URL || '/',
        "body_set_subscription": JSON.stringify(body_set_subscription),   // value is used when app restarts to set uses new subscription
    };
    const resp = await icbControllerGenerel02(state, body)
    if (!resp.ok) {
        dispatch(getActionSetConfirm(resp.error))
        return
    }
    window.location.href = resp.location || ''
}

export function rereadUser(state: TypeState, dispatch: React.Dispatch<any>, id: string) {
    let user = state.allUsers.find(x => x.id === id);
    log.debug(`rereadUser() - ${user!.attributes.display_name} (${user!.attributes.drupal_internal__uid})`);
    let filter = `?filter[id]=${id}&include=user_picture,field_my_club_admin,field_club_logo`;
    // fetchNodes('user', 'user', filter, state, dispatch)
    //     .then((data) => {
    //         dispatch({ type: 'setUserUsers', collected: data })
    //     });
    getAllContentEntities(state, dispatch, 'user--user', filter);
}

export function goHome(dispatch: React.Dispatch<any>, navigate: NavigateFunction) {
    navigate('/home');
    let action: ActionGoHome = { type: 'goHome' }
    dispatch(action);
    // dispatch({ type: 'setAppBarShowTabs', show: false });
    // window.scrollTo(0, 0); // this will take us all the way up.
}

// Show confirmation dialog. Potentially execute code when cnfirm.
export function getActionSetConfirm(textConfirm: string | undefined = undefined, textButton: string | undefined = undefined, codeButton: () => any = () => { }): ActionSetConfirm {
    return {
        type: 'setConfirm',
        confirm: {
            text: textConfirm,
            buttonText: textButton,
            code: codeButton,
        }
    };
}

// Open create exercise but first check that free user has not reached limit
export function navigateExerciseCreate(state: TypeState, dispatch: React.Dispatch<any>, navigate: NavigateFunction) { //, txt1: string, txt2: string, txt3: string) {
    // t('AlertMsg12'), t('ICBAppBar04'), t('Generel13'))}>
    if (
        state.user.data.attributes.field_user_type === "free" &&
        state.allExercises.filter(x => x.relationships.uid.data.id === state.user.data.id).length >= state.configuration[0].attributes.field_max_exercises_free_user
    ) {
        dispatch(getActionSetConfirm(i18next.t('AlertMsg12'), i18next.t('ICBAppBar04'), () => navigate('/setsubscription')));
    } else {
        navigate("/exercisecreate");
    }
}

export function getImageURL(fileName: string): string {
    let res = `${import.meta.env.BASE_URL}images/${fileName}`;
    return res;
}

// JSONAPITypeId structure with File ID to URL. If no file ID in structure or no file in state.allFiles then return undefined
// export const fileData2FileURL = (state: TypeState, data: JSONAPITypeId | null) => data?.id && state.allFiles.find(x => x.id === data.id)?.attributes.uri.url;

// export const exerciseImageOrBlankBoard = (fileImage: string) => fileImage ? `${BACKEND}${fileImage}` : getImageURL('boardBlank.webp');

// test to get multi media from CDN
// export const exerciseImageOrBlankBoard = (fileImage: string) => fileImage ? `http://ceipi.dk/icb/var/www/test.data.icoachbasketball.netmaster.dk/web${fileImage}.webp` : getImageURL('boardBlank.webp');
// export const exerciseImageOrBlankBoard = (fileImage: string) => fileImage ? `${CDN}${fileImage}.webp` : getImageURL('boardBlank.webp');

// If exercise image belongs to the exercise that was just created by the user then we
// add a cache blocker in the image to make sure we try reload the image until we find
// the image in the CDN
export const exerciseImage = (state: TypeState, fileID: string, exerciseID: string = '') =>
    fileID ?
        `${CDN}${state.allFiles.find(x => x.id === fileID)?.attributes.uri.url}.webp${exerciseID === state.exerciseIDLastCRUD ? "?" + new Date().getTime() : ''}`
        : getImageURL('boardBlank.webp');
// export const exerciseVideo = (state: TypeState, fileID: string) => `${CDN}${state.allFiles.find(x => x.id === fileID)?.attributes.uri.url}.mp4`;
export function exerciseVideo(state: TypeState, fileID: string): string {
    const URL = `${CDN}${state.allFiles.find(x => x.id === fileID)?.attributes.uri.url}.mp4`
    // console.log(`exerciseVideo: ${state.allFiles.length} ${fileID} ${URL}`)
    // return `${CDN}${state.allFiles.find(x => x.id === fileID)?.attributes.uri.url}.mp4`;
    return URL
}

export function getEnvironment(): EnvironmentType {
    if (window.location.hostname === 'app.icoachbasketball.com') return EnvironmentType.prod;
    if (window.location.hostname === 'www.icoachbasketball.com') return EnvironmentType.prod;
    if (window.location.hostname === 'test.app.icoachbasketball.netmaster.dk') return EnvironmentType.test;
    if (window.location.hostname === 'testspottps.netmaster.dk') return EnvironmentType.test;
    if (window.location.hostname === 'localhost') return EnvironmentType.dev;
    log.error(`Error: getEnvironment() can't get environment, window.location: ${JSON.stringify(window.location)}`)
    return EnvironmentType.unknown;
}

export const landingPage = (): string => {
    let env = getEnvironment();
    if (env === EnvironmentType.prod)
        return 'https://www.icoachbasketball.com/';
    else if (env === EnvironmentType.test)
        return 'https://test.www.icoachbasketball.netmaster.dk/';
    else if (env === EnvironmentType.dev)
        return 'https://test.www.icoachbasketball.netmaster.dk/';
    log.error(`Error: can't find landing page based on envrionment`)
    return '';
}

export function nakedPage(): boolean {
    const ret = NAKEDPAES.includes(window.location.pathname.toLowerCase())
    return ret
}

// Send message to native app. Supply uid of Drupal user, uuid (not used), and a command number
// Command number is used to tell native app what to do
export function inAppPaymentApple(state: TypeState, dispatch: React.Dispatch<any>, cmd: string) {
    switch (cmd) {
        case "0":
            log.info(`ask native app for iOS payment status`)
            break
        case "1":
            log.info(`show payment wall`)
            break
    }
    const toXcode = { "uid": state.user.data.attributes.drupal_internal__uid, "uuid": "notUsed", cmd: cmd }
    if ((window as any).webkit) {
        (window as any).webkit.messageHandlers?.SOME_BRIDGE?.postMessage(toXcode)
    } else {
        dispatch(getActionSetConfirm('Your are running native app but webkit not available'))
    }
}

export function getUser(state: TypeState): DrupalEntity | undefined {
    const user = state.allUsers.find(x => x.attributes.drupal_internal__uid === state.user.login.current_user.uid)
    return user
}

export function getClub(state: TypeState): string | undefined {
    const user = getUser(state)
    if (user?.attributes.field_user_type === UserType.club && user?.attributes.field_club_admin_accept) {
        const clubAdmin = state.allUsers.find(x => x.id === user.relationships.field_my_club_admin.data.id)
        return clubAdmin?.attributes.field_club_name
    }
    if (user?.attributes.field_user_type === UserType.clubadmin) {
        return user?.attributes.field_club_name
    }
}

export function getFilterExercise(state: TypeState, group: DrupalEntity): string {
    // Access rights for user 7 is a special case because user 7 has access to
    // all data as user 7 is a Drupal admin. Here we make sure that when user 7 runs
    // the app user 7 retrieves exercises like any other user running the app
    // If we did not limit user 7 to user 7 exercises then user 7 would retrieve all exercises!
    const filterForUser7 = state.user.login.current_user.uid === 7 ? `&filter[uid.meta.drupal_internal__target_id]=7` : '';
    return `?filter[field_group.id]=${group.id}${filterForUser7}&include=field_exercise_board_image,field_exercise_video,uid,field_original_author,uid.user_picture,field_original_author.user_picture`;
    // return `?filter[field_group.id]=${group.id}&filter[field_discontinued]${encodeURI('=')}null${filterForUser7}&include=field_exercise_board_image,field_exercise_video,uid,field_original_author,uid.user_picture,field_original_author.user_picture`;
}

// Msg to user now that subscription has been updated
export function postSetSubscriptionMessage(state: TypeState, dispatch: React.Dispatch<any>, newUserTypeAfterStripeCheckout: any) {
    switch (newUserTypeAfterStripeCheckout) {
        case UserType.free:
            dispatch(getActionSetConfirm(i18next.t('AlertMsg07')));
            break;
        case UserType.pro:
            dispatch(getActionSetConfirm(i18next.t('AlertMsg08')));
            break;
        case UserType.club:
            // if subscription changed from pro to club and pro was created using in-app payment
            // then tell user to stop subscription in App Store
            const appStoreFromProToClub = state.user.data.attributes.field_user_type === 'pro'
                // Not needed as we are in the correct case entry? && body_set_subscription.field_user_type === 'club'
                && state.user.data.attributes.field_app_store_original_transac
            dispatch(getActionSetConfirm(i18next.t(appStoreFromProToClub ? 'AlertMsg09a' : 'AlertMsg09')));
            break;
        case UserType.clubadmin:
            dispatch(getActionSetConfirm(i18next.t('AlertMsg10'), 'OK'));
            break;
    }
}

// Update subscription for current user
export async function updateSubscriptionInDrupal(state: TypeState, dispatch: React.Dispatch<any>, body_set_subscription: any) {
    let resp = await icbControllerGenerel02(state, body_set_subscription)
    if (!resp.ok) {
        dispatch(getActionSetConfirm(resp.error))
        return resp
    }

    /*
    24/4/24 Gabriel has show how a newly created user who updates to a paying subscription still has user type = free after
    reloadApp(state.nativeApp, '/home') below.
    I think that JSONAPI does not pick up the latest user->field_user_type after the reloadApp(state.nativeApp, '/home') below.
    To help JSONAPI pull data from database and not from cache we do a JSONAPI save/PATCH of the user below
    First we get the uid of the user as we only have the drupal_internal__uid
    */
    resp = await getDD(state, dispatch, `${BACKEND}/${JSONAPIPATH}/user/user?filter[uid]=${state.user.login.current_user.uid}`);
    if (!resp)
        return;
    await nodeCRUD(state, dispatch, CRUD.Update, {
        type: 'user--user',
        id: resp.data[0].id,
    })

    // TO DO why is reloadApp() needed? Get rid of query string so users don't refresh (F5) and then executes same start up again?

    // If we come back from Stripe payment with success we have info on the query string that holds data subscription in
    // Drupal can be updated below. That info has to be cleared so the code below is not rerun if the user refreshes (F5)
    // window.history.replaceState(nextState, nextTitle, nextURL);
    // See https://www.30secondsofcode.org/js/s/modify-url-without-reload/
    window.history.replaceState('', '', location.origin + location.pathname)

    postSetSubscriptionMessage(state, dispatch, body_set_subscription.field_user_type);
}

// Get tex and list of images for a single practice
export function getPracticeTex(state: TypeState, practice: PracticeBetter): [string, Array<string>] {
    let tex = ''
    let images: Array<string> = []

    // Values to put in tex
    const field_team_name = lescape(practice.team?.attributes.title || 'No Name').trim()
    const pdate = new Date(practice.date)
    const field_practice_date = `${pdate.toLocaleDateString()} ${pdate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
    const field_practice_note = lescape(practice.note || '').trim()

    // Show club logo?
    const urlClubLogo = getFileClubLogo(state)
    let pathClubLogo = ''
    if (urlClubLogo) {
        // Yes, show club logo
        images.push(urlClubLogo)
        pathClubLogo = '.' + new URL(urlClubLogo).pathname + '.png'
    }

    // values before list of exercises
    const nameDateNote = `\\raggedright \\Large \\textbf{${field_team_name}}\\normalsize \\newline ${field_practice_date}\\newline ${field_practice_note ? field_practice_note + '\\newline' : ''}`
    if (pathClubLogo) {
        tex += '\\begin{tabularx}{\\linewidth}{ > { \\hsize=.6\\hsize }X > { \\hsize=.4\\hsize }X }\n'
        tex += `${nameDateNote} & \\raggedleft \\includegraphics[align = t, width = 1.5cm]{${pathClubLogo}}\\\\\n`
        tex += '\\end{tabularx}\n'
    } else {
        tex += `${nameDateNote}\n`
    }

    // seperator between team name/date/practice note and exercises
    // tex += `\\noindent\\makebox[\\linewidth]{\\rule{\\linewidth}{0.4pt}}\\vspace{4mm}\\newline\\\n`
    tex += `\\noindent\\makebox[\\linewidth]{\\color{lightgray}\\rule{\\linewidth}{.5pt}}\\vspace{4mm}\\newline\\\n`

    // variables used in loop of exercises for current practice
    let texX = new Array<string>(4)
    let endDateTime = new Date(practice.date)
    let index = -1

    for (const exerciseEncoded of practice.selectedExercises) {
        index += 1

        // Get single exercise
        let exercise = state.allExercises.find(z => z.attributes.drupal_internal__nid === exerciseEncoded.drupal_internal__nid) || DRUPALENTITYINIT
        if (!exercise.id) {
            exercise.attributes.title = 'no access to drill'
            exercise.attributes.field_description = ''
        }

        // Values to put in tex
        const title = lescape(exercise.attributes.title || '-').trim()

        // Replace the "ENTER FOR A NEW LINE" for a LaTex command that creates a newline
        const field_description = lescape(exercise.attributes.field_description || '-')
            .replace(/\n/g, '\\newline ')
            .trim();
        const coachNote = lescape(exerciseEncoded.coachNote || '')
            .replace(/\n/g, '\\newline ')
            .trim();

        // Calculate exercise time slot
        let startDateTime = new Date(endDateTime);
        endDateTime.setTime(startDateTime.getTime() + 60 * 1000 * (exerciseEncoded.durationMinutes || 0));
        const timeslot = `${zeroPad(startDateTime.getHours(), 2)}:${zeroPad(startDateTime.getMinutes(), 2)}-${zeroPad(endDateTime.getHours(), 2)}:${zeroPad(endDateTime.getMinutes(), 2)}`

        // two column layout
        // a column holds 1st line with title and timeslot
        // a column holds 2nd line with image (if available) and description + focus

        // title and timeslot
        // after 33 caracthers, the title jumps on the newline.
        // otherwise it would override the timeslot
        const formattedTitle = title.length > 33 ? `${title.substring(0, 33)}\\\\${title.substring(33)}` : title;
        texX[(index % 2)] = `\\raggedright \\parbox[t]{0.7\\linewidth}{\\textbf{${index + 1}. ${formattedTitle}}} & \\parbox[t]{0.3\\linewidth}{\\raggedleft \\textbf{${timeslot}}}`

        // image and description + focus
        // first, get image
        const fileIDImage = exercise.relationships?.field_exercise_board_image?.data?.id
        const url = exerciseImage(state, fileIDImage)
        images.push(url)
        let urlPath = '/images/boardBlank.webp'
        if (url !== '/images/boardBlank.webp')
            urlPath = new URL(url).pathname

        // texX[(index % 2) + 2] = `\\includegraphics[align=t,width=3.8cm]{.${urlPath}.png} & ${field_description}\\newline \\textbf{${i18next.t('Generel08')}}\\newline ${coachNote}`
        // Test program where exercise images are only displayed if they are available - don't show default exercise image!
        if (fileIDImage) {
            texX[(index % 2) + 2] = `\\includegraphics[align=t,width=3.8cm]{.${urlPath}.png} & {\\parbox[t]{0.3\\linewidth}{\\footnotesize ${field_description} \\vspace{2mm} \\newline \\textbf{${i18next.t('Generel08')}} \\newline \\footnotesize ${coachNote}}}`
        } else {
            texX[(index % 2) + 2] = `\\multicolumn{2}{l}{\\parbox[t]{0.45\\linewidth}{\\footnotesize \\vspace{1mm}  \\textbf{${i18next.t('ExerciseCardPrint00')}}\\newline ${field_description}\\vspace{2mm}  \\newline \\textbf{${i18next.t('Generel08')}}\\newline ${coachNote}}}`
        }

        if ((index % 2) === 1 || index === practice.selectedExercises.length - 1) {
            tex += `\\vspace{2mm}\n` // NEW LINE FROM GABRIEL
            tex += `\\begin{tabularx}{\\linewidth}{ >{\\hsize=.2\\hsize}X >{\\hsize=.3\\hsize}X >{\\hsize=.2\\hsize}X >{\\hsize=.3\\hsize}X }\n`
            tex += `${texX[0]} & ${texX[1] ? texX[1] : '&'}\\\\\n`
            tex += `${texX[2]} & ${texX[3] ? texX[3] : '&'}\\\\\n`
            tex += `\\end{tabularx}\n`
            texX[0] = texX[1] = texX[2] = texX[3] = ''
            tex += `\\vspace{2mm}\n` // NEW LINE FROM GABRIEL
            tex += index < practice.selectedExercises.length - 1 ? `\\vspace{2mm}\\noindent\\makebox[\\linewidth]{\\color{lightgray}\\rule{\\linewidth}{0.4pt}}\\\\\n` : ''; // NEW LINE FROM GABRIEL
        }
    }
    return [tex, images]
}

// see https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

// Club users uses the club logo of his club admin. Other uses use their own club logo
export function getFileClubLogo(state: TypeState) {
    const fileIDClubLogo = state.user.data.attributes.field_user_type === 'club' ?
        state.allUsers.find(x => x.id === state.user.data.relationships.field_my_club_admin.data.id)?.relationships.field_club_logo?.data?.id :
        state.user.data.relationships.field_club_logo?.data?.id;
    return fileIDClubLogo && exerciseImage(state, fileIDClubLogo)
}

// Create and send practice program in PDF
export function reportPracticeProgramEmail(state: TypeState, dispatch: React.Dispatch<any>, practiceID: string, send: boolean) {
    // Get and reset selections from CRUDList
    const emailReceivers = state.CRUDListSelectedValues?.join(' ')
    dispatch({ type: 'setCRUDListSelectedValues', selectedValues: [] })

    if (send) {
        if (!emailReceivers) {
            dispatch(getActionSetConfirm(i18next.t('MyContacts05')))
        } else {
            // Send report to emailReceivers
            const practiceBetter = mapPractice(state, state.allPractices.find(x => x.id === practiceID)!)
            const [tex, images] = getPracticeTex(state, practiceBetter)
            getFileICBLogo(state, true) && images.push(getFileICBLogo(state, false))

            // Generate PDF document with report and email to list of receivers
            const file = `${randomId()}.pdf`
            icbControllerGenerel02(state, {
                opr: 'get_pdf',
                images: [...new Set(images)],
                document: tex,
                file: file,
                fileICBLogo: getFileICBLogo(state, true),
                deliver: "mail",
                receivers: emailReceivers,
                emailBody00: i18next.t('emailBody00'),
                emailBody01: i18next.t('emailBody01'),
                emailSubject: i18next.t('emailSubject00'),
            })
                .then((resp) => {
                    if (!resp.ok) {
                        dispatch(getActionSetConfirm(resp.error))
                    }
                })
            dispatch(getActionSetConfirm(i18next.t('MyContacts06')))
        }
    }

}

// Create and show practice program in PDF
export async function reportPracticeProgramDownload(state: TypeState, dispatch: React.Dispatch<any>, navigate: NavigateFunction, practiceID: string) {
    dispatch({ type: 'setBackdrop', diff: 1 })

    // File user will download
    const file = `${randomId()}.pdf`

    // Find practice we want to email
    const practice = mapPractice(state, state.allPractices.find(x => x.id === practiceID)!)

    // Get tex and list of images in practice we want to create as PDF
    const [practiceTex, images] = getPracticeTex(state, practice)
    getFileICBLogo(state, true) && images.push(getFileICBLogo(state, false))

    // Create name of PDF file to download later. Call backend to generate PDF file
    const resp = await icbControllerGenerel02(state, {
        opr: 'get_pdf',
        images: [...new Set(images)],
        document: practiceTex,
        file: file,
        fileICBLogo: getFileICBLogo(state, true),
    })
    dispatch({ type: 'setBackdrop', diff: 0 })
    if (!resp.ok) {
        dispatch(getActionSetConfirm(resp.error))
        return
    }

    navigate('/practicereport', { state: `${BACKEND}/sites/default/files/icb_pdf/${file}` })
}

// Get ICB logo used on PDFs. If pathOnly then return . + path. Otherwise return URL
export function getFileICBLogo(state: TypeState, pathOnly: boolean): string {
    const fileIDICBLogo = state.configuration[0].relationships.field_icb_logo_for_pdf.data.id
    if (fileIDICBLogo)
        if (pathOnly)
            return '.' + new URL(exerciseImage(state, fileIDICBLogo)).pathname
        else
            return exerciseImage(state, fileIDICBLogo)
    else
        return '';
}

// /*
//     Save user profile with latest practice header info and save/update practice
//     If we don't have state.practiceID then a new practice is created
//     If we have a state.practiceID and we don't overwrite then a practice is created
//     If we have a state.practiceID and we overwrite then that practice is updated    
// */
export async function savePractice(state: TypeState, dispatch: React.Dispatch<any>, practice: PracticeBetter) {
    // create or update practice node
    // if new practice then attendance is that all players on the team are present
    let practiceNode: DrupalEntity = {
        type: "node--practice",
        attributes: {
            title: `${new Date(practice.date).toISOString()} ${practice.team.attributes.title}`,
            field_practice_date: practice.date.toISOString().slice(0, -5) + 'Z',
            body: {
                value: JSON.stringify({
                    note: practice.note,
                    attendance: practice.practiceID ?
                        practice.attendance.map((item) => { return { playerID: item.playerID, present: item.present } }) // existing attendance - make sure we don't save name of player
                        : practice.team!.relationships.field_players.data.map((x: JSONAPITypeId) => { return { playerID: x.id, present: true } }), // players on team
                    selectedExercises: practice.selectedExercises.map((item) => ({
                        drupal_internal__nid: item.drupal_internal__nid,
                        coachNote: item.coachNote,
                        durationMinutes: item.durationMinutes,
                    })),
                }),
                format: "plain_text"
            },
        },
        relationships: {
            field_team: {
                data: {
                    type: "node--team",
                    id: practice.team.id
                }
            }
        }
    }

    // add id if we update practice. Otherwise, we create practice
    if (practice.practiceID)
        practiceNode = { ...practiceNode, id: practice.practiceID }

    // save practice
    // log.info(practice.practiceID ? CRUD.Update : CRUD.Create, JSON.stringify(practiceNode))
    const respPractice = await nodeCRUD(state, dispatch, practice.practiceID ? CRUD.Update : CRUD.Create, practiceNode);
    if (respPractice.data) {
        // update global practice now we have practice ID
        const action: ActionSetPractice = {
            type: 'setPractice', practice: {
                ...practice,
                practiceID: respPractice.data.id,
                team: practice.team,
                dirty: false,
            }
        }
        dispatch(action)
    } else {
        return respPractice
    }
}

export async function saveUser(state: TypeState, dispatch: React.Dispatch<any>, drupalEntityData: DrupalEntityData) {
    // Save new user data
    const resp = await nodeCRUD(state, dispatch, CRUD.Update, {
        type: 'user--user',
        id: state.user.data.id,
        ...drupalEntityData
    })
    if (!resp.data) {
        dispatch(getActionSetConfirm(resp));
        return
    }
}

// return monday and sunday of current week. Credits https://camkode.com/posts/get-monday-and-sunday-of-the-week-in-javascript
export function mondaySunday() {
    const today = new Date(new Date().setHours(0, 0, 0, 0))
    const day = today.getDay()
    const diff = today.getDate() - day + (day === 0 ? -6 : 1)
    const monday = new Date(today.setDate(diff))
    const sunday = new Date(new Date(monday).setHours(23, 59, 59, 999))
    sunday.setDate(sunday.getDate() + 6)
    return [monday, sunday]
}

// Map practice in Drupal content node to practice in format 'PracticeBetter'
export function mapPractice(state: TypeState, p: DrupalEntity): PracticeBetter {
    const practiceParsed = JSON.parse(p.attributes.body.value)
    const coach = state.allUsers.find(x => x.id === p.relationships.uid.data.id)

    const practiceBetter: PracticeBetter = {
        // Fields stored in the node type practice in JSON string
        ...practiceParsed,
        date: new Date(p.attributes.field_practice_date), // practiceParsed.date),
        // Fields added when needed. Add on fields for reports etc
        practiceID: p.id,
        // FIND TEAM FROM THE PRACTICES AND NOT FROM LIST OF TEAMS
        // team: state.allTeams.find(x => x.relationships.field_practices.data.map((y: JSONAPITypeId) => y.id).includes(p.id)) || DRUPALENTITYINIT,
        team: state.allTeams.find(x => x.id === p.relationships.field_team.data.id),
        display_name: coach?.attributes.display_name,
        uid: coach?.id || '',
    }
    return practiceBetter
}

// Map preplanned practice in Drupal content node to preplanned practice in format 'PreplannedPractice'
export function mapPreplannedPractice(p: DrupalEntity): PreplannedPractice {
    const preplannedPracticeParsed = JSON.parse(p.attributes.body.value)

    const preplannedPractice: PreplannedPractice = {
        // Fields stored in the node type preplanned practice in JSON string
        note: preplannedPracticeParsed.note,
        selectedExercises: preplannedPracticeParsed.selectedExercises,
        teamplanAgeGroup: preplannedPracticeParsed.teamplanAgeGroup,
        teamplanSkillLevel: preplannedPracticeParsed.teamplanSkillLevel,
        teamplanDuration: preplannedPracticeParsed.teamplanDuration,
    }
    return preplannedPractice
}

export function getRelationshipsDataElementFor(node: DrupalEntity) {
    const dataElement: JSONAPITypeId = {
        type: node.type,
        id: node.id || '',
        meta: {
            drupal_internal__target_id: node.attributes.drupal_internal__nid
        }
    }
    return dataElement
}

// update relationship on node and return updated node
export function getNodeWithUpdatedRelationship(node: DrupalEntity, fieldName: string, fieldValue: JSONAPITypeId[]): DrupalEntity {
    const nodeUpdated: DrupalEntity = {
        ...node,
        relationships: {
            ...node.relationships,
            [fieldName]: {
                data: fieldValue
            }
        }
    }
    return nodeUpdated
}

// remove relationship from node with fieldName and id and return node
export function getNodeWithRemovedRelationship(node: DrupalEntity, fieldName: string, id: string): DrupalEntity {
    const playbookLocal = {
        ...node,
        relationships: {
            ...node.relationships,
            [fieldName]: {
                data: node.relationships[fieldName].data.filter((x: JSONAPITypeId) => x.id !== id)
            }
        }
    }
    return playbookLocal
}

// create structure that goes to the back end to create playbook in the file public area.
// created structure is based on plays in state.allPlays. If playUpdated has a value then use that specific play and
// not the play in state.allPlays. This is needed because we want to run this function before state.allPlays
// is updated with playUpdated. 
export async function getPlaybookSharable(state: TypeState, playbook: DrupalEntity, playUpdated: DrupalEntity = DRUPALENTITYINIT) {
    const playbookSharable: PlaybookSharable = {
        playbookTime: new Date().toLocaleString(formatLanguage(state.user.locale)),
        plays: playbook.relationships.field_plays.data.map((item: JSONAPITypeId) => {
            const play = playUpdated.id === item.id ? playUpdated : state.allPlays.find(x => x.id === item.id)
            const playDetails: PlayDetails = JSON.parse(play?.attributes.field_play_details)
            return {
                nid: play?.attributes.drupal_internal__nid,
                frames: playDetails.frames,
                svgContent: generateSVGContent(playDetails.frames, playDetails.courtType),
                animationScript: generateAnimationScript(playDetails.frames, 0)
            }
        })
    }

    // compress and mangle generated javascript code so code in playbook becomes a little more difficult to deciffer
    for (let i = 0; i < playbookSharable.plays.length; i++) {
        const result = await minify(playbookSharable.plays[i].animationScript)
        playbookSharable.plays[i].animationScript = result.code || ''
    }

    return playbookSharable
}

// get MenuItems for type for use in Select dropdown
export function menuItemsForType(type: any) {
    return (
        Object.keys(type).map((item, index) =>
            <MenuItem
                key={index}
                value={item}
            >
                {`${i18next.t('EnumKey' + item)}`}
            </MenuItem>
        )
    )
}

// return property value from configuration json field if available. Otherwise return false
export function getConfigValue(state: TypeState, configProp: string) {
    if (state.configuration.length > 0
        && state.configuration[0].attributes.field_configuration)
        return JSON.parse(state.configuration[0].attributes.field_configuration)[configProp]
    else
        return false
}

export function setUISetting(state: TypeState, dispatch: React.Dispatch<any>, field: UISettingKeys, value: any) {
    const ui_settings = {
        ...state.uiSettings,
        [field]: value,
    }
    getDD(state, dispatch, `${BACKEND}/icb-user/set_first_person_user_field_value/field_ui_settings`, 'POST', JSON.stringify(ui_settings))
        .then(() => {
            const action: ActionSetUISettings = { type: 'setUISettings', uiSettings: ui_settings }
            dispatch(action)
        })
}

