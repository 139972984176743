import { Box, Button, CardMedia, Fab, Paper, Typography, useTheme } from '@mui/material';
import { Fragment, useContext, useRef, useState } from 'react';
import { Context } from '../App';
import { exerciseImage, } from '../misc/Functions';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useTranslation } from 'react-i18next';
import log from "../misc/Logger";
import { DialogExerciseDetails } from './DialogExerciseDetails';
import DialogExerciseDurationAndFocus from './DialogExerciseDurationAndFocus';
import { DialogShowVideo } from './DialogShowVideo';
import { Tooltip } from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FiberNewRoundedIcon from '@mui/icons-material/FiberNewRounded';
import AuthorImageAndName from './AuthorImageAndName';
import Favorite from './Favorite';
import { DrupalEntity, JSONAPITypeId, PropsExerciseCard, TypeContext } from '../misc/Types';

export default function ExerciseCard(props: PropsExerciseCard) {
  const { state } = useContext(Context) as TypeContext;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const [openDialogExerciseDetails, setOpenDialogExerciseDetails] = useState(false)
  const [openDialogExerciseDurationAndFocus, setOpenDialogExerciseDurationAndFocus] = useState(false);
  const [conceptFileID, setConceptFileID] = useState('') // Controls open/close of DialogShowVideo
  const [concept, setConcept] = useState('') // Title for video dialog
  const [retries, setRetries] = useState(0)

  // Value for repostion of video play icon 
  // const [videoPlayIconLeft, setVideoPlayIconLeft] = useState(0);
  // Initially show exercise description. In portrait mode there is an option not to show exercise description
  const [showExerciseDescription, setShowExerciseDescription] = useState(false);
  const [showConcepts, setShowConcepts] = useState(false);
  // const [imageLoadTime, setImageLoadTime] = useState(new Date().getTime())
  const imageRef = useRef<HTMLImageElement>(null);
  const fileIDVideo = props.exercise.relationships.field_exercise_video.data?.id;
  const fileIDImage = props.exercise.relationships.field_exercise_board_image.data?.id;

  // Set flag on new exercises. Determined by configuration
  const newExercise = () => {
    let nowMinusXDays = Date.now() - parseInt(state.configuration[0].attributes.field_days_to_flag_new_exercises) * 24 * 60 * 60 * 1000;
    return (Date.parse(props.exercise.attributes.created) > nowMinusXDays || Date.parse(props.exercise.attributes.changed) > nowMinusXDays)
  }

  // Set flag on new concept. Determined by configuration
  function newConcept(concept: DrupalEntity): boolean {
    let nowMinusXDays = Date.now() - parseInt(state.configuration[0].attributes.field_days_to_flag_new_concept) * 24 * 60 * 60 * 1000;
    return (Date.parse(concept.attributes.created) > nowMinusXDays || Date.parse(props.exercise.attributes.changed) > nowMinusXDays)
  }

  return (
    <Fragment>

      <DialogExerciseDetails
        open={openDialogExerciseDetails}
        onClose={() => setOpenDialogExerciseDetails(false)}
        exercise={props.exercise}
        addOption={true}
      />

      <DialogExerciseDurationAndFocus
        open={openDialogExerciseDurationAndFocus}
        onClose={() => setOpenDialogExerciseDurationAndFocus(false)}
        exercise={props.exercise}
      />

      <DialogShowVideo
        open={conceptFileID !== ''}
        onClose={() => setConceptFileID('')}
        fileIDVideo={conceptFileID}
        concept={concept}
      />

      <Paper
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '350px',
          margin: 'auto',
          padding: state.portrait ? 1 : 1.5,
          borderRadius: state.portrait ? 2 : 5,
        }}
      >

        <Box display={'flex'} sx={{ marginBottom: 1 }}>
          <AuthorImageAndName node={props.exercise} />

          {/* New exercise - pushed to the right */}
          <Box marginTop={'auto'} marginBottom={'auto'} marginLeft={'auto'}>
            {
              newExercise() ?
                <FiberNewRoundedIcon color={'secondary'} />
                : <></>
            }
          </Box>
        </Box>

        {/* Image. If video available and hover then show video */}
        <Box position='relative'>
          <CardMedia
            sx={{ display: 'block', maxHeight: '300px', objectFit: 'contain', margin: 'auto', borderRadius: 2 }}
            component='img'
            src={exerciseImage(state, fileIDImage, props.exercise.id)}
            // If image is not jet on CDN then retry in a 5s.
            onError={() => {
              if (state.exerciseIDLastCRUD === props.exercise.id) {
                if (retries < 6)
                  setTimeout(() => {
                    setRetries(retries + 1)
                    log.info(`reload image: ${exerciseImage(state, fileIDImage)}`);
                    const src = exerciseImage(state, fileIDImage, props.exercise.id);
                    if (imageRef && imageRef.current && imageRef.current.src)
                      imageRef.current.src = src;
                  }, 5000)
                else
                  log.error(`Error reload image: ${exerciseImage(state, fileIDImage)}`);
              }
            }}
            alt={`${state.exerciseIDLastCRUD === props.exercise.id ? t('ExerciseCard05') : t('ExerciseCard06')}`}
            ref={imageRef}
            onClick={() => setOpenDialogExerciseDetails(true)}
          />
          {/* reposition video play icon 36px becuase it fits well with icon size of 24x24, ie PlayCircleOutlineIcon */}
          {/* <PlayCircleOutlineIcon sx={{ display: fileIDVideo ? 'block' : 'none', position: 'relative', top: '-36px', left: `${videoPlayIconLeft}px` }} /> */}
          <PlayCircleOutlineIcon sx={{ display: fileIDVideo ? 'block' : 'none', position: 'absolute', bottom: '4px', left: '4px' }} />
          <Tooltip title={t('ExerciseCard12')} enterDelay={1000}>
            <Fab
              color="secondary"
              size="small"
              sx={{
                position: 'absolute',
                bottom: '4px',
                right: '4px',
                opacity: 0.7, // para añadir transparencia
                transition: 'opacity 0.3s',
                '&:hover': {
                  opacity: 0.9,
                  backgroundColor: '#DD6F20',
                  color: 'white',
                },
              }}
              onClick={() => setOpenDialogExerciseDurationAndFocus(true)}
            >
              <AddRoundedIcon />
            </Fab>
          </Tooltip>
        </Box>

        {/* Use marginTop -2 because we pull text up into area where video play icon is before icon is repositioned */}
        <Box sx={{ display: 'flex', marginTop: 1 }}>

          {/* Edit icon */}
          {
            props.exercise.relationships.uid.data.id === state.user.data.id &&
            <IconButton size='small' onClick={() => { navigate("/exercisecreate", { state: props.exercise }); }}>
              <EditIcon />
            </IconButton>
          }

          {/* Favorite icon */}
          <Favorite node={props.exercise} field='field_favorite_exercises' />

          {/* Exercise Title. Use 'auto' on top and buttom margin to center text vertically */}
          <Typography sx={{ fontSize: { xs: 11, sm: 14 }, lineHeight: 1, fontWeight: 'bold', marginTop: 'auto', marginBottom: 'auto', width: 1 }} >
            {props.exercise.attributes.title}
          </Typography>

          {/* More icon to show concepts if there are any */}
          {
            props.exercise.relationships.field_concepts.data.length > 0 && (
              <Tooltip title={t('ExerciseCard11')}>
                <IconButton size='small' onClick={() => setShowConcepts(!showConcepts)}> {/* Cambiar a showConcepts */}
                  {showConcepts ? <TipsAndUpdatesIcon fontSize='small' color='primary' /> : <TipsAndUpdatesOutlinedIcon fontSize='small' />}
                </IconButton>
              </Tooltip>
            )
          }

        </Box>

        {!state.portrait && (
          <>
            <Typography sx={{ fontSize: { xs: 11, sm: 13 }, lineHeight: 1.4, whiteSpace: 'pre-wrap' }} marginLeft={0.5}>
              {showExerciseDescription
                ? props.exercise.attributes.field_description
                : props.exercise.attributes.field_description?.substring(0, 150) + (props.exercise.attributes.field_description?.length > 150 ? '...' : '')
              }
            </Typography>
            {props.exercise.attributes.field_description?.length > 150 && (
              <Typography
                onClick={() => setShowExerciseDescription(!showExerciseDescription)}
                sx={{
                  textTransform: 'none',
                  paddingLeft: 0.5,
                  paddingBottom: 1,
                  fontSize: '12px',
                  cursor: 'pointer',
                  color: theme.palette.text.secondary,
                  textDecoration: 'underline',
                }}
              >
                {showExerciseDescription ? t('ExerciseCard10') : t('ExerciseCard09')}
              </Typography>
            )}
          </>
        )}

        {/* Concept. Show buttons for each exercise concept */}
        {showConcepts && (
          <Box display="flex" justifyContent="center" flexWrap="wrap" gap={0.5} marginTop={1}>
            {
              props.exercise.relationships.field_concepts.data.map((conceptRef: JSONAPITypeId) => (state.allConcepts.find(x => x.id === conceptRef.id))).map((concept: DrupalEntity, index: number) => (
                <Fragment key={index}>
                  <Tooltip
                    title={newConcept(concept) ? `${t('ExerciseCard07')} "${concept.attributes.title}" ${t('ExerciseCard08')}` : ''}
                    enterDelay={500}
                  >
                    <Button
                      variant='text'
                      sx={{
                        fontSize: { xs: 9, sm: 10 },
                        textTransform: 'none',
                        fontWeight: 'bold',
                        borderRadius: '20px',
                        cursor: 'pointer',
                        padding: '1px 4px',
                        color: theme.palette.text.primary,
                        border: '1px solid lightgray',
                      }}
                      onClick={() => {
                        setConceptFileID(concept.relationships.field_concept_video.data.id)
                        setConcept(concept.attributes.title)
                        log.info('DialogShowVideo() - ' + concept.attributes.title);
                      }}
                    >
                      {concept.attributes.title}
                    </Button>
                  </Tooltip>
                </Fragment>
              ))
            }
          </Box>
        )}

        {/* Create flexbox item that will take up as much space as possible. Thereby pressing the following flexbox item all the way down */}
        <Box height={1}></Box>

      </Paper>
    </Fragment >
  );
}
