import { ThemeProvider } from '@mui/material/styles'
import { useReducer, useEffect, Suspense, createContext, useState, Fragment } from "react";
import { Routes, Route, useLocation, useNavigate, Navigate, } from "react-router-dom";
import AccountCaptcha from "./components/AccountCaptcha";
import Coaches from "./components/Coaches";
import ExerciseCreate from "./components/ExerciseCreate";
import FormGetPracticeReport from "./components/FormGetPracticeReport";
import FrontPage from "./components/FrontPage";
import PDFViewer from "./components/PDFViewer";
import SetSubscription from "./components/SetSubscription";
import TermsAndConditions from "./components/TermsAndConditions";
import {
  STATEINIT, BACKEND, JSONAPIPATH, DEFAULT_GROUP_EXERCISE, NAKEDPAES, NATIVEAPPID,
  ROUTESWITHCARDSANDFORM,
} from "./misc/Constants";
import {
  fetchData, getActionSetConfirm, getAllContentEntities, getClub, getCookie, getDD, getFilterExercise,
  icbControllerGenerel02, inAppPaymentApple,
  logout, nakedPage, postSetSubscriptionMessage, reloadApp, rereadUser, testEnvironment
} from "./misc/Functions";
import { reducer } from "./misc/Reducer";
import {
  AccountAction, ActionResizeOrRotate, ActionSetConfiguration,
  ActionSortGroups, OperationMode, TypeState, UserType,
} from "./misc/Types";
import * as locales from './misc/LocalesSupported'; // supported languages
import ReactGA from "react-ga4";
import log from "./misc/Logger";
import Home from "./components/Home";
import userflow from "userflow.js";
import DialogConfirm from "./components/DialogConfirm";
import SendClubMemberInviration from "./components/SendClubMemberInvitation";
import MyPractices from "./components/MyPractices";
import MyTeams from "./components/MyTeams";
import ICBAppBar from "./components/ICBAppBar";
import { Box, CssBaseline } from "@mui/material";
import OpenPracticeProgramButton from "./components/OpenPracticeProgramButton";
import DrawerLandscape from "./components/DrawerLandscape";
import Profile from "./components/Profile";
import AppiOSTwoWayCommunication from "./components/AppiOSTwoWayCommunication";
import { useTranslation } from 'react-i18next';
import ClubDocuments from "./components/ClubDocuments";
import { useThemeContext } from "./theme/ThemeContextProvider";
import Groups from "./components/Groups";
import InstallAndroid from "./components/InstallAndroid";
import ICBBackdrop from "./components/ICBBackdrop";
import prefix from 'loglevel-plugin-prefix'; // logging
import MyContacts from "./components/MyContacts";
import Concepts from "./components/Concepts";
import MyPlayers from "./components/MyPlayers";
import AttendanceReport from "./components/AttendanceReport";
import './components/i18n';
import MyPlaybooks from './components/MyPlaybooks';
import CreatePlay from './components/CreatePlay';
import ButtomAppBar from './components/ButtomAppBar';
import IntroApp from './components/IntroApp';
import { isbot } from "isbot";
import PreplannedPractices from './components/PreplannedPractices';
// import { registerSW } from 'virtual:pwa-register';

// const updateSW = registerSW({
//   onNeedRefresh() {
//     // Show a prompt to the user to refresh
//     const shouldRefresh = confirm('New content is available. Refresh now?');
//     if (shouldRefresh) {
//       window.location.reload();
//     }
//   },
//   onOfflineReady() {
//     // Inform the user they can use the app offline
//     console.log('App is ready to work offline');
//   },
// });

export const Context = createContext({});

// Build initial state
function getInitialState(state: TypeState) {
  log.debug('getInitialState()');
  // Get language from cookie. Test valid language in cookie. Otherwise, use default language
  let user = STATEINIT.user;
  if (Object.keys(locales).includes(getCookie('icoachbasketball'))) {
    user.locale = getCookie('icoachbasketball');
  }
  const params = new URLSearchParams(location.search);

  // find operation mode
  let operationMode = OperationMode.exercise // assume operation mode is exercise
  if (location.pathname === '/icbplays')
    operationMode = OperationMode.play
  else if (location.pathname === '/icbpreplannedpractices')
    operationMode = OperationMode.preplannedPractice

  const returnState: TypeState = {
    ...state,
    user: user,
    portrait: window.innerWidth < window.innerHeight,
    nativeApp: (params.has("s") && params.get("s") === NATIVEAPPID),
    fetchControllerSignal: state.fetchController.signal,
    operationMode: operationMode
  }
  return returnState
}

export default function App() {
  log.debug('App');

  // Init global state
  const [state, dispatch] = useReducer(reducer, STATEINIT, getInitialState);
  const [leftMargin, setLeftMargin] = useState(0)
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const { mode, toggleColorMode } = useThemeContext();
  const [club, setClub] = useState<string | undefined>()
  ReactGA.initialize("G-284DR5VTDB");

  const location = useLocation()

  // // remove outer most scrollbar - can we always have it like that?
  // useEffect(() => {
  //   // Remove outer scrollbar
  //   document.body.style.overflow = 'hidden';

  //   // Cleanup on unmount
  //   return () => {
  //     document.body.style.overflow = '';
  //   };
  // }, []);

  // Scroll to top of page when route changes
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname, state.selectedGroup, state.selectedExercisePool])

  // log route change and optionally adjust left margin
  useEffect(() => {
    setLeftMargin(!state.portrait && !nakedPage() ? 57 : 0)
    log.info(`${location.pathname}/${state.selectedExercisePool}/${state.selectedGroup?.attributes?.title}`); // ? after selectedGroup for the case where we searched for the first group of type 'play' and we did not find ant
  }, [location, state.selectedExercisePool, state.selectedGroup]);

  // Catch a number of browser events
  useEffect(() => {
    log.debug('useEffect: handleResize')
    const handleResize = () => {
      let portrait = window.innerWidth < window.innerHeight
      setLeftMargin(!portrait && !nakedPage() ? 57 : 0)
      let action: ActionResizeOrRotate = {
        type: 'resizeOrRotate',
        portrait: window.innerWidth < window.innerHeight,
      }
      dispatch(action);
    };
    window.addEventListener('resize', handleResize);
    // Set correct mode, portrait or landscape, on first show of component
    handleResize();

    const handleError = (event: ErrorEvent) => {
      // there are a number of cases where we don't want to log stack trace and get email notification - see list below
      if (isbot(navigator.userAgent)) {
        log.info('isbot, handleError - dont log stacktrace')
        return
      }
      if (event.message === "ResizeObserver loop completed with undelivered notifications.") {
        log.info(event.message)
        return
      }
      const errorDetails = event.error
      const errorTextToBackEnd = `ICB Error Event Listener:
        ICB version: ${__APP_VERSION__} 
        message: ${event.message} 
        filename: ${event.filename} 
        lineno: ${event.lineno} 
        colno: ${event.colno} 
        error: ${event.error}
        stack: ${errorDetails ? errorDetails.stack : 'no stack'}`;
      log.info(errorTextToBackEnd)
      if (event.error.stack && event.filename)
        getDD(state, dispatch, `${BACKEND}/icb-stacktrace/stacktrace`, 'POST', event.error.stack)
      else
        log.info('No stack or no file:', event.message) // no reason to try parse stack trace as stack trace or file is not defined
    }
    window.addEventListener("error", handleError);

    const handleUnhandledrejection = (event: PromiseRejectionEvent) => {
      if (isbot(navigator.userAgent)) {
        log.info('isbot, handleUnhandledrejection - dont log stacktrace')
        return
      }
      const errorTextToBackEnd = `ICB PromiseRejectionEvent Event Listener:
        ICB version: ${__APP_VERSION__}
        message: ${event.reason.message}
        stack: ${event.reason.stack}`
      log.info(errorTextToBackEnd)
      if (event.reason.stack)
        getDD(state, dispatch, `${BACKEND}/icb-stacktrace/stacktrace`, 'POST', event.reason.stack)
      else
        log.info('No stack or no file:', event.reason.message) // no reason to try parse stack trace as stack trace or file is not defined
    }
    window.addEventListener("unhandledrejection", handleUnhandledrejection)

    onStartApp();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleUnhandledrejection);
    };
  }, []);

  // House keeping when we know the current user
  useEffect(() => {
    // Update club in app bar if user changes his profile/club name
    setClub(getClub(state))
    // Debug current user?
    if (state.configuration.length > 0
      && state.configuration[0].relationships.field_users_to_debug.data.find((x: any) => x.id === state.user.data.id)) {
      log.info(`app, log.setLevel(${state.configuration[0].attributes.field_log_level})`);
      log.setLevel(state.configuration[0].attributes.field_log_level || "info");
    }
  }, [state.user.data])

  // When initial data retrieval of users and groups has been completed then sort
  // groups and get exercises for all groups except standard default group. We already
  // have data for standard default group
  useEffect(() => {
    log.debug('app, useEffect, state.gotUsersGroupsExercises: ', state.gotUsersGroupsExercises)
    if (state.gotUsersGroupsExercises === 3) {
      log.info(`start of app, one time execution of code, ${__APP_VERSION__}`);

      // Don't execute this code block again
      dispatch({ type: 'setGotUsersGroupsExercises' })

      setClub(getClub(state))

      if (state.nativeApp && (window as any).webkit) {
        /*
        This app is up and running and eventhandler that receives messages from native app
        has been set up.
        Send message to native app and native app will return with the subscription status of the native app logged in user
        */
        inAppPaymentApple(state, dispatch, "0")
      }

      // let actionSetOneTimeExecuted: ActionSetOneTimeExecuted = { type: 'setOneTimeExecuted', done: true };
      // dispatch(actionSetOneTimeExecuted);

      // Find exercises for all exercise groups except the standard default exercise group
      // Exercises in the standard default exercise group has been retrieved
      let exerciseFetches: Promise<void>[] = []; // handle await in loop. See https://eslint.org/docs/latest/rules/no-await-in-loop
      for (let group of state.allGroups.filter(x => x.id !== DEFAULT_GROUP_EXERCISE && x.attributes.field_group_type === OperationMode.exercise)) {
        exerciseFetches.push(getAllContentEntities(state, dispatch, 'node--exercise', getFilterExercise(state, group), false))
      }
      Promise.all(exerciseFetches)

      let action: ActionSortGroups = { type: 'sortGroups' };
      dispatch(action);

      // Integrate with userflow. See https://userflow.com/
      const userFlowID = testEnvironment() ? 'ct_xnocpymr4jf2jgksmezpkvughu' : 'ct_bybp7lkpyvecnbzefrug7tuxxe'
      userflow.init(userFlowID)
      userflow.identify(state.user.login.current_user.name, {
        // name: state.user.login.current_user.name,
        // email: 'tps@netmaster.dk',
        signed_up_at: new Date().toISOString(),
      })

      // Now that the user has been read we can set the default theme
      if (state.user.data.attributes.field_theme_dark_mode && mode === 'light')
        toggleColorMode()
      if (!state.user.data.attributes.field_theme_dark_mode && mode === 'dark')
        toggleColorMode()
    }
  }, [state.gotUsersGroupsExercises])

  // Handle misc. other 'commands' from the native app!. Appstore users sets his/her subscription
  useEffect(() => {
    // only relevant for native apps
    if (!state.nativeApp)
      return

    // Reduce log. If we get jwsRepresentation from native app then we just want to show part of the encode value
    const jwsRepresentationFromNativeApp = state.fromApp.length > 8 && state.fromApp.substring(0, 9) === 'cmdDecode'

    log.info(`info from native app: ${jwsRepresentationFromNativeApp ? state.fromApp.substring(0, 20) + '...' : state.fromApp}`);

    // Handle commands from native app
    if (jwsRepresentationFromNativeApp) {
      // user has successfully paid in native app and we got the jwsRepresentation
      // call backend to decode jwsRepresentation and store app store originaltransactionid for current user
      icbControllerGenerel02(state, {
        "opr": "set_app_store_originaltransactionid",
        "encoded_value": state.fromApp.substring(9).trim(),
        user_msg: t('AlertMsg35')
      })
        .then((resp) => {
          if (!resp.ok) {
            dispatch(getActionSetConfirm(resp.error))
            return
          }
          // show success update and fetch user to update state now that user has been updated to pro
          dispatch(getActionSetConfirm(t('AlertMsg08')));
          rereadUser(state, dispatch, state.user.data.id!);
        })
      // const opr = { "opr": "set_app_store_originaltransactionid", "encoded_value": state.fromApp.substring(9).trim(), user_msg: t('AlertMsg35') }
      // icbControllerGenerel(state, dispatch, opr,
      //   () => {
      //     // show success update and fetch user to update state not that user has been updated to pro
      //     dispatch(getActionSetConfirm(t('AlertMsg08')));
      //     rereadUser(state, dispatch, state.user.data.id!);
      //   }
      // )
    }
  }, [state.fromApp])

  // Read current user and reload app if user has paying subscription
  function callBackAwaitPaymentProcessing(stateLocal: TypeState) {
    // Get current user, if set
    if (stateLocal.loggedIn === 1) {
      getDD(stateLocal, dispatch, `${BACKEND}/${JSONAPIPATH}/user/user?filter[uid]=${stateLocal.user.login.current_user.uid}`)
        .then((user) => {
          log.info(`Wait for back end to register paying user type and customer number. user type: ${user.data[0].attributes.field_user_type}. customer number: ${user.data[0].attributes.field_customer_number}`)
          // Check if current user is paying user. Remember it takes both a pro/clubadmin user type AND
          // a Stripe customerID. Promotion users have pro/clubadmin user type but no Stripe customerID
          // because Gabri moved the valid until out in the future so the customer could upgrade to
          // pro/clubadmin user type without paying through Stripe Checkout
          if ([UserType.clubadmin, UserType.pro].includes(user.data[0].attributes.field_user_type)
            && user.data[0].attributes.field_customer_number) {
            // Yes, current user is paying user. Reload app to get data relevant for new user type
            reloadApp(stateLocal.nativeApp, `?show_upgrade_msg=${user.data[0].attributes.field_user_type}`)
          }
        })
    }
  }

  // Run when app starts
  async function onStartApp() {
    log.info(`onStartApp(), ${__APP_VERSION__} ${location.pathname}${location.search}`);

    // // extra logging for current user?
    // if (location.pathname === "/debug")
    //   log.setLevel("debug")
    // else if (location.pathname === "/trace")
    //   log.setLevel("trace")

    // Get search parameters on quesry string
    const params = new URLSearchParams(location.search);

    // Get configuration
    const configuration = await getDD(state, dispatch, `${BACKEND}/${JSONAPIPATH}/node/configuration`);
    if (!configuration) {
      return
    }

    // Check session cookie. Is user already logged in?
    const login_status = await getDD(state, dispatch, `${BACKEND}/user/login_status?_format=json`);

    // Maintenance mode?
    if (!login_status) {
      return
    }

    if (login_status === "0") {
      // not logged in, no longer show IntroApp/Splash Screen
      dispatch({ type: 'setLoggedIn', userType: 0 })

      // use the configuration we just read
      let action: ActionSetConfiguration = { type: 'setConfiguration', configuration: configuration.data }
      dispatch(action);

      // allow only a couple of routes when not logged in
      if (!['/', '/installandroid', '/termsandconditions'].includes(location.pathname.toLowerCase()))
        navigate('/')
    } else {
      // logged in. Go to Home unless user is refreshing and already on another route
      if (location.pathname === "/")
        navigate('/home')

      // Logout if version mismatch. Needed for iOS app operations.
      // In app goto login if version mismatch
      // Jan 13, 2024 I see in the log that configuration.data[0] - Cannot read properties of undefined (reading '0')
      // This msg was after a "Please check your internet connection and try again" msg to the user and the user!!
      // I don't want mails on these errors but for now I do nothing!
      if (configuration.data[0].attributes.field_app_version !== __APP_VERSION__) {
        logout(state, dispatch, navigate)
        return
      }

      // Get CSRF token and current user
      const csrf_token = await getDD(state, dispatch, `${BACKEND}/session/token`);
      if (!csrf_token)
        return; // Error
      const get_user = await icbControllerGenerel02(state, { "opr": "get_user" })
      if (!get_user)
        return; // Error

      // Change log prefix now we know who is logged in
      prefix.apply(log, { template: `${get_user.user.current_user.name} (${get_user.user.current_user.uid})` })

      // Convert uid from string to int to enable compare with JSONAPI data
      get_user.user.current_user.uid = parseInt(get_user.user.current_user.uid)

      // Build local state with user info and CSRF token. Use local state in the following calls
      const stateLocal: TypeState = {
        ...state,
        user: {
          ...state.user,
          login: {
            ...get_user.user,
            csrf_token: csrf_token,
          }
        },
        loggedIn: 1,
        configuration: configuration.data,
      }
      dispatch({ type: 'setState', state: stateLocal });
      dispatch({ type: 'setLogin', login: stateLocal.user.login }); // just to get local storage

      /* 
      If we came back from successfull Stripe checkout session then we have new paying user type as query parameter. 
      If subscription for new paying user type has been paid then it will be reflected in the users profile in Drupal. 
      User is updated in Stripe webhook invoice.paid. 
      If the query parameter for new user type equals the user type in the users profile in Drupal
      then we show successfull set new subscription message. 
      Otherwise, we show error message.
      */
      if (params.get("new_user_type")) {
        // we came back from Stripe Checkout
        // now, singnal that we want to show IntroApp
        dispatch({ type: 'setAwaitPaymentProcessing', await: true })
        // keep reading current user. If user is paying user then reload app with information to show upgrade message on startup
        const intervalID = setInterval(callBackAwaitPaymentProcessing, 1000, stateLocal);
        // stop reading current user after 60s and show error message that payment was unsuccessfull - this will ofcourse happen if we reload
        setTimeout(() => {
          clearInterval(intervalID)
          dispatch({ type: 'setAwaitPaymentProcessing', await: false })
          let msg = `Processing of payment has expired. Check mail for rejected payment. Contact ICB if all fails!`
          log.info(msg)
          dispatch(getActionSetConfirm(msg));
        }, 60000)
      }

      /*
      If code above caused reload of app then query parameter show_upgrade_msg has
      been set with a value of new user type. Show upgrade msg based on user type
      */
      if (params.get("show_upgrade_msg")) {
        postSetSubscriptionMessage(stateLocal, dispatch, params.get("show_upgrade_msg"))
      }

      // Get data. Use updated local version of state so user is available in state we pass to fetchData()
      fetchData(stateLocal, dispatch);
    }
  }

  // Adjust for a new special cases regarding margin at top of content and below content
  const spacerBeforeLowBar = () => !(state.portrait && NAKEDPAES.includes(location.pathname.toLowerCase()))

  // find operation mode if user enters route by hand
  if (location.pathname === '/icbdrills' && state.operationMode !== OperationMode.exercise)
    dispatch({ type: 'setOperationMode', operationMode: OperationMode.exercise });
  else if (location.pathname === '/icbplays' && state.operationMode !== OperationMode.play)
    dispatch({ type: 'setOperationMode', operationMode: OperationMode.play });
  else if (location.pathname === '/icbpreplannedpractices' && state.operationMode !== OperationMode.preplannedPractice)
    dispatch({ type: 'setOperationMode', operationMode: OperationMode.preplannedPractice });

  return state.loggedIn === -1 || state.awaitPaymentProcessing ?
    (
      // If we don't have this block then at least iPhone users will be able run 
      // as anonymous user and is unexpectedly not shown the login dialog after 
      // new version is released
      <Fragment><IntroApp awaitPaymentProcessing={state.awaitPaymentProcessing} />TPS</Fragment>
    )
    : (
      <Context.Provider value={{ state, dispatch }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          {state.nativeApp && (window as any).webkit && <AppiOSTwoWayCommunication />}

          {!nakedPage() && !state.portrait && <DrawerLandscape />}

          <Box id="boxmarginleft" style={{ marginLeft: leftMargin }}>

            {!nakedPage() && <ICBAppBar club={club} />}

            {/* Include toolbar after appbar to scroll in box below toolbar so scrollbar does not go into appbar */}
            {/* <Toolbar /> */}

            {/* <Box id="appboxbelowappbar" height={`${state.appBarHeight}px !important`} /> */}

            {/* Control scrolling below appbar */}
            <Box
              id="appscollingbox"
              style={{
                overflowY: 'auto', // Enable scrolling
                height: '100vh',   // Full viewport height
                marginTop: state.appBarHeight
              }}>
              <Routes>
                {/* login */}
                {state.loggedIn === 0 && <Route path="/" element={<AccountCaptcha
                  mode={AccountAction.login}
                  showEmailVerificationCode={false}
                />} />}
                {/* login with field for email verification code open */}
                {state.loggedIn === 0 && <Route path="/emailverificationcode" element={<AccountCaptcha
                  mode={AccountAction.login}
                  showEmailVerificationCode={true}
                />} />}
                <Route path="/home" element={<Home />} />
                <Route path="/icbdrills" element={<Fragment><FrontPage /><OpenPracticeProgramButton /></Fragment>} />
                <Route path="/icbplays" element={<Fragment><FrontPage /><OpenPracticeProgramButton /></Fragment>} />
                <Route path="/icbpreplannedpractices" element={<Fragment><FrontPage /><OpenPracticeProgramButton /></Fragment>} />
                <Route path="/exercisecreate" element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <ExerciseCreate />
                  </Suspense>
                } />
                <Route path="/formgetpracticereport" element={<FormGetPracticeReport />} />
                <Route path="/practicereport" element={<PDFViewer />} />
                <Route path="/coaches" element={<Coaches />} />
                <Route path="/sendclubmemberinvitation" element={<SendClubMemberInviration />} />
                <Route path="/setsubscription" element={<SetSubscription />} />
                <Route path="/termsandconditions" element={<TermsAndConditions />} />
                <Route path="/practices" element={<MyPractices />} />
                <Route path="/myteams" element={<MyTeams />} />
                <Route path="/myplayers" element={<MyPlayers />} />
                <Route path="/mycontacts" element={<MyContacts />} />
                <Route path="/myplaybooks" element={<MyPlaybooks />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/clubdocuments" element={<ClubDocuments />} />
                <Route path="/groups" element={<Groups />} />
                <Route path="/concepts" element={<Concepts />} />
                <Route path="/attendancereport" element={<AttendanceReport />} />
                <Route path="/createplay" element={<CreatePlay />} />
                <Route path="/preplannedpractices" element={<PreplannedPractices />} />
                <Route path="/installandroid" element={<InstallAndroid />} />
                {/* default redirect to home page - see https://jasonwatmore.com/react-router-v6-catch-all-default-redirect-in-react*/}
                <Route path="*" element={<Navigate to="/home" />} />
              </Routes>
              {/* Add space to when we scroll all the way down we get some space between the lover toolbar and the last content */}
              {/* Special case if portrait and /print in which case we do not want to include SpacerBeforePortraitLowAppBar
            because it causes scroll */}

              {
                !nakedPage() && spacerBeforeLowBar() && !ROUTESWITHCARDSANDFORM.includes(location.pathname) && <Box height={150} />
              }
            </Box>
            {!nakedPage() && state.portrait && <ButtomAppBar />}

            {/* Helper components  */}
            <ICBBackdrop />
            <DialogConfirm />

          </Box>
        </ThemeProvider>
      </Context.Provider>
    );
}
