import { useContext, useEffect, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { ActionSetContentEntity, ActionSetPractice, CRUD, DrupalEntity, TypeContext } from "../misc/Types";
import { DataGrid, GridActionsCellItem, GridActionsColDef, GridColDef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridRowParams } from "@mui/x-data-grid";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { PRACTICEINIT } from "../misc/Constants";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { getActionSetConfirm, icbControllerGenerel02, nodeCRUD } from "../misc/Functions";
import AddBUttonAboveLists from "./AddButtonAboveList";
import { randomId } from "@mui/x-data-grid-generator";

export default function MyTeams() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('MyTeams');
    const theme = useTheme();

    // find all rows
    const allRows = () => state.allTeams
        .filter(x => x.relationships.uid.data.id === state.user.data.id)
        .map(x => {
            return {
                id: x.id,
                title: x.attributes.title,
            }
        })

    const [disabled, setDisabled] = useState(false)
    const [rows, setRows] = useState(allRows())

    // reset disabled after team has been delete
    useEffect(() => {
        setDisabled(false)
        setRows(allRows())
    }, [state.allTeams, state.user.data.id])

    // data columns
    const columns: (GridColDef | GridActionsColDef)[] = [
        { field: 'id', headerName: 'ID', width: 10 },
        { field: 'title', headerName: t('PracticeProgramForm22'), flex: 1, editable: true, },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    onClick={() => {
                        handleDelete(params.id.toString())
                    }}
                    label={t('ExerciseCreate09')}
                    showInMenu
                />,
            ]
        }
    ]

    // Delete team
    function handleDelete(id: string) {
        dispatch(getActionSetConfirm(t('MyTeams03'), '', () => {
            if (disabled)
                return
            setDisabled(true)
            // delete team and practices for team. Do this as back end job!
            icbControllerGenerel02(state, {
                opr: "delete_team",
                teamID: id
            })
                .then((resp) => {
                    if (resp.ok) {
                        // team and practices have been removed from the back end - remove from local lists
                        // remove practices from list of read practices
                        const actionSetContentEntityPractices: ActionSetContentEntity = {
                            type: 'setContentEntity',
                            contentType: 'node--practice',
                            data: state.allPractices.filter(x => x.relationships.field_team.data.id !== id),
                            initialLoad: true
                        }
                        dispatch(actionSetContentEntityPractices)
                        // remove team from list of read teams
                        const actionSetContentEntityTeams: ActionSetContentEntity = {
                            type: 'setContentEntity',
                            contentType: 'node--team',
                            data: state.allTeams.filter(x => x.id !== id),
                            initialLoad: true
                        }
                        dispatch(actionSetContentEntityTeams)
                        // init current practice if current practice belongs to team just deleted
                        if (state.curPractice.team && id === state.curPractice.team.id) {
                            const action: ActionSetPractice = { type: 'setPractice', practice: PRACTICEINIT }
                            dispatch(action)
                        }
                    }
                })
        }))
    }

    function handleAddRow() {
        // dont allow new row if we already new empty row
        if (rows.find(x => x.title === ''))
            return;
        setRows([...rows, { id: randomId(), title: '' }]);
    };

    // handle add or update team
    async function processRowUpdate(newRow: GridRowModel, oldRow: GridRowModel) {
        // check newRow.teamName is not in list
        if (state.allTeams.find(x => x.attributes.title === newRow.title)) {
            // team already exists
            dispatch(getActionSetConfirm(`${t('DialogGetSingleLineOfText05')}: ${newRow.title}`));
            return oldRow
        }

        if (disabled)
            return
        setDisabled(true)

        // create team to add or update
        let team: DrupalEntity = {
            type: 'node--team',
            attributes: {
                title: newRow.title.trim(),
            }
        }
        // add new team or update team?
        const update = state.allTeams.find(x => x.id === newRow.id)
        if (update)
            team = { ...team, id: newRow.id } // update
        nodeCRUD(state, dispatch, update ? CRUD.Update : CRUD.Create, team)
            .then((resp) => {
                if (!resp.data) {
                    log.error(resp);
                    dispatch(getActionSetConfirm(resp));
                }
            })

        return newRow
    }

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true
    }

    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                {t('MyTeams02')}
            </Typography>
            <Divider sx={{ marginBottom: 3 }} />

            {/* Button to add preplanned practice */}
            <AddBUttonAboveLists
                buttonText={t('Generel12')}
                addCode={() => { handleAddRow() }}
            />

            <DataGrid
                sx={{ marginTop: 3 }}
                columns={columns}
                rows={rows}
                columnVisibilityModel={{ id: false }}
                disableColumnMenu
                processRowUpdate={(newRow, oldRow) => processRowUpdate(newRow, oldRow)}
                onRowEditStop={handleRowEditStop}
                rowHeight={30}
            />
        </Box >
    )
}