import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Fragment, useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { ActionSetPractice, Attend, DrupalEntity, JSONAPITypeId, PracticeBetter, TypeContext } from "../misc/Types";
import { useNavigate } from "react-router-dom";
import { getActionSetConfirm, mapPractice, savePractice } from "../misc/Functions";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import DialogDownloadSendBoth from "./DialogDownloadSendBoth";
import TeamDropdown from "./TeamDropdown";
import { DRUPALENTITYINIT, PRACTICEINIT } from "../misc/Constants";
import SnackbarMessages from "./SnackbarMessages";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

interface TypeFormData {
    team: DrupalEntity,
    date: string,
    note: string,
    dirty: boolean,
}

const commonStyles = {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 'bold',

    height: '40px', // Set your desired height here
    '& .MuiInputBase-root': {
        height: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        fontFamily: '"PT Sans", sans-serif',
        color: 'grey', // Text color
        '& .MuiOutlinedInput-notchedOutline': {
            // borderColor: 'grey', // Border color
            borderWidth: '1px', // Normal state border thickness
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'grey', // Border color on hover
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            // borderColor: 'grey', // Border color when focused
            borderWidth: '2px', // Focused state border thickness
        },
        '& .MuiSvgIcon-root': {
            // color: 'grey', // Icon color
        },
    },
    '& .MuiInputLabel-root': {
        fontFamily: '"PT Sans", sans-serif',
        // fontWeight: 'bold',
        // color: 'grey', // Consistent label color
    },
};

// Show form with practice header edit boxes and options to save, clear etc current practice
export default function PracticeProgramForm() {
    // Get state and dispatcher
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const navigate = useNavigate();
    log.debug('PracticeProgramForm');
    const theme = useTheme();
    const [openSnackbarMessage, setOpenSnackbarMessage] = useState(false)
    // const [disabled, setDisabled] = useState(false)

    const buttonStyle = {
        textTransform: 'none',
        fontSize: '0.8rem',
        height: '25px',
        color: theme.palette.text.primary,
        border: '1px solid ' + theme.palette.divider,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: theme.palette.action.selected,
        }
    }

    const formDataInit: TypeFormData = {
        team: state.allTeams.find(x => x.relationships.uid.data.id === state.user.data.id) || { ...DRUPALENTITYINIT, id: '' },
        date: dateToDateStringInTextFieldOfType_datetime_local(new Date(new Date().setSeconds(0, 0))),
        note: '',
        dirty: false,
    }

    // State of data in form
    const [formData, setFormData] = useState<TypeFormData>(formDataInit)
    const [openDialogDownloadSendBoth, setOpenDialogDownloadSendBoth] = useState(false)
    // const formDataRef = useRef(formData);

    // // Update the ref whenever count changes
    // useEffect(() => {
    //     formDataRef.current = formData;
    // }, [formData]);

    // On refresh make sure latest state.allTeams is used
    useEffect(() => {
        // show practice from global state if practice in global state is dirty or there is a team. We have a team in global state practice if we edit an existing practice
        // remember that clubadmin can have a team in global practice that is not his own team - that team should be disregarded here!
        if (state.curPractice.dirty || (state.curPractice.team && state.curPractice.team.id))
            setFormData({
                team: state.curPractice.team && state.curPractice.team.id && state.curPractice.team.relationships.uid.data.id === state.user.data.id ? state.curPractice.team : { ...DRUPALENTITYINIT, id: ''},
                date: dateToDateStringInTextFieldOfType_datetime_local(state.curPractice.date),
                note: state.curPractice.note,
                dirty: true,
            })
        else {
            setFormData(formDataInit)
            // global state.curPractice must be in sync with what is shown on form so we have to call setPractice
            // if we don't do this then we might end up with a case where we have team selected in form and not in
            // state.curPractice and team value in state.curPractice is never updated before browswer local storage 
            // is saved
            // const action: ActionSetPractice = { type: 'setPractice', practice: {...PRACTICEINIT, team: formDataInit.team}}
            // dispatch(action)
        }

        // // Cleanup function to put local state data into global state
        // return () => {
        //     if (formDataRef.current.dirty) {
        //         // update global data/state
        //         const actionSetPractice: ActionSetPractice = {
        //             type: 'setPractice',
        //             practice: {
        //                 ...state.curPractice,
        //                 ...formDataRef.current,
        //                 uid: state.user.data.id!,
        //             }
        //         }
        //         dispatch(actionSetPractice)
        //     }
        // };
    }, [state.allTeams])

    function handleChange(name: string, value: any) {
        // Prevent date from being cleared. If clear dates for exercises i NaN and we dont want that
        if (name === 'date' && value === '')
            return
        setFormData({ ...formData, [name]: value, dirty: true })
        // copy change in local state to global state
        const action: ActionSetPractice = {
            type: 'setPractice',
            practice: {
                ...state.curPractice,
                team: formData.team, // needed in case team drop down is never selected. Otherwise, we dont get the show team to global state
                [name]: value,
                date: new Date(formData.date), // needed because formData.date is a string in datetime-local format and practice needs a date object
                dirty: true
            }
        }
        dispatch(action)
    }

    // if possible, find practice with same team on the same date and time
    function dublicatePractice(): PracticeBetter | undefined {
        return state.allPractices
            .map(x => mapPractice(state, x))
            .find(x => x.date.toISOString() === new Date(formData.date).toISOString()
                && x.team.id === formData.team.id)
    }

    // Check and confirm before save practice. If user confirms then 'afterSave' is execute if 'afterSave' has a value
    function checkPractice(): boolean {
        let res = false;
        let exerciseDiscontinued = state.curPractice.selectedExercises.find(item => item.exercise.attributes.field_discontinued);//selectedExercises.find(item => item.exercise.attributes.field_discontinued);
        if (!state.curPractice.dirty) {
            dispatch(getActionSetConfirm(t('PracticeProgramForm25')));
        } else if (exerciseDiscontinued) {
            // practice holds discontinued exercises
            dispatch(getActionSetConfirm(`${t('PracticeProgramForm13')} ${exerciseDiscontinued.exercise.attributes.title} ${t('PracticeProgramForm14')}`));
        } else if (state.user.login.current_user.uid === 0) {
            // Anonymous user tries to save a practice
            dispatch(getActionSetConfirm(t('Generel00'), t('AccountCaptcha08'), () => navigate('/signup')));
        } else if (state.curPractice.selectedExercises.length === 0) {
            // Save when no exercises - makes no sense!
            dispatch(getActionSetConfirm(t('PracticeProgramForm12')));
        } else if (!formData.team.id) {
            // Team must be selected
            dispatch(getActionSetConfirm(t('PracticeProgramForm30')));
        } else if (state.curPractice.selectedExercises.find(item => !item.exercise.attributes.drupal_internal__nid)) {//selectedExercises.find(item => !item.exercise.attributes.drupal_internal__nid)) {
            // test user has access to all exercises in practice. If user
            // has retrived an previously saved pratice and that practice holds
            // one or more exercises the user no longer has access to then the
            // user should no be allowed to save a new practice holding exercises
            // he/she no longer has access to
            // alert(t('PracticeProgramForm15'));
            dispatch(getActionSetConfirm(t('PracticeProgramForm15')));
        } else if (state.user.data.attributes.field_user_type === 'free'
            && !dublicatePractice()
            && state.allPractices.filter(x => x.relationships.uid.data.id === state.user.data.id).length >= state.configuration[0].attributes.field_max_practices_per_user) {
            // free user is NOT allowed to save more practices
            dispatch(getActionSetConfirm(t('PracticeProgramForm24'), t('ICBAppBar04'), () => navigate('/setsubscription')));
        } else if (formData.date === '') {
            dispatch(getActionSetConfirm(t('specify practice date')));
        } else {
            res = true;
        }
        return res;
    }

    // Check and give option to save/update the modified practice program. 
    // After save/update of practice program the user is given option to see/download/send practice program
    function savePracticeProgram() {
        if (checkPractice()) {
            const dublicate = dublicatePractice()
            dispatch(getActionSetConfirm(`${dublicate ? t('PracticeProgramForm35') : t('PracticeProgramForm33')}`, 'ok', async () => {
                /*
                If we overwrite we have a player list equal to the player list of the practice we overwrite.
                Otherwise, we have a player list based on how the team looks now
                */
                const attendanceNewPractice: Attend[] = formData.team.relationships.field_players.data.map((item: JSONAPITypeId) => ({ playerID: item.id, present: true }))
                const resp = await savePractice(state, dispatch, {
                    date: new Date(formData.date),
                    note: formData.note,
                    selectedExercises: state.curPractice.selectedExercises,
                    attendance: dublicate ? dublicate.attendance : attendanceNewPractice,
                    practiceID: dublicate ? dublicate.practiceID : '',
                    team: formData.team,
                    uid: state.user.data.id!,
                })
                if (resp) {
                    // error saving practice
                    log.error(resp);
                    dispatch(getActionSetConfirm(resp));
                } else {
                    setOpenDialogDownloadSendBoth(true)
                    // Activate Snackbar for 2 seconds. Autohide in parent would be inconsistent when double clicking fast
                    setOpenSnackbarMessage(true); setTimeout(() => { setOpenSnackbarMessage(false); }, 2000)
                }
            }));
        }
    }


    function dateToDateStringInTextFieldOfType_datetime_local(d: Date) {
        return d.getFullYear()
            + '-' + ('0' + (d.getMonth() + 1).toString()).slice(-2)
            + '-' + ('0' + d.getDate()).slice(-2)
            + 'T'
            + ('0' + d.getHours()).slice(-2)
            + ':' + ('0' + d.getMinutes()).slice(-2)
    }

    return (
        <Fragment>
            {/* {!state.practiceID ? "NEW " : "OLD "} */}
            {/* {state.practiceProgramDirty ? "DIRTY" : "CLEAN"} */}
            <Grid2 xs={12} padding={1} >
                {/* <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={state.user.locale.substring(0, 2)}> */}
                {/* Do not allow an initial undef value as controls would go from uncontrolled to controlled */}

                <Box sx={{ backgroundColor: '#fff3e0', padding: 0.5, borderRadius: 1, border: '1px solid ' + theme.palette.divider, }}>
                    <Typography textAlign={'center'} sx={{ fontSize: { xs: 20, sm: 23 }, fontWeight: 'bold', color: '#DD6F20' }}>
                        {t('PracticeProgramSelectedExercises00')}
                    </Typography>
                </Box>

                <Grid2 container spacing={1} paddingTop={1}>

                    {/* Select team */}
                    <Grid2 xs={6}>
                        {
                            <TeamDropdown
                                handleChange={(value) => { handleChange('team', value) }}
                                selectedTeam={formData.team}
                                firstElement={t('PracticeProgramForm27')}
                                filterOnMyTeams={true}
                            />
                        }
                    </Grid2>

                    {/* Practice Date */}
                    <Grid2 xs={6}>
                        {
                            // <DateTimePicker
                            //     label={t('PracticeProgramForm17')}
                            //     value={dayjs(new Date(formData.date))}
                            //     // onChange={(date: Dayjs | null) => handleChange('date', date ? date.unix() * 1000 : 0)}
                            //     onChange={(date: Dayjs | null) => handleChange('date', date?.toDate())}
                            //     sx={{ ...commonStyles, width: '100%' }}
                            // />
                            <TextField
                                label={t('PracticeProgramForm17')}
                                type="datetime-local"
                                // value={formData.date.toISOString().slice(0,16)}
                                value={formData.date}
                                onChange={(e) => handleChange('date', e.target.value)}
                                sx={{ ...commonStyles, width: '100%' }}
                            />
                        }
                    </Grid2>

                    {/* Practice Note */}
                    <Grid2 xs={12}>
                        <TextField
                            variant="outlined"
                            label={t('PracticeProgramForm29')}
                            size="small"
                            value={formData.note}
                            onChange={(event) => handleChange('note', event.target.value)}
                            fullWidth
                            sx={{ ...commonStyles }}
                        />
                    </Grid2>
                </Grid2>
                {/* </LocalizationProvider> */}
            </Grid2>

            {/* Show all buttons below input fields */}
            <Grid2 xs={12} display={'flex'} justifyContent={'space-evenly'}>

                {/* Save practice program */}
                <Button
                    variant="text"
                    aria-label={`practiceProgramForm_${t('PracticeProgramForm37')}`}
                    size="small"
                    onClick={savePracticeProgram}
                    sx={buttonStyle}
                >
                    {t('PracticeProgramForm37')}
                </Button>

                {/* Clear practice program */}
                <Button
                    variant="text"
                    aria-label={`practiceProgramForm_${t('PracticeProgramForm04')}`}
                    size="small"
                    onClick={() => {
                        dispatch(getActionSetConfirm(t('PracticeProgramForm06'), 'ok', () => {
                            const action: ActionSetPractice = { type: 'setPractice', practice: PRACTICEINIT }
                            dispatch(action)
                            setFormData({ ...formDataInit })
                        }))
                    }}
                    sx={buttonStyle}
                >
                    {t('PracticeProgramForm04')}
                </Button>

            </Grid2>

            <DialogDownloadSendBoth
                open={openDialogDownloadSendBoth}
                onClose={() => setOpenDialogDownloadSendBoth(false)}
            />

            <SnackbarMessages
                message={t('PracticeProgramForm36')}
                open={openSnackbarMessage}
                icon={<CheckCircleRoundedIcon fontSize="small" sx={{ color: '#2e7d36' }} />}
                color={'#2e7d36'}
            />

        </Fragment >
    );
}
