import { useContext, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { ExerciseSelected, TypeContext } from "../misc/Types";
import { Context } from "../App";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { setUISetting, getFileClubLogo, getImageURL, zeroPad } from "../misc/Functions";
import Grid2 from "@mui/material/Unstable_Grid2";
import ExerciseCardPrint from "./ExerciseCardPrint";
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import DialogAttendanceListForPractice from "./DialogAttendanceListForPractice";
import { DRUPALENTITYINIT } from "../misc/Constants";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
interface PropsPracticeProgramPrint {
    open: boolean,
    onClose: () => void,
}

export default function DialogPracticeProgramPrint(props: PropsPracticeProgramPrint) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('DialogPracticeProgramPrint');
    const theme = useTheme();

    // const [disabled, setDisabled] = useState(false)

    // const [showDialogPracticeProgramPrintDescription, setShowDialogPracticeProgramPrintDescription] = useState(false)
    // const [showDialogPracticeProgramPrintNote, setShowDialogPracticeProgramPrintNote] = useState(false)
    // const [showDialogPracticeProgramPrintTeachingPoints, setShowDialogPracticeProgramPrintTeachingPoints] = useState(false)
    // const [field_show_description_in_portra, setField_show_description_in_portra] = useState(false);
    const [openDialogAttendanceListForPractice, setOpenDialogAttendanceListForPractice] = useState(false)

    // // field_show_description_in_portra has been flopped. Reenable button
    // useEffect(() => {
    //     const show = state.uiSettings.showDescriptionInPortrait
    //     setField_show_description_in_portra(show === undefined || show)
    //     // setDisabled(false)
    // }, [state.user.data])

    // // User has flopped switch to show or not show exercise description
    // async function handleChange_field_show_description_in_portra() {
    //     // setDisabled(true)
    //     const showDescriptionInPortrait = !field_show_description_in_portra
    //     setField_show_description_in_portra(showDescriptionInPortrait)
    //     await saveUser(state, dispatch, {
    //         attributes: {
    //             field_ui_settings: JSON.stringify({ ...state.uiSettings, showDescriptionInPortrait: showDescriptionInPortrait })
    //         }
    //     })
    // }

    // Show exercises in practice
    const exercises = () => {
        let endDateTime = new Date(state.curPractice.date)
        return state.curPractice.selectedExercises
            .map((exerciseSelected: ExerciseSelected, index: number) => {
                let startDateTime = new Date(endDateTime);
                endDateTime.setTime(startDateTime.getTime() + 60 * 1000 * (exerciseSelected.durationMinutes || 0));
                // we store drupal_internal__id - now we get the exercise
                exerciseSelected.exercise = state.allExercises.find(x => x.attributes.drupal_internal__nid === exerciseSelected.drupal_internal__nid) || DRUPALENTITYINIT
                return (
                    <Grid2 xs={state.portrait ? 12 : 6} key={index}>
                        <ExerciseCardPrint
                            exerciseSelected={exerciseSelected}
                            startAndStopTime={`${zeroPad(startDateTime.getHours(), 2)}:${zeroPad(startDateTime.getMinutes(), 2)}-${zeroPad(endDateTime.getHours(), 2)}:${zeroPad(endDateTime.getMinutes(), 2)}`}
                            index={index}
                            displayOnly={false}
                            sourceList={state.curPractice.selectedExercises}
                        />
                    </Grid2>
                );
            });
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullScreen={state.portrait}
            PaperProps={{
                style: {
                    borderRadius: state.portrait ? 0 : 16,
                    minWidth: state.portrait ? '' : '750px'
                },
            }}
        >

            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* team, date and practice note */}
                <Box>
                    {/* Team */}
                    <Typography fontWeight={'bold'}>
                        {state.curPractice.team?.attributes.title.toUpperCase()}
                    </Typography>
                    {/* Date */}
                    <Typography fontSize={'small'} sx={{ color: theme.palette.text.secondary }}>
                        {new Date(state.curPractice.date).toLocaleDateString() + ' - ' + new Date(state.curPractice.date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
                    </Typography>
                    {/* Practice note */}
                    <Typography fontSize={'small'} sx={{ color: theme.palette.text.secondary }}>
                        {state.curPractice.note}
                    </Typography>
                </Box>
                <img
                    style={{ height: 'auto', width: state.portrait ? '40px' : '50px', objectFit: 'scale-down' }}
                    src={getFileClubLogo(state) || getImageURL('logoICBBlue.webp')}
                    alt="Logo del Club"
                />
            </DialogTitle>

            <DialogContent>
                {/* Three button to flop show of certain content */}
                <Box display="flex" alignItems="center" gap={2}>
                    <Box display="flex" alignItems="center">
                        <IconButton
                            onClick={() => setUISetting(state, dispatch, 'showDialogPracticeProgramPrintDescription', !state.uiSettings.showDialogPracticeProgramPrintDescription)}
                            aria-label="toggle description visibility"
                        >
                            {state.uiSettings.showDialogPracticeProgramPrintDescription ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
                        </IconButton>
                        <Typography sx={{ marginLeft: 0.5, fontSize: { xs: 10, sm: 12 }, color: theme.palette.text.secondary }}>{t('ExerciseCardPrint00')}</Typography>
                    </Box>

                    <Box display="flex" alignItems="center">
                        <IconButton
                            onClick={() => setUISetting(state, dispatch, 'showDialogPracticeProgramPrintNote', !state.uiSettings.showDialogPracticeProgramPrintNote)}
                            aria-label="toggle note visibility"
                        >
                            {state.uiSettings.showDialogPracticeProgramPrintNote ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
                        </IconButton>
                        <Typography sx={{ marginLeft: 0.5, fontSize: { xs: 10, sm: 12 }, color: theme.palette.text.secondary }}>{t('ExerciseCardPrint01')}</Typography>
                    </Box>

                    <Box display="flex" alignItems="center">
                        <IconButton
                            onClick={() => setUISetting(state, dispatch, 'showDialogPracticeProgramPrintTeachingPoints', !state.uiSettings.showDialogPracticeProgramPrintTeachingPoints)}
                            aria-label="toggle teaching points visibility"
                        >
                            {state.uiSettings.showDialogPracticeProgramPrintTeachingPoints ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
                        </IconButton>
                        <Typography sx={{ marginLeft: 0.5, fontSize: { xs: 10, sm: 12 }, color: theme.palette.text.secondary }}>{t('ExerciseCardPrint02')}</Typography>
                    </Box>
                </Box>

                <Grid2 container spacing={2}> {/* Adding a spacing between grid items */}
                    {state.exercisesRetrivedInitially && exercises()}
                </Grid2>

            </DialogContent>

            <DialogActions sx={{ justifyContent: 'center', alignItems: 'center', position: 'relative', padding: 2, paddingBottom: state.nativeApp ? 4 : 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => props.onClose()}
                    sx={{ textTransform: 'none', height: '30px' }}
                >
                    {t('PracticeProgramPrint01')}
                </Button>

                <Button
                    variant='outlined'
                    color='success'
                    sx={{ position: 'absolute', right: 15, height: '30px' }}
                    onClick={() => setOpenDialogAttendanceListForPractice(true)}
                >
                    <PlaylistAddCheckCircleRoundedIcon />
                </Button>
                {/* 
                <IconButton sx={{ position: 'absolute', right: 15 }}
                    onClick={() => setOpenDialogAttendanceListForPractice(true)}
                    aria-label="close"
                >
                    <PlaylistAddCheckCircleRoundedIcon />
                </IconButton> */}
            </DialogActions>

            <DialogAttendanceListForPractice
                open={openDialogAttendanceListForPractice}
                onClose={() => setOpenDialogAttendanceListForPractice(false)}
            />

        </Dialog >
    )
}

