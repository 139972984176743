import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Context } from "../App";
import { DrupalEntity, TypeContext, TypeState, UserType } from "../misc/Types";
import { Box, Button, Divider, Grid, TextField, Typography, styled } from "@mui/material";
import { afterLogout, formatLanguage, getActionSetConfirm, getDD, icbControllerGenerel02, reloadApp, saveNodeWithFileRelations, validateEmail } from "../misc/Functions";
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { BACKEND, CDN, IMAGEMIMETYPES } from "../misc/Constants";
import { MuiTelInput } from "mui-tel-input";
import DeleteIcon from '@mui/icons-material/Delete';
import DialogGetSingleLineOfText from "./DialogGetSingleLineOfText";
import log from "../misc/Logger";
import { useTheme } from "@mui/material";
import LanguageICB from "./LanguageICB";
import NightModeToggle from "./NightModeToggle";
import { useCookies } from "react-cookie";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

// Structure to handle values when user changes password and/or email
interface PasswordChange {
    current: string,
    new1: string,
    new2: string,
    mail: string,
}

export default function Profile() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    log.debug(' Profile()');

    const [dirty, setDirty] = useState(false)
    const [dirtyPasswordChange, setDirtyPasswordChange] = useState(false)
    const [userData, setUserData] = useState<DrupalEntity>(state.user.data)
    const [passwordChange, setPasswordChange] = useState<PasswordChange>({ current: '', new1: '', new2: '', mail: '' })
    const [userPictureFile, setUserPictureFile] = useState<File | null>(null);
    const [userPictureURL, setUserPictureURL] = useState<string | null>()
    const [clubLogoFile, setClubLogoFile] = useState<File | null>(null);
    const [clubLogoURL, setClubLogoURL] = useState<string | null>()
    const [openDialogGetSingleLineOfText, setOpenDialogGetSingleLineOfText] = useState(false)

    const userPictureRef = useRef<HTMLImageElement>(null);
    const clubLogoRef = useRef<HTMLImageElement>(null);

    const [_cookies, setCookie] = useCookies(['icoachbasketball']);

    // Make sure we use defaults values on F5/refresh
    useEffect(() => {
        setUserData(state.user.data)
        setPasswordChange({ current: '', new1: '', new2: '', mail: state.user.data.attributes.mail || '' })
        let fileID = state.user.data.relationships.user_picture?.data?.id
        let file = fileID && state.allFiles.find(x => x.id === fileID)
        let url = file && `${CDN}${file.attributes.uri.url}.webp`
        setUserPictureURL(url)
        fileID = state.user.data.relationships.field_club_logo?.data?.id
        file = fileID && state.allFiles.find(x => x.id === fileID)
        url = file && `${CDN}${file.attributes.uri.url}.webp`
        setClubLogoURL(url)
    }, [state.user.data])

    // Changes in password or email fields
    const handleChangePassword = (value: string, field: string) => {
        setDirtyPasswordChange(true)
        setPasswordChange({ ...passwordChange, [field]: value })
    };

    // Changes in all other fields
    const handleChangeAttribute = (value: string, field: string) => {
        setDirty(true)
        setUserData({
            ...userData,
            attributes: {
                ...userData.attributes,
                [field]: value,
            }
        })
    };

    // Changes in related files/images
    const handleChangeRelationship = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        setDirty(true)
        if (event.target.files && event.target.files.length > 0) {
            if (['image/png', 'image/gif', 'image/jpg', 'image/jpeg',].includes(event.target.files[0].type)) {
                const url = URL.createObjectURL(event.target.files[0]);
                if (event.target.files[0].size > 8000000) {
                    dispatch(getActionSetConfirm(t('AlertMsg27')));
                } else if (field === 'user_picture') {
                    setUserPictureFile(event.target.files[0]);
                    setUserPictureURL(url);
                }
                else if (field === 'field_club_logo') {
                    setClubLogoFile(event.target.files[0]);
                    setClubLogoURL(url);
                }
            } else {
                dispatch(getActionSetConfirm(t('AlertMsg26')));
            }
        }
    };

    // create a new state variable with updated csrf_token
    function stateNewCsrf_token(state: TypeState, csrf_token: string): TypeState {
        return {
            ...state,
            user: {
                ...state.user,
                login: {
                    ...state.user.login,
                    csrf_token: csrf_token,
                }
            }
        };
    }

    // Save new password, email or user. One call to save password/email and antoher call to save other user info
    async function onSave() {
        // Unconditionally save new preferred langcode and cookie. TODO save only if needed and save in same request as other values, ie. field_first_name
        // If user has opened LanguageICB to select new langauge then state.user.locale has been updated. Nothing has been done to cookie or preferred language
        const resp = await icbControllerGenerel02(state, {
            opr: "set_preferred_language",
            preferred_langcode: formatLanguage(state.user.locale)
        })
        let aYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        setCookie('icoachbasketball', state.user.locale, { expires: aYearFromNow });
        if (!resp.ok) {
            dispatch(getActionSetConfirm(resp.error))
            return
        }
        // hold on to csrf so we can update it before save of 'normal' user data in case user changes password
        let respCsrf = state.user.login.csrf_token
        if (dirty) {
            // 'normal' attributes changed - build data for 'normal' attributes we save below
            const userDataLocal: DrupalEntity = {
                type: 'user--user',
                id: state.user.data.id,
                attributes: {
                    field_first_name: userData.attributes.field_first_name,
                    field_last_name: userData.attributes.field_last_name,
                    field_phone_number: userData.attributes.field_phone_number,
                    field_club_name: userData.attributes.field_club_name,
                },
                relationships: state.user.data.relationships
            }
            const resp = await saveNodeWithFileRelations(stateNewCsrf_token(state, respCsrf), dispatch, userDataLocal, [
                {
                    field: 'user_picture',
                    file: userPictureFile,
                    remove: state.user.data.relationships.user_picture.data && !userPictureURL,
                },
                {
                    field: 'field_club_logo',
                    file: clubLogoFile,
                    remove: state.user.data.relationships.field_club_logo.data && !clubLogoURL,
                }
            ])
            if (!resp.data) {
                dispatch(getActionSetConfirm(resp));
                return
            }
        }

        if (dirtyPasswordChange) {
            const respPwd = await icbControllerGenerel02(state, {
                ...passwordChange,
                email_subject: t("AccountCaptcha22"),
                email_body: t("AccountCaptcha23"),
                "opr": "set_password_and_email",
            })
            if (!respPwd.ok) {
                dispatch(getActionSetConfirm(respPwd.error))
                return
            }
            // if password changed and email verification is activated then user has been logged out by the back end
            if (passwordChange.mail && state.configuration[0].attributes.field_email_ver_code_active) {
                afterLogout(state, dispatch, navigate, '/emailverificationcode')
                // logoutNow(state, dispatch, navigate, '/emailverificationcode')
                return
            }

            respCsrf = await getDD(state, dispatch, `${BACKEND}/session/token`)
            if (!respCsrf) { // TO DO make getDD return something like respCsrf.ok - it looks more like code above!
                dispatch(getActionSetConfirm(respPwd.error))
                return
            }
            dispatch({ type: 'setState', state: stateNewCsrf_token(state, respCsrf) })
        }

        // Update of 'normal' attributes and/or password/email succeeded
        dispatch(getActionSetConfirm(t('Generel17')));
        navigate('/home')

        // reload data now with new language
        reloadApp(state.nativeApp, location.pathname)
    }

    // Add timestamp to url to break cache
    function cacheBreaker(url: string) {
        if (url === '' || url.startsWith('blob')) {
            return url;
        } else {
            // Use Math.trunc so image max reloads every 10 sec.
            return `${url}?q=${Math.trunc(new Date().getTime() / 10000)}`
        }
    }

    // Clear 'field' from Drupal Enttiry relationsships
    function clearRelationship(field: string) {
        setUserData({
            ...userData,
            relationships: {
                ...userData.relationships,
                [field]: {
                    data: null,
                }
            }
        })
    }

    // Close dialog to confirm cancel account
    function onCloseDialogGetSingleLineOfText(ok: boolean, name: string) {
        if (ok) {
            // User clicked OK to cancel account. Check user name entered correctly
            if (state.user.login.current_user.name === name) {
                dispatch(getActionSetConfirm(t('Profile08'), 'ok', async () => {
                    log.info('Aggree: ' + t('Profile08'));
                    const resp = await icbControllerGenerel02(state, { "opr": "remove_account" })
                    if (!resp.ok) {
                        dispatch(getActionSetConfirm(resp.error))
                        return
                    }
                    setOpenDialogGetSingleLineOfText(false)
                    afterLogout(state, dispatch, navigate)
                }));
            } else {
                dispatch(getActionSetConfirm(t('Profile07')));
            }
        } else {
            // User cancelled the dialog
            setOpenDialogGetSingleLineOfText(false)
        }
    }

    return (
        <Fragment>
            <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>

                <Box>
                    <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                        {t('Profile13')}
                    </Typography>
                    <Divider />

                    <Typography style={{ marginTop: 25, textTransform: 'none', fontSize: '18px', fontWeight: 'bold', color: theme.palette.primary.main }}>
                        {t('Profile17')}
                    </Typography>

                    {/* FIRST NAME */}
                    <Grid container spacing={1} mt={0}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                sx={{ width: '100%' }}
                                required
                                id="field_first_name"
                                label={t('AccountCaptcha02')}
                                value={userData.attributes.field_first_name || ''}
                                onChange={(e) => handleChangeAttribute(e.target.value, "field_first_name")}
                                variant="standard"
                                autoComplete="off"
                            />
                        </Grid>

                        {/* LAST NAME */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                sx={{ width: '100%' }}
                                required
                                id="field_last_name"
                                label={t('AccountCaptcha03')}
                                value={userData.attributes.field_last_name || ''}
                                onChange={(e) => handleChangeAttribute(e.target.value, "field_last_name")}
                                variant="standard"
                                autoComplete="off"
                            />
                        </Grid>
                    </Grid>

                    {/* e-mail */}
                    <Grid container spacing={2} mt={{ xs: 0, sm: 1 }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                sx={{ width: '100%' }}
                                required
                                id="mail"
                                label={t('AccountCaptcha01')}
                                value={passwordChange.mail || ''}
                                onChange={(e) => handleChangePassword(e.target.value, "mail")}
                                variant="standard"
                                autoComplete="off"
                            />
                        </Grid>

                        {/* Phone Number */}
                        <Grid item xs={12} sm={6}>
                            <MuiTelInput
                                sx={{ width: '100%' }}
                                name={'field_phone_number'}
                                id={'field_phone_number'}
                                label={t('AccountCaptcha13')}
                                value={userData.attributes.field_phone_number || ''}
                                onChange={(newValue: string) => handleChangeAttribute(newValue, 'field_phone_number')}
                                InputLabelProps={{ shrink: true }} // Makes the label shrink and place on top of the text field
                                variant="standard"
                            />
                        </Grid>
                    </Grid>

                </Box>

                <Box>
                    <Divider sx={{ marginTop: 4 }} />
                    <Typography style={{ marginTop: 25, textTransform: 'none', fontSize: '18px', fontWeight: 'bold', color: theme.palette.primary.main }}>
                        {t('Profile10')}
                    </Typography>

                    {/* Current password */}
                    <Grid >
                        <TextField
                            // required
                            sx={{ width: '100%' }}
                            id="current"
                            label={t('Profile11')}
                            value={passwordChange.current || ''}
                            onChange={(e) => handleChangePassword(e.target.value, "current")}
                            variant="standard"
                            autoComplete="off"
                            type="password"
                        />
                    </Grid>

                    {/* New password 1 */}
                    <Grid container spacing={2} mt={{ xs: 0, sm: 1 }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // required
                                id="new1"
                                label={t('Profile12')}
                                value={passwordChange.new1 || ''}
                                onChange={(e) => handleChangePassword(e.target.value, "new1")}
                                variant="standard"
                                autoComplete="off"
                                type="password"
                                sx={{ width: '100%' }}
                            />
                        </Grid>

                        {/* New password 2 */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // required
                                id="new2"
                                label={t('Profile18')}
                                value={passwordChange.new2 || ''}
                                onChange={(e) => handleChangePassword(e.target.value, "new2")}
                                variant="standard"
                                autoComplete="off"
                                type="password"
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Divider sx={{ marginTop: 4 }} />

                <Typography style={{ marginTop: 25, textTransform: 'none', fontSize: '18px', fontWeight: 'bold', color: theme.palette.primary.main }}>
                    {t('Profile19')}
                </Typography>

                {/* Club Name */}

                {
                    state.user.data.attributes.field_user_type === 'clubadmin' &&
                    <TextField
                        required
                        id="field_club_name"
                        label={t('AccountCaptcha06')}
                        value={userData.attributes.field_club_name || ''}
                        onChange={(e) => handleChangeAttribute(e.target.value, "field_club_name")}
                        variant="standard"
                        autoComplete="off"
                        sx={{ width: '100%', marginTop: 2 }}
                    />
                }

                {/* Image - user picutre */}
                <Grid container spacing={2} mt={{ xs: 0, sm: 1 }}>
                    <Grid item xs={12} sm={6}>
                        <Typography sx={{ color: 'grey' }}>
                            {t('Profile15')}
                        </Typography>
                        {
                            userPictureURL &&

                            <img
                                src={cacheBreaker(userPictureURL)}
                                width={64}
                                height='auto'
                                ref={userPictureRef}
                                alt={`${t('Profile05')}`}
                                // If image is not jet on CDN then retry in a 1s.
                                onError={() => {
                                    setTimeout(() => {
                                        if (userPictureRef && userPictureRef.current && userPictureRef.current.src)
                                            userPictureRef.current.src = cacheBreaker(userPictureURL);
                                    }, 1000)
                                }}
                            />

                        }

                        {/* Button - upload user picutre */}
                        {
                            !userPictureURL &&
                            <Button
                                component="label"
                                variant="outlined"
                                startIcon={<CloudUploadIcon />}

                            >
                                {t('Profile02')}
                                <VisuallyHiddenInput
                                    type="file"
                                    accept={IMAGEMIMETYPES.toString()}
                                    onChange={(event) => handleChangeRelationship(event, 'user_picture')}
                                />
                            </Button>
                        }

                        {/* Button - remove user picutre */}
                        {
                            userPictureURL &&
                            <div>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}

                                    onClick={() => {
                                        setDirty(true)
                                        setUserPictureFile(null);
                                        setUserPictureURL('');
                                        clearRelationship('user_picture')
                                    }}
                                >
                                    {t('Profile04')}
                                </Button>
                            </div>
                        }
                    </Grid>

                    {/* Image - club logo */}
                    <Grid item xs={12} sm={6}>
                        {
                            [UserType.pro, UserType.clubadmin].includes(state.user.data.attributes.field_user_type) && clubLogoURL &&
                            <div>
                                <Typography sx={{ color: 'grey' }}>
                                    {t('Profile03')}
                                </Typography>
                                <img
                                    src={cacheBreaker(clubLogoURL)}
                                    width={64}
                                    height='auto'
                                    ref={clubLogoRef}
                                    alt={`${t('Profile05')}`}
                                    // If image is not jet on CDN then retry in a 1s.
                                    onError={() => {
                                        setTimeout(() => {
                                            if (clubLogoRef && clubLogoRef.current && clubLogoRef.current.src)
                                                clubLogoRef.current.src = cacheBreaker(clubLogoURL);
                                        }, 1000)
                                    }}
                                />
                            </div>
                        }

                        {/* Button - club logo */}
                        {
                            [UserType.pro, UserType.clubadmin].includes(state.user.data.attributes.field_user_type) && !clubLogoURL &&
                            <div>
                                <Typography sx={{ fontFamily: 'PT Sans, sans-serif', color: 'grey' }}>
                                    {t('Profile03')}
                                </Typography>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    startIcon={<CloudUploadIcon />}
                                >
                                    {t('Profile03')}
                                    <VisuallyHiddenInput
                                        type="file"
                                        onChange={(event) => handleChangeRelationship(event, 'field_club_logo')}
                                    />
                                </Button>
                            </div>
                        }

                        {/* Button - remove club logo */}
                        {
                            [UserType.pro, UserType.clubadmin].includes(state.user.data.attributes.field_user_type) && clubLogoURL &&
                            <div>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => {
                                        setDirty(true)
                                        setClubLogoFile(null);
                                        setClubLogoURL('');
                                        clearRelationship('field_club_logo')
                                    }}
                                >
                                    {t('Profile04')}
                                </Button>
                            </div>
                        }

                    </Grid>
                </Grid>

                <Divider sx={{ marginTop: 4 }} />

                <Typography style={{ marginTop: 25, textTransform: 'none', fontSize: '18px', fontWeight: 'bold', color: theme.palette.primary.main }}>
                    {t('Profile20')}
                </Typography>

                <Grid container spacing={2} mt={{ xs: 0, sm: 1 }}>
                    <Grid item xs={12} sm={6}>
                        <Typography sx={{ color: 'grey' }}>
                            {t('Profile21')}
                        </Typography>
                        <LanguageICB color={theme.palette.primary.main} variant="outlined" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography sx={{ color: 'grey' }}>
                            {t('Profile22')}
                        </Typography>

                        <NightModeToggle />
                    </Grid>
                </Grid>

                <Divider sx={{ marginTop: 4 }} />

                <Typography style={{ marginTop: 25, textTransform: 'none', fontSize: '18px', fontWeight: 'bold', color: theme.palette.primary.main }}>
                    {t('Profile23')}
                </Typography>

                <Button
                    variant="text"
                    sx={{ textTransform: 'none' }}
                    onClick={() => {
                        navigate('/setsubscription')
                    }}
                >
                    {t('Profile24')}
                </Button>

                <Divider sx={{ marginTop: 4 }} />

                <Typography style={{ marginTop: 25, textTransform: 'none', fontSize: '18px', fontWeight: 'bold', color: 'red' }}>
                    {t('Profile25')}
                </Typography>

                {/* Button - remove account */}
                <Button

                    variant="text"
                    sx={{
                        textTransform: 'none',
                        color: 'grey'

                    }}
                    onClick={() => setOpenDialogGetSingleLineOfText(true)}
                >
                    {t('Profile00')}
                </Button>

                {/* Button - submit */}
                <div>
                    <Button
                        variant="contained"
                        onClick={() => {
                            // check valid email format
                            if (passwordChange.mail && !validateEmail(passwordChange.mail)) {
                                dispatch(getActionSetConfirm(t('Generel20')));
                                return
                            }
                            if (passwordChange.mail !== state.user.data.attributes.mail && state.configuration[0].attributes.field_email_ver_code_active) {
                                // if email change then info about email validation - user can stop update
                                dispatch(getActionSetConfirm(t('Profile26'), 'ok', () => {
                                    onSave()
                                }));
                            } else {
                                // just update!
                                onSave()
                            }
                        }}
                        sx={{
                            position: 'fixed',
                            bottom: state.portrait ? '100px' : '30px',
                            left: state.portrait ? '50%' : 'calc(50% + 29px)',
                            transform: 'translateX(-50%)',
                            fontWeight: 'bold'
                        }}
                    >
                        {t('Profile16')}
                    </Button>
                </div>

                {/* Dialogbox to confirm cancel account by entering user name */}
                <DialogGetSingleLineOfText
                    open={openDialogGetSingleLineOfText}
                    onClose={(ok, text) => onCloseDialogGetSingleLineOfText(ok, text)}
                    title={t('Profile00')}
                    contentLine1={t('Profile06')}
                    label={t('AccountCaptcha00')}
                    button={t('Profile00')}
                />

            </Box>
        </Fragment>
    )
}
