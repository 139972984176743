import React, { useState, useRef, useEffect, useContext } from 'react';
import { BasketballHalfCourt } from './BasketballHalfCourt.svg';
import { BasketballFullCourt } from './BasketballFullCourt.svg';
import PlayBuilderActionButtons from './PlayBuilderActionButtons';
import { Button, Dialog, Grid, IconButton, Typography, Box } from '@mui/material';
import { Context } from '../App';
import { CRUD, DrupalEntity, TypeContext } from '../misc/Types';
import { useTranslation } from 'react-i18next';
import log from '../misc/Logger';
import { getActionSetConfirm, nodeCRUD } from '../misc/Functions';
import DialogPlayAnimation from './DialogPlayAnimation';
import PhasesSectionPlayBuilder from './PhasesSectionPlayBuilder';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { DRAGGINGSTATE, FRAMEINIT } from '../misc/Constants';
import SnackbarMessages from './SnackbarMessages';
import FeedbackIcon from '@mui/icons-material/Feedback';
import PlayAnimationLinesOrder from './PlayAnimationLinesOrder';

// Interfaces
export interface Player {
  id: number;
  x: number;
  y: number;
  number: number;
  hasBall: boolean;
}

export interface Play {
  frames: Frame[];
  currentFrameIndex: number;
  dragging: DraggingState;
  selectedPlayerId: number | null;
  isModalOpen: boolean;
  nextPlayerId: number;
  nextLineId: number;
  courtType: 'halfcourt' | 'fullcourt';
}

export interface Line {
  id: number;
  fromId: number;
  toId?: number;
  type: 'straight' | 'zigzag' | 'dashed' | 'bar';
  fromX: number;
  fromY: number;
  toX: number;
  toY: number;
  controlX: number;
  controlY: number;
  order?: number;             // <- Orden secuencial (1, 2, 3...)
  sameMomentAsPrevious?: boolean;   // <- Si dos o más líneas tienen el mismo “grupo”, ocurren a la vez
  controlModified?: boolean;
}

export interface Frame {
  id: number;
  players: Player[];
  lines: Line[];
}

interface DraggingState {
  isDragging: boolean;
  type: 'player' | 'end' | 'control' | null;
  id: number | null;
}

interface PropsDialogPlayBuilder {
  open: boolean;
  onClose: (play: PlayDetails | null) => void;
}

export interface PlayDetails {
  frames: Frame[],
  currentFrameIndex: number,
  dragging: DraggingState,
  selectedPlayerId: number | null,
  courtType: 'halfcourt' | 'fullcourt',
};

export const courtDimensions = {
  halfcourt: { width: 542, height: 455 },
  fullcourt: { width: 910, height: 550 },
};

// Helper functions
export const getOffsetPoint = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  offset: number
) => {
  const dx = x2 - x1;
  const dy = y2 - y1;
  const length = Math.hypot(dx, dy);
  if (length === 0) return { x: x1, y: y1 };
  const ratio = offset / length;
  return { x: x1 + dx * ratio, y: y1 + dy * ratio };
};

// Smooth line for curves
export const approximateBezierLength = (
  from: { x: number; y: number },
  control: { x: number; y: number },
  to: { x: number; y: number }
) => {
  const steps = 10;
  let length = 0;
  let prevPoint = from;

  for (let i = 1; i <= steps; i++) {
    const t = i / steps;
    const xt =
      (1 - t) ** 2 * from.x + 2 * (1 - t) * t * control.x + t ** 2 * to.x;
    const yt =
      (1 - t) ** 2 * from.y + 2 * (1 - t) * t * control.y + t ** 2 * to.y;

    length += Math.hypot(xt - prevPoint.x, yt - prevPoint.y);
    prevPoint = { x: xt, y: yt };
  }

  return length;
};

export const createZigzagPath = (
  from: { x: number; y: number },
  control: { x: number; y: number },
  to: { x: number; y: number },
  amplitude = 3,
  oscillationsPer100px = 12,
  finalSegmentLength = 10
) => {

  // First, approximate the total length of the curve
  const length = approximateBezierLength(from, control, to);
  const totalOscillations = Math.max(
    2,
    Math.round((length / 100) * oscillationsPer100px)
  );

  const steps = 1000;
  const dt = 1 / steps;

  const cumulativeLengths = [0];
  let prevX = from.x;
  let prevY = from.y;
  let totalLength = 0;

  // Calculate cumulative lengths and points along the curve
  for (let i = 1; i <= steps; i++) {
    const t = i * dt;
    const xt =
      (1 - t) ** 2 * from.x + 2 * (1 - t) * t * control.x + t ** 2 * to.x;
    const yt =
      (1 - t) ** 2 * from.y + 2 * (1 - t) * t * control.y + t ** 2 * to.y;

    const dx = xt - prevX;
    const dy = yt - prevY;
    const ds = Math.hypot(dx, dy);

    totalLength += ds;
    cumulativeLengths.push(totalLength);

    prevX = xt;
    prevY = yt;
  }

  // Generate evenly spaced points along the curve
  const path = [];
  path.push(`M ${from.x} ${from.y}`);

  const numPoints = steps;
  const desiredLengths = [];
  for (let i = 1; i <= numPoints; i++) {
    desiredLengths.push((i / numPoints) * totalLength);
  }

  for (let i = 0; i < desiredLengths.length; i++) {
    const s = desiredLengths[i];

    // Find the index where the cumulative length is greater than or equal to s
    let j = 0;
    while (j < cumulativeLengths.length && cumulativeLengths[j] < s) {
      j++;
    }
    if (j === 0) {
      j = 1;
    }

    // Linear interpolation to find t
    const s0 = cumulativeLengths[j - 1];
    const s1 = cumulativeLengths[j];
    const t0 = (j - 1) * dt;
    const t1 = j * dt;

    const t = t0 + ((s - s0) / (s1 - s0)) * (t1 - t0);

    // Calculate the point and derivative at t
    const xt =
      (1 - t) ** 2 * from.x + 2 * (1 - t) * t * control.x + t ** 2 * to.x;
    const yt =
      (1 - t) ** 2 * from.y + 2 * (1 - t) * t * control.y + t ** 2 * to.y;

    const dxdt =
      2 * (1 - t) * (control.x - from.x) + 2 * t * (to.x - control.x);
    const dydt =
      2 * (1 - t) * (control.y - from.y) + 2 * t * (to.y - control.y);

    const len = Math.hypot(dxdt, dydt);
    const nx = -dydt / len;
    const ny = dxdt / len;

    const frequency = (totalOscillations * Math.PI) / totalLength;
    const offset = amplitude * Math.sin(s * frequency);

    const offsetX = xt + offset * nx;
    const offsetY = yt + offset * ny;

    path.push(`L ${offsetX} ${offsetY}`);
  }

  // Add the final segment
  const theta = Math.atan2(to.y - control.y, to.x - control.x);
  const finalX = to.x + finalSegmentLength * Math.cos(theta);
  const finalY = to.y + finalSegmentLength * Math.sin(theta);
  path.push(`L ${finalX} ${finalY}`);

  return path.join(' ');
};

// Arrowhead and bar definitions
export const SvgDefs: React.FC = () => (
  <defs>
    <marker
      id="arrowhead"
      markerWidth="10"
      markerHeight="7"
      refX="6"
      refY="3.5"
      orient="auto"
    >
      <polygon points="0 0, 10 3.5, 0 7" fill="black" />
    </marker>
    <marker
      id="barEnd"
      markerWidth="1"
      markerHeight="16"
      refX="0.5"
      refY="8"
      orient="auto"
    >
      <rect x="0" y="0" width="1" height="16" fill="black" />
    </marker>
  </defs>
);

// This restricts a value to be between a minimum and maximum so movable elements don't go out of bounds
const clamp = (value: number, min: number, max: number): number => {
  return Math.max(min, Math.min(value, max));
};

// MAIN COMPONENT
export default function DialogPlayBuilder(props: PropsDialogPlayBuilder) {
  const { state, dispatch } = useContext(Context) as TypeContext;
  const { t } = useTranslation();
  log.debug('DialogPlayBuilder');

  const [openSnackbarMessage00, setOpenSnackbarMessage00] = useState(false)
  const [openSnackbarMessage01, setOpenSnackbarMessage01] = useState(false)
  const [openSnackbarMessage02, setOpenSnackbarMessage02] = useState(false)
  const [openSnackbarMessage03, setOpenSnackbarMessage03] = useState(false)
  const [openSnackbarMessage04, setOpenSnackbarMessage04] = useState(false)
  const [openSnackbarMessage05, setOpenSnackbarMessage05] = useState(false)
  const [openSnackbarMessage06, setOpenSnackbarMessage06] = useState(false)
  const [openSnackbarMessage07, setOpenSnackbarMessage07] = useState(false)

  // State to manage the court type
  const [courtType, setCourtType] = useState<'halfcourt' | 'fullcourt'>('halfcourt')

  // State to manage the initial pointer position
  const [initialPointerPosition, setInitialPointerPosition] = useState<{ x: number; y: number } | null>(null);

  // Court dimensions depending on the court type
  const MAX_SVG_WIDTH = courtType === 'halfcourt' ? 600 : 800;

  const { width: courtWidth, height: courtHeight } = courtDimensions[courtType];

  // Toggle to change the court type
  const handleCourtChange = (
    _event: React.MouseEvent<HTMLElement>,
    newCourtType: 'halfcourt' | 'fullcourt' | null
  ) => {
    if (newCourtType !== null) {
      setCourtType(newCourtType);
    }
  };

  const [frames, setFrames] = useState<Frame[]>([FRAMEINIT]);

  const [currentFrameIndex, setCurrentFrameIndex] = useState<number>(0);
  const [dragging, setDragging] = useState<DraggingState>(DRAGGINGSTATE);
  const [selectedPlayerId, setSelectedPlayerId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [nextPlayerId,] = useState<number>(1);
  const [nextLineId, setNextLineId] = useState<number>(1);

  const svgRef = useRef<SVGSVGElement | null>(null);

  // Set play. Test we have play details in field_play_details
  useEffect(() => {
    const play: Play = state.curPlay.attributes.field_play_details && JSON.parse(
      state.curPlay.attributes.field_play_details
    );
    if (play) {
      // handle wrong line id saved in back end
      const framesLocal = play.frames || frames
      const framesLocalCorrected = framesLocal.map(frame => {
        const linesLocal = frame.lines
        const linesLocalCorrected = linesLocal.map(line => {
          return {
            ...line,
            id: line.fromId
          }
        })
        return {
          ...frame,
          lines: linesLocalCorrected
        }
      })

      // setFrames(play.frames || frames);
      setFrames(framesLocalCorrected);
      setCurrentFrameIndex(play.currentFrameIndex || 0);
      setDragging(
        play.dragging || {
          isDragging: false,
          type: null,
          id: null,
        }
      );
      setSelectedPlayerId(play.selectedPlayerId || null);
      setIsModalOpen(play.isModalOpen || false);
      setNextLineId(play.nextLineId || 1);
      setCourtType(play.courtType || 'halfcourt');
    } else {
      setFrames(frames);
      setCurrentFrameIndex(0);
      setDragging({
        isDragging: false,
        type: null,
        id: null,
      });
      setSelectedPlayerId(null);
      setIsModalOpen(false);
      setNextLineId(1);
      setCourtType('halfcourt');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMove = (e: React.MouseEvent<SVGElement, MouseEvent> | React.TouchEvent<SVGElement>) => {
    if (!dragging.type || dragging.id === null || !svgRef.current) return;

    const { x: clientX, y: clientY } = getEventCoordinates(e.nativeEvent);

    const svgPoint = svgRef.current.createSVGPoint();
    svgPoint.x = clientX;
    svgPoint.y = clientY;
    const cursorPoint = svgPoint.matrixTransform(svgRef.current.getScreenCTM()?.inverse());

    let mouseX = clamp(cursorPoint.x, 0, courtWidth);
    let mouseY = clamp(cursorPoint.y, 0, courtHeight);

    const DRAG_THRESHOLD = 5;

    if (!dragging.isDragging) {
      if (initialPointerPosition) {
        const deltaX = mouseX - initialPointerPosition.x;
        const deltaY = mouseY - initialPointerPosition.y;
        const distance = Math.hypot(deltaX, deltaY);

        if (distance > DRAG_THRESHOLD) {
          setDragging((prev) => ({ ...prev, isDragging: true }));
        } else {
          return;
        }
      } else {
        return;
      }
    }

    setFrames((prevFrames) => {
      const updatedFrames = [...prevFrames];
      const currentFrame = { ...updatedFrames[currentFrameIndex] };

      if (dragging.type === 'player') {
        currentFrame.players = currentFrame.players.map((player) =>
          player.id === dragging.id ? { ...player, x: mouseX, y: mouseY } : player
        );
      } else if (dragging.type === 'end' && currentFrame.lines) {
        currentFrame.lines = currentFrame.lines.map((line) => {
          if (line.id === dragging.id) {
            if (line.type !== 'dashed') {
              if (!line.controlModified) {
                // Reset control point to midpoint
                const midpointX = (line.fromX + mouseX) / 2;
                const midpointY = (line.fromY + mouseY) / 2;
                return {
                  ...line,
                  toX: mouseX,
                  toY: mouseY,
                  controlX: midpointX,
                  controlY: midpointY,
                };
              }
            }

            // Existing behavior for 'dashed' lines
            if (line.type === 'dashed') {
              const nearbyPlayerId = findNearbyPlayer(mouseX, mouseY);
              if (nearbyPlayerId !== null && nearbyPlayerId !== line.fromId) {
                const targetPlayer = currentFrame.players.find(p => p.id === nearbyPlayerId);
                if (targetPlayer) {
                  return {
                    ...line,
                    toX: targetPlayer.x,
                    toY: targetPlayer.y,
                    toId: targetPlayer.id,
                    controlX: (line.fromX + targetPlayer.x) / 2,
                    controlY: (line.fromY + targetPlayer.y) / 2,
                  };
                }
              }
            }

            // If the control point has been modified or it's a 'dashed' line without nearby player
            return { ...line, toX: mouseX, toY: mouseY };
          }
          return line;
        });
      } else if (dragging.type === 'control' && currentFrame.lines) {
        currentFrame.lines = currentFrame.lines.map((line) => {
          if (line.id === dragging.id) {
            return { ...line, controlX: mouseX, controlY: mouseY };
          }
          return line;
        });
      }

      updatedFrames[currentFrameIndex] = currentFrame;
      return updatedFrames;
    });
  };

  const handleEnd = () => {
    setDragging({ isDragging: false, type: null, id: null });
    setInitialPointerPosition(null);
  };


  // Function to get the coordinates of the event
  const getEventCoordinates = (e: MouseEvent | TouchEvent) => {
    if (e instanceof MouseEvent) {
      return { x: e.clientX, y: e.clientY };
    } else if (e instanceof TouchEvent) {
      const touch = e.touches[0] || e.changedTouches[0];
      return { x: touch.clientX, y: touch.clientY };
    }
    return { x: 0, y: 0 };
  };

  // Función to detect if the point is near a player
  const findNearbyPlayer = (x: number, y: number): number | null => {
    const proximity = 20;
    const currentFrame = frames[currentFrameIndex];
    let closestPlayerId: number | null = null;
    let minDistance = proximity;

    for (const player of currentFrame.players) {
      const distance = Math.hypot(player.x - x, player.y - y);
      if (distance <= minDistance) {
        minDistance = distance;
        closestPlayerId = player.id;
      }
    }

    return closestPlayerId;
  };

  // Function to handle the start of the drag
  const handleStart = (
    e: React.MouseEvent<SVGElement, MouseEvent> | React.TouchEvent<SVGElement>,
    type: 'player' | 'end' | 'control',
    id: number
  ) => {
    e.stopPropagation();

    const { x, y } = getEventCoordinates(e.nativeEvent);

    if (!svgRef.current) return;

    const svgPoint = svgRef.current.createSVGPoint();
    svgPoint.x = x;
    svgPoint.y = y;
    const cursorPoint = svgPoint.matrixTransform(svgRef.current.getScreenCTM()?.inverse());

    let initialX = clamp(cursorPoint.x, 0, courtWidth);
    let initialY = clamp(cursorPoint.y, 0, courtHeight);

    setInitialPointerPosition({ x: initialX, y: initialY });
    setDragging({ isDragging: false, type, id });

    if (type === 'player') setSelectedPlayerId(id);

    // If dragging a control point, mark it as modified
    if (type === 'control') {
      setFrames((prevFrames) => {
        const updatedFrames = [...prevFrames];
        const currentFrame = { ...updatedFrames[currentFrameIndex] };
        currentFrame.lines = currentFrame.lines.map((line) =>
          line.id === id ? { ...line, controlModified: true } : line
        );
        updatedFrames[currentFrameIndex] = currentFrame;
        return updatedFrames;
      });
    }
  };


  // NEW: Función para calcular el `order` de la nueva línea
  const getNextLineOrder = (lines: Line[]): number => {
    // si no hay líneas, el orden será 1
    if (lines.length === 0) return 1;
    // caso contrario, busca el máximo “order” y súmale 1
    const maxOrder = Math.max(...lines.map((l) => l.order ?? 1));
    return maxOrder + 1;
  };

  const createLine = (type: 'straight' | 'zigzag' | 'dashed' | 'bar') => {
    if (selectedPlayerId === null) {
      // Activar Snackbar para 2 segundos
      setOpenSnackbarMessage00(true);
      setTimeout(() => { setOpenSnackbarMessage00(false); }, 3000);
      return;
    }

    const currentFrame = frames[currentFrameIndex];
    const selectedPlayer = currentFrame.players.find(
      (p) => p.id === selectedPlayerId
    );

    if (!selectedPlayer) return;

    if (selectedPlayer.hasBall) {
      // Si el jugador tiene el balón, solo permitir 'zigzag' (driblar) y 'dashed' (pasar)
      if (type !== 'zigzag' && type !== 'dashed') {
        // Activar Snackbar indicando que la acción no está permitida
        setOpenSnackbarMessage06(true);
        setTimeout(() => { setOpenSnackbarMessage06(false); }, 3000);
        return;
      }
    } else {
      // Si el jugador no tiene el balón, no permitir 'zigzag' (driblar) y 'dashed' (pasar)
      if (type === 'zigzag' || type === 'dashed') {
        // Activar Snackbar indicando que la acción no está permitida
        setOpenSnackbarMessage07(true);
        setTimeout(() => { setOpenSnackbarMessage07(false); }, 3000);
        return;
      }
    }

    // Verificar restricciones adicionales
    if ((type === 'zigzag' || type === 'dashed') && !selectedPlayer.hasBall) {
      setOpenSnackbarMessage01(true);
      setTimeout(() => { setOpenSnackbarMessage01(false); }, 3000);
      return;
    }

    const existingLine = currentFrame.lines.find(
      (line) => line.fromId === selectedPlayerId
    );
    if (existingLine) {
      setOpenSnackbarMessage02(true);
      setTimeout(() => { setOpenSnackbarMessage02(false); }, 3000);
      return;
    }

    const svgHeight = courtHeight;
    const isUpperHalf = selectedPlayer.y < svgHeight / 2;

    const toX = selectedPlayer.x;
    const toY = isUpperHalf ? selectedPlayer.y + 125 : selectedPlayer.y - 125;
    const controlX = selectedPlayer.x;
    const controlY = isUpperHalf ? selectedPlayer.y + 40 : selectedPlayer.y - 40;

    // Asignar el id de la línea al id del jugador seleccionado
    const newLine: Line = {
      id: selectedPlayerId, // Asignación directa del id del jugador
      fromId: selectedPlayerId,
      type,
      toX,
      toY,
      fromX: selectedPlayer.x,
      fromY: selectedPlayer.y,
      controlX,
      controlY,
      order: getNextLineOrder(currentFrame.lines),
      sameMomentAsPrevious: undefined,
      controlModified: false,
    };

    // Ya no es necesario incrementar nextLineId
    // setNextLineId((prev) => prev + 1);

    setFrames((prevFrames) => {
      const updatedFrames = [...prevFrames];
      const currentFrameCopy = { ...updatedFrames[currentFrameIndex] };
      currentFrameCopy.lines = [...currentFrameCopy.lines, newLine];
      updatedFrames[currentFrameIndex] = currentFrameCopy;
      return updatedFrames;
    });
  };


  const handleCreateNewFrame = () => {
    const currentFrame = frames[currentFrameIndex];

    const updatedPlayers = currentFrame.players.map((player) => {
      const connectedLine = currentFrame.lines.find(
        (line) => line.fromId === player.id
      );

      if (connectedLine) {
        if (connectedLine.type === 'dashed') {
          if (player.hasBall) {
            const receiver = currentFrame.players.find(
              (p) => p.id === connectedLine.toId
            );
            if (receiver) {
              return { ...player, hasBall: false };
            }
          }
        } else {
          return {
            ...player,
            x: connectedLine.toX,
            y: connectedLine.toY,
          };
        }
      }
      return { ...player };
    });

    const newPlayers = updatedPlayers.map((player) => {
      const receivedBall = currentFrame.lines.some(
        (line) =>
          line.type === 'dashed' &&
          line.toId === player.id &&
          currentFrame.players.find((p) => p.id === line.fromId)?.hasBall
      );
      if (receivedBall) {
        return { ...player, hasBall: true };
      }
      return player;
    });

    const newFrame: Frame = {
      id: frames.length + 1,
      players: newPlayers,
      lines: [],
    };

    setFrames((prevFrames) => [...prevFrames, newFrame]);
    setCurrentFrameIndex(frames.length);
    const playerWithBall = newPlayers.find((player) => player.hasBall);
    if (playerWithBall) {
      setSelectedPlayerId(playerWithBall.id);
    } else {
      setSelectedPlayerId(null);
    }
  };

  const handleDeleteFrame = (index: number) => {
    if (frames.length === 1) {
      //Activate Snackbar for 2 seconds. Autohide in parent would be inconsistent when double clicking fast
      setOpenSnackbarMessage03(true); setTimeout(() => { setOpenSnackbarMessage03(false); }, 3000)
      return;
    }

    // Delete the selected frame
    const updatedFrames = frames.filter((_, i) => i !== index);

    // Reassign IDs to be consecutive
    const reindexedFrames = updatedFrames.map((frame, i) => ({
      ...frame,
      id: i + 1,
    }));

    setFrames(reindexedFrames);

    // Update current frame index if necessary
    if (currentFrameIndex >= reindexedFrames.length) {
      setCurrentFrameIndex(reindexedFrames.length - 1);
    } else if (index < currentFrameIndex) {
      setCurrentFrameIndex(prevIndex => prevIndex - 1);
    }
  };

  const handleSavePlay = async () => {
    const play: any = {
      frames: frames,
      currentFrameIndex: currentFrameIndex,
      dragging: dragging,
      selectedPlayerId: selectedPlayerId,
      isModalOpen: isModalOpen,
      nextPlayerId: nextPlayerId,
      nextLineId: nextLineId,
      courtType: courtType,
    };
    const playNode: DrupalEntity = {
      type: 'node--play',
      id: state.curPlay.id,
      attributes: {
        field_play_details: JSON.stringify(play),
      },
    };
    const resp = await nodeCRUD(state, dispatch, CRUD.Update, playNode);
    if (!resp.data) {
      dispatch(getActionSetConfirm(t(resp)));
    }
    // handleCloseCreatePlay();
  };

  const handleDeleteLine = () => {
    if (selectedPlayerId === null) {

      //Activate Snackbar for 2 seconds. Autohide in parent would be inconsistent when double clicking fast
      setOpenSnackbarMessage04(true); setTimeout(() => { setOpenSnackbarMessage04(false); }, 3000)
      return;
    }

    setFrames((prevFrames) => {
      const updatedFrames = [...prevFrames];
      const currentFrame = { ...updatedFrames[currentFrameIndex] };
      currentFrame.lines = currentFrame.lines.filter(
        (line) =>
          line.fromId !== selectedPlayerId && line.toId !== selectedPlayerId
      );
      updatedFrames[currentFrameIndex] = currentFrame;
      return updatedFrames;
    });
  };

  const handleToggleBall = () => {
    if (selectedPlayerId === null) {
      //Activate Snackbar for 2 seconds. Autohide in parent would be inconsistent when double clicking fast
      setOpenSnackbarMessage05(true); setTimeout(() => { setOpenSnackbarMessage05(false); }, 3000)
      return;
    }

    setFrames((prevFrames) => {
      const updatedFrames = [...prevFrames];
      const currentFrame = { ...updatedFrames[currentFrameIndex] };
      currentFrame.players = currentFrame.players.map((player) =>
        player.id === selectedPlayerId
          ? { ...player, hasBall: !player.hasBall }
          : player
      );
      updatedFrames[currentFrameIndex] = currentFrame;
      return updatedFrames;
    });
  };

  const handleSelectFrame = (index: number) => {
    setCurrentFrameIndex(index);
    setSelectedPlayerId(null);
    setDragging({ isDragging: false, type: null, id: null });
  };

  const generatePathD = (
    line: Line,
    adjustedFrom: { x: number; y: number },
    adjustedTo: { x: number; y: number }
  ) => {
    // Determine offset based on line type
    let fromOffset = 15; // Default offset
    let toOffset = 15;   // Default offset

    // Adjust offsets for specific line types
    if (line.type === 'bar' || line.type === 'straight') {
      fromOffset = 10; // Shorter offset for bar and straight lines
    }

    // Apply offset to both start and end points
    const startPoint = getOffsetPoint(
      adjustedFrom.x,
      adjustedFrom.y,
      line.controlX,
      line.controlY,
      fromOffset
    );
    const endPoint = getOffsetPoint(
      adjustedTo.x,
      adjustedTo.y,
      line.controlX,
      line.controlY,
      toOffset
    );

    // Generate path based on line type
    if (line.type === 'zigzag') {
      return createZigzagPath(
        startPoint,
        { x: line.controlX, y: line.controlY },
        endPoint
      );
    } else {
      // For other line types, use a standard quadratic Bezier curve
      return `M ${startPoint.x} ${startPoint.y} Q ${line.controlX} ${line.controlY} ${endPoint.x} ${endPoint.y}`;
    }
  };

  const currentFrame = frames[currentFrameIndex];

  // Determine if the selected player has connected lines -> we need to disable the delete line button if it does on ActionButtons.tsx
  const hasConnectedLines = selectedPlayerId !== null && currentFrame.lines.some(line => line.fromId === selectedPlayerId || line.toId === selectedPlayerId);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.onClose(null)}
        fullScreen
        PaperProps={{
          style: {
            width: '98%',
            height: '98%',
            margin: 'auto',
            borderRadius: '8px'
          },
        }}
      >
        <div>

          < Box sx={{ p: 1, bgcolor: '#00398F', textAlign: 'center' }}>
            <Grid container alignItems="center">

              {/* Left aligned Play button */}
              <Grid item xs={state.portrait ? 6 : 4} sx={{ textAlign: 'left' }}>
                <Button
                  variant="outlined"
                  onClick={() => setIsModalOpen(true)}
                  size={state.portrait ? "small" : "medium"}
                  style={{ color: "white", textTransform: 'none', borderColor: 'white', fontWeight: 'bold' }}
                >
                  <PlayArrowRoundedIcon />
                  {t('DialogPlayBuilder00')}
                </Button>

              </Grid>

              {/* Centered title */}
              {!state.portrait &&
                <Grid item xs={state.portrait ? 0 : 4}>
                  <Typography sx={{ fontSize: { xs: '16px', md: '20px' }, fontWeight: 'bold', color: 'white' }} align="center">
                    {t('PlayBuilder06')}
                  </Typography>
                </Grid>
              }

              {/* Right aligned Save Play button */}
              <Grid item xs={state.portrait ? 6 : 4} sx={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  size={state.portrait ? "small" : "medium"}
                  style={{ color: '#00398F', textTransform: 'none', backgroundColor: 'white', fontWeight: 'bold' }}
                  onClick={() => {
                    const playDetails: PlayDetails = {
                      frames: frames,
                      currentFrameIndex: currentFrameIndex,
                      dragging: dragging,
                      selectedPlayerId: selectedPlayerId,
                      courtType: courtType,
                    }
                    props.onClose(playDetails)

                  }}
                >
                  <SaveRoundedIcon />
                  {t('CreatePlay08')}
                </Button>
                <IconButton sx={{ color: 'white', marginLeft: '10px' }} onClick={() => props.onClose(null)}>
                  <CloseRoundedIcon />
                </IconButton>
              </Grid>

            </Grid>
          </Box >

          <Grid container style={{ height: state.portrait ? 'auto' : '100vh', backgroundColor: state.portrait ? 'white' : '#f1f1f1' }}>
            {/* Middle Section */}
            <Grid item xs={state.portrait ? 12 : 3} md={state.portrait ? 12 : 3} style={{ padding: state.portrait ? '0px' : '10px', borderRight: state.portrait ? 'none' : '1px solid #ccc', backgroundColor: state.portrait ? 'white' : 'white', overflowY: 'auto', paddingBottom: state.portrait ? '0px' : '300px' }}>
              <PhasesSectionPlayBuilder
                frames={frames}
                currentFrameIndex={currentFrameIndex}
                handleSelectFrame={handleSelectFrame}
                handleDeleteFrame={handleDeleteFrame}
                handleCreateNewFrame={handleCreateNewFrame}
              />

            </Grid>

            {/* SVG */}
            <Grid item xs={12} md={6} style={{ position: 'relative', maxWidth: `${MAX_SVG_WIDTH}px`, margin: '0 auto' }}>

              {/* SHOW TITLE IN LANDSCAPE. WE SHOW IT IN PHASES SECTION FOR BETTER UI WHEN ON PORTRAIT */}
              {!state.portrait &&
                <Typography style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: state.portrait ? '0px' : '10px', paddingBottom: state.portrait ? '5px' : '10px' }}>
                  {state.curPlay.attributes.title || ''}
                </Typography>
              }

              <svg
                ref={svgRef}
                onTouchMove={handleMove}
                onMouseMove={handleMove}
                onMouseUp={handleEnd}
                onTouchEnd={handleEnd}

                viewBox={`0 0 ${courtWidth} ${courtHeight}`}
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: state.portrait ? '0px' : '8px',
                  touchAction: 'none' // prevents default touch behavior
                }}
                preserveAspectRatio="xMidYMid meet"

              >
                {courtType === 'halfcourt' ? <BasketballHalfCourt /> : <BasketballFullCourt />}
                <SvgDefs />

                {currentFrame.players.map((player) => (
                  <g
                    key={player.id}
                    onMouseDown={(e) => handleStart(e, 'player', player.id)}
                    onTouchStart={(e) => handleStart(e, 'player', player.id)}
                    onClick={() => setSelectedPlayerId(player.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {/* Transparent circle to increase selection area */}
                    <circle
                      cx={player.x}
                      cy={player.y}
                      r={15}
                      fill="transparent"
                    />
                    {selectedPlayerId === player.id && (
                      <rect
                        x={player.x - 16}
                        y={player.y - 16}
                        width={32}
                        height={32}
                        rx={2}
                        ry={2}
                        fill="#f1f1f1"
                        stroke="black"
                        strokeWidth={1}
                        strokeDasharray="4,2"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from="0"
                          to="6"
                          dur="2s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    )}
                    {player.hasBall && (
                      <circle
                        cx={player.x}
                        cy={player.y}
                        r={14}
                        stroke="black"
                        strokeWidth={2}
                        fill="none"
                      />
                    )}
                    <text
                      x={player.x}
                      y={player.y}
                      textAnchor="middle"
                      alignmentBaseline="central"
                      fontSize="20"
                      fontWeight="bold"
                      fill="black"
                      style={{ userSelect: 'none', pointerEvents: 'none' }}
                    >
                      {player.number}
                    </text>
                  </g>
                ))}

                {/* Render lines after players */}
                {currentFrame.lines.map((line) => {
                  const fromPlayer = currentFrame.players.find(
                    (p) => p.id === line.fromId
                  );
                  if (!fromPlayer) return null;

                  const isLineConnectedToSelected =
                    selectedPlayerId === line.fromId;

                  // Calcula el punto ajustado basado en el control
                  const adjustedFrom = getOffsetPoint(
                    fromPlayer.x,
                    fromPlayer.y,
                    line.controlX,
                    line.controlY,
                    0
                  );

                  const adjustedTo = getOffsetPoint(
                    line.toX,
                    line.toY,
                    fromPlayer.x,
                    fromPlayer.y,
                    0
                  );

                  const pathD = generatePathD(line, adjustedFrom, adjustedTo);

                  return (
                    <g key={line.id}>
                      <path
                        d={pathD}
                        stroke="black"
                        strokeWidth={2}
                        fill="none"
                        strokeDasharray={line.type === 'dashed' ? '5,5' : 'none'}
                        markerEnd={
                          line.type === 'bar' ? 'url(#barEnd)' : 'url(#arrowhead)'
                        }
                      />
                      {isLineConnectedToSelected && (
                        <>
                          <circle
                            cx={line.controlX}
                            cy={line.controlY}
                            r={6}
                            fill="#00398f"
                            stroke="white"
                            strokeWidth={2}
                            style={{ cursor: 'pointer' }}
                            onMouseDown={(e) => handleStart(e, 'control', line.id)}
                            onTouchStart={(e) => handleStart(e, 'control', line.id)}
                          >
                            <title>{t('PlayBuilder07')}</title>
                          </circle>
                          {/* Transparent circle to increase selection area of control point */}
                          <circle
                            cx={line.controlX}
                            cy={line.controlY}
                            r={15}
                            fill="transparent"
                            onMouseDown={(e) => handleStart(e, 'control', line.id)}
                            onTouchStart={(e) => handleStart(e, 'control', line.id)}
                            style={{ cursor: 'pointer' }}
                          />

                          <circle
                            cx={line.toX}
                            cy={line.toY}
                            r={line.type === 'dashed' ? 12 : 6}
                            fill={line.type === 'dashed' ? 'rgba(221, 111, 32, 0.3)' : '#00398f'}
                            stroke="white"
                            strokeWidth={2}
                            style={{ cursor: 'pointer' }}
                            onMouseDown={(e) => handleStart(e, 'end', line.id)}
                            onTouchStart={(e) => handleStart(e, 'end', line.id)}
                          >
                            <title>{t('PlayBuilder08')}</title>
                          </circle>

                          {/* Transparent circle to increase selection area of end point */}
                          <circle
                            cx={line.toX}
                            cy={line.toY}
                            r={15}
                            fill="transparent"
                            onMouseDown={(e) => handleStart(e, 'end', line.id)}
                            onTouchStart={(e) => handleStart(e, 'end', line.id)}
                            style={{ cursor: 'pointer' }}
                          />
                        </>
                      )}
                    </g>
                  );
                })}
              </svg>

              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <ToggleButtonGroup
                  value={courtType}
                  exclusive
                  onChange={handleCourtChange}
                  aria-label="Tipo de cancha"
                  size="small"
                  sx={{
                    backgroundColor: '#f1f1f1',
                    borderRadius: '4px',
                    height: '30px',
                    border: '1px solid #ccc',
                  }}
                >
                  <ToggleButton
                    value="halfcourt"
                    aria-label="Halfcourt"
                    sx={{
                      backgroundColor: 'white',
                      color: 'gray',
                      '&.Mui-selected': {
                        backgroundColor: '#f6f6f6',
                        color: 'black',
                      },
                      border: 'none',
                      textTransform: 'none',
                    }}
                  >
                    {t('PlayBuilder09')}
                  </ToggleButton>
                  <ToggleButton
                    value="fullcourt"
                    aria-label="Fullcourt"
                    sx={{
                      backgroundColor: 'white',
                      color: 'gray',
                      '&.Mui-selected': {
                        backgroundColor: '#f6f6f6',
                        color: 'black',
                      },
                      border: 'none',
                      textTransform: 'none',
                    }}
                  >
                    {t('PlayBuilder10')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>

            </Grid>

            {/* Right Sidebar */}
            <Grid item xs={state.portrait ? 12 : 8} md={state.portrait ? 12 : 3}
              style={{
                padding: '10px',
                borderLeft: state.portrait ? 'none' : '1px solid #ccc',
                backgroundColor: state.portrait ? 'white' : 'white'
              }}>
              <PlayBuilderActionButtons
                selectedPlayer={currentFrame.players.find(p => p.id === selectedPlayerId) || null}
                createLine={createLine}
                handleCreateNewFrame={handleCreateNewFrame}
                setIsModalOpen={setIsModalOpen}
                handleDeleteLine={handleDeleteLine}
                handleToggleBall={handleToggleBall}
                handleSavePlay={handleSavePlay}
                hasConnectedLines={hasConnectedLines}
              />

              {/* NEW: Aquí podríamos mostrar un subcomponente para reordenar líneas si hay más de 3 */}
              {currentFrame.lines.length > 0 && (
                <Box sx={{ mt: state.portrait ? 2 : 2 }}>
                  <PlayAnimationLinesOrder
                    lines={currentFrame.lines}
                    onUpdateLines={(updatedLines) => {
                      // Actualizar el frame actual con el nuevo orden
                      setFrames((prevFrames) => {
                        const newFrames = [...prevFrames];
                        newFrames[currentFrameIndex] = {
                          ...newFrames[currentFrameIndex],
                          lines: updatedLines
                        };
                        return newFrames;
                      });
                    }}
                  />
                </Box>
              )}

            </Grid>

          </Grid>
        </div>
      </Dialog>

      {/* Animation Modal */}
      {
        isModalOpen && (
          <DialogPlayAnimation
            playNid={0}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            frames={frames}
            courtType={courtType}
          />
        )
      }

      <SnackbarMessages open={openSnackbarMessage00} message={t('PlayBuilder00')} icon={<FeedbackIcon />} color={'#00398F'} />
      <SnackbarMessages open={openSnackbarMessage01} message={t('PlayBuilder01')} icon={<FeedbackIcon />} color={'#00398F'} />
      <SnackbarMessages open={openSnackbarMessage02} message={t('PlayBuilder02')} icon={<FeedbackIcon />} color={'#00398F'} />
      <SnackbarMessages open={openSnackbarMessage03} message={t('PlayBuilder03')} icon={<FeedbackIcon />} color={'#00398F'} />
      <SnackbarMessages open={openSnackbarMessage04} message={t('PlayBuilder04')} icon={<FeedbackIcon />} color={'#00398F'} />
      <SnackbarMessages open={openSnackbarMessage05} message={t('PlayBuilder05')} icon={<FeedbackIcon />} color={'#00398F'} />
      <SnackbarMessages open={openSnackbarMessage06} message={t('PlayBuilder01')} icon={<FeedbackIcon />} color={'#00398F'} />
      <SnackbarMessages open={openSnackbarMessage07} message={t('PlayBuilder01')} icon={<FeedbackIcon />} color={'#00398F'} />
    </>
  );
};